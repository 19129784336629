export const reproduceCells = (cells: (number | null)[][], columnsCount = 0, rowsCount = 0) => {
  const originalRows = cells.length
  const originalColumns = originalRows > 0 ? cells[0].length : 0

  const newColumnsCount = columnsCount && originalColumns > columnsCount ? originalColumns : columnsCount
  const newRowsCount = rowsCount && originalRows > rowsCount ? originalRows : rowsCount

  const expandedArray: (number | null)[][] = []

  for (let i = 0; i < newRowsCount; i++) {
    const newRow: (number | null)[] = []
    for (let j = 0; j < newColumnsCount; j++) {
      const originalRowIndex = i % originalRows
      const originalColumnIndex = j % originalColumns
      newRow.push(cells[originalRowIndex][originalColumnIndex])
    }
    expandedArray.push(newRow)
  }

  return expandedArray
}
