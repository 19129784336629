import { createPluralize, I18N } from '@ayub-begimkulov/i18n'
import { ArrayElement, UnionToIntersection } from 'interfaces/common.interfaces'
import { global } from 'packages/helper'
import { getLang } from 'packages/localization'

import { KeySet, KeySetFile, KeySetsMap, LanguageConfig, LanguagesKeySets, LanguagesLoaders, Locals } from './types'

async function mergeJsonFiles<T extends Promise<{ default: KeySet }>>(promises: readonly T[]) {
  const jsons = await Promise.all(promises).then((items) => items.map((json) => json.default))
  return jsons.reduce((result, json) => ({ ...result, ...json }), {}) as UnionToIntersection<
    Awaited<ArrayElement<readonly T[]>>['default']
  >
}

export const createI18n = <T extends Locals>(locals: T) => {
  const languagesLoaders: LanguagesLoaders = {}
  const languagesKeySets: LanguagesKeySets = Object.keys(locals).reduce((acc: Record<string, LanguageConfig>, lang) => {
    languagesLoaders[lang] = () => mergeJsonFiles((locals[lang as keyof Locals] as KeySetFile).map((fn) => fn()) as any)
    acc[lang] = {
      keyset: languagesLoaders[lang],
      pluralize: createPluralize(lang),
    }
    return acc
  }, {})

  // @ts-ignore
  const i18n: I18N<KeySetsMap<T>> = new I18N({
    defaultLang: getLang(),
    languages: languagesKeySets,
  })

  if (!global.i18n) {
    global.i18n = i18n
  }

  const t = i18n.get.bind(global.i18n)
  return { i18n, t, languagesLoaders }
}

export type Localization = ReturnType<typeof createI18n>
