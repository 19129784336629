import { axios } from 'packages/core'

import { feedbackEndpoints } from './endpoints'
import { Feedback } from './interfaces'

const sendFeedback = async (feedback: Feedback): Promise<void> => {
  const { data } = await axios.request({
    method: 'POST',
    ...feedbackEndpoints.sendFeedback(),
    data: feedback,
    tracing: {
      stream: null,
      module: 'feedback',
      methodName: 'sendFeedback',
    },
  })
  return data
}

export const feedbackApi = {
  sendFeedback,
}
