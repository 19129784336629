import { Row } from '@tanstack/table-core'
import CloseEye from 'assets/images/eye-close.svg?react'
import OpenEye from 'assets/images/eye-open.svg?react'
import cx from 'clsx'
import { TRow } from 'interfaces/table.interfaces'
import { getParentId } from 'packages/ui/Table/helpers/getParentId'
import { getRowId } from 'packages/ui/Table/helpers/getRowId'
import classes from 'packages/ui/Table/Table.module.scss'
import { RowId } from 'packages/ui/Table/types'

interface Props<TData extends TRow> {
  row: Row<TData>
  prevRow?: Row<TData>
  nextRow?: Row<TData>
  isParent?: (rowId: RowId) => boolean
  isMouseOverParent?: (rowId: RowId) => void
  isMouseOutParent?: () => void
  hoverParent?: RowId
  toggleCollapse: (rowId: RowId) => void
  isActiveCollapse?: boolean
}

export const EyeToggler = <TData extends TRow>({
  row,
  prevRow,
  nextRow,
  hoverParent,
  isMouseOutParent,
  isMouseOverParent,
  isParent,
  toggleCollapse,
  isActiveCollapse,
}: Props<TData>) => {
  const rowId = getRowId(row)
  const rowAttribute = (row.original as any)?.attribute
  const nextRowAttribute = (nextRow?.original as any)?.attribute
  const isParentRow = isParent?.(rowAttribute)
  const parentId = getParentId(row)
  const isStartGroup =
    (!!parentId && getParentId(prevRow) !== parentId) || (isParentRow && getParentId(prevRow) !== rowAttribute)
  const isInGroup = !!parentId
  const isEndGroup =
    (!!parentId && getParentId(nextRow) !== parentId && nextRow && !isParent?.(nextRowAttribute)) ||
    (isParentRow && getParentId(nextRow) !== rowAttribute)

  if (!isParentRow) {
    return (
      <td
        className={cx(classes.arrowTd, classes.eyeTd, {
          [classes.isStartGroup]: isStartGroup,
          [classes.isInGroup]: isInGroup,
          [classes.isEndGroup]: isEndGroup,
          [classes.eyeHover]: hoverParent === parentId,
        })}
      />
    )
  }

  return (
    <td
      className={cx(classes.arrowTd, classes.eyeTd, classes.isParent, {
        [classes.isStartGroup]: isStartGroup,
        [classes.isInGroup]: isInGroup,
        [classes.isEndGroup]: isEndGroup,
        [classes.eyeHover]: hoverParent === rowAttribute,
      })}
    >
      {isActiveCollapse ? (
        <OpenEye
          className={cx(classes.arrowCollapsed, classes.eyeOpened)}
          onClick={() => {
            toggleCollapse(rowId)
          }}
          onMouseOut={isMouseOutParent}
          onMouseOver={() => isMouseOverParent?.(rowAttribute)}
        />
      ) : (
        <CloseEye
          className={cx(classes.arrowCollapsed, classes.eyeClosed, classes.disabled)}
          onClick={() => {
            toggleCollapse(rowId)
          }}
        />
      )}
    </td>
  )
}
