import { useLocation } from '@tanstack/react-router'
import { AuthLayout, MainLayout } from 'packages/app/layout'
import { isProtectedPageWithoutMain } from 'packages/core/auth'

export const useLayout = () => {
  const { pathname } = useLocation()

  const Layout = isProtectedPageWithoutMain(pathname) ? MainLayout : AuthLayout

  return { Layout }
}
