import { generateErrorsState, UseErrors, ValidationError } from 'modules/errorsState'
import { AttributeFilterData } from 'modules/filters/attribute/api'
import { ValueFilterData } from 'modules/filters/value/api'
import { devtools } from 'packages/core'
import { create } from 'zustand'

export type StepError = ValidationError<string, keyof AttributeFilterData | keyof ValueFilterData>
export type UseStepsErrors = UseErrors<StepError>

export const useStepsStateErrors = create<UseStepsErrors>()(
  devtools((set, get) => generateErrorsState<StepError>(set, get), {
    store: 'stepsErrors',
  }),
)
