export const getChildrenItems = <
  TValue extends { id: number | string; _level?: number; _disableSelect?: boolean; _hideSelect?: boolean },
>(
  rows: TValue[],
  id: number | string,
  itemIndex?: number,
) => {
  const itemIndexInternal = itemIndex ?? rows?.findIndex((row) => row.id === id)
  if (itemIndexInternal === undefined || itemIndexInternal === -1 || !rows) {
    return []
  }

  const rowData = rows[itemIndexInternal]
  let i = itemIndexInternal + 1
  const result = []
  const parentLevel = rowData?._level
  let childOptionData = rows[i]
  let childLevel = childOptionData?._level
  while (childLevel !== undefined && parentLevel !== undefined && childLevel > parentLevel) {
    if (!childOptionData._disableSelect && !childOptionData._hideSelect) {
      result.push(rows[i].id)
    }
    i++
    childOptionData = rows[i]
    childLevel = childOptionData?._level
  }

  return result
}
