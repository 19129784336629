import { useForecastTable as getStateForecastTable } from 'demand/forecast/store'
import { difference, isEqual, uniq } from 'lodash'
import { devtools } from 'packages/core'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

import { UseChangedCells } from './types'

export const useChangedCells = create<UseChangedCells>()(
  subscribeWithSelector(
    devtools(
      (set, get) => ({
        changedCells: [],
        addChangedCells: (changedCells) =>
          set(
            (prev) => {
              const newChangedCells = [
                ...prev.changedCells.filter(
                  (item) => !changedCells.find((cell) => isEqual(item.location, cell.location)),
                ),
                ...changedCells,
              ]
              const newPages = uniq(newChangedCells.map((item) => item.location.page))
              const setNeedLoadingPages = getStateForecastTable.getState().setNeedLoadingPages
              const data = getStateForecastTable.getState().data
              setNeedLoadingPages(difference(newPages, data ? Object.keys(data).map(Number) : []))
              if (isEqual(prev.changedCells, newChangedCells)) {
                return prev
              }
              return { changedCells: newChangedCells }
            },
            undefined,
            'addChangedCells',
          ),
        toError: (changedCells, error) =>
          set(
            (prev) => ({
              changedCells: prev.changedCells.map((item) => {
                const found = changedCells.find((cell) => isEqual(item.location, cell.location))
                if (found) {
                  return { ...item, status: 'error', error }
                }
                return item
              }),
            }),
            undefined,
            'toError',
          ),
        toNeedToSend: (changedCells) =>
          set(
            (prev) => ({
              changedCells: prev.changedCells.map((item) => {
                const found = changedCells.find((cell) => isEqual(item.location, cell.location))
                if (found) {
                  return { ...found, status: 'need_to_send' }
                }
                return item
              }),
            }),
            undefined,
            'toNeedToSend',
          ),
        toSent: (changedCells, loaderSlug) =>
          set(
            (prev) => ({
              changedCells: prev.changedCells.map((item) => {
                const found = changedCells.find((cell) => isEqual(item.location, cell.location))
                if (found) {
                  return { ...found, status: 'sent', loaderSlug }
                }
                return item
              }),
            }),
            undefined,
            'toSent',
          ),
        remove: (changedCells) =>
          set(
            (prev) => ({
              changedCells: prev.changedCells.filter(
                (item) => !changedCells.find((cell) => isEqual(item.location, cell.location)),
              ),
            }),
            undefined,
            'remove',
          ),
        getByLocation: (location) => get().changedCells.find((cell) => isEqual(location, cell.location)),
        clear: () => set({ changedCells: [] }),
      }),
      {
        store: 'forecastChangedCells',
      },
    ),
  ),
)
