import { createEndpoint, NoParamEndpointConstructor } from 'packages/core'

const baseUrl = '/account'

interface Endpoints {
  getPermissions: NoParamEndpointConstructor
}

export const permissionsEndpoints: Endpoints = {
  getPermissions: () => createEndpoint(`${baseUrl}/permissions`),
}
