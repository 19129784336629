import { forwardRef, memo, MutableRefObject, useEffect, useRef } from 'react'

import CanvasJSReact from '@canvasjs/react-charts'
import { usePrevious, useSize } from 'ahooks'
import cx from 'clsx'
import { useCombinedRef } from 'hooks/useCombinedRef'
import { isEqual } from 'lodash'

import { ChartOptions, ChartRef } from './interfaces'

const CanvasJSStockChart = CanvasJSReact.CanvasJSChart

interface Props {
  className?: string
}

export const Chart = memo(
  forwardRef<HTMLDivElement, { options: ChartOptions; refChart?: MutableRefObject<ChartRef | null> } & Props>(
    ({ options, className }, ref) => {
      const refInstance = useRef<ChartRef | null>(null)
      const refWrap = useRef<HTMLDivElement>(null)

      const size = useSize(refWrap)
      const previousWidth = usePrevious(size?.width)

      const { cbRef: cbRefWrap } = useCombinedRef(refWrap, ref)

      useEffect(() => {
        if (size?.width && previousWidth && refInstance.current) {
          refInstance.current.render()
        }
      }, [size?.width])

      return (
        <div className={cx('canvasjs', className)} ref={cbRefWrap}>
          <CanvasJSStockChart options={options} />
        </div>
      )
    },
  ),
  (prevProps, nextProps) => isEqual(prevProps.options, nextProps.options),
)
