import { RefObject } from 'react'

import { Frame } from 'packages/ui/Table/interfaces'

import { getBorders } from './getBorders'

export const getFrameStyle = (frame: Frame, tableRef: RefObject<HTMLTableElement>) => {
  if (!tableRef.current) {
    return
  }
  const { minColumn, maxColumn, minRow, maxRow } = getBorders(frame)
  const { rows } = tableRef.current
  const cells = rows[0].cells
  let width = 0
  let height = 1
  let left = 0
  let top = -1
  for (let columnIdx = minColumn; columnIdx <= Math.min(cells.length - 1, maxColumn); columnIdx++) {
    const cellWidth = cells[columnIdx].getBoundingClientRect().width
    width += cellWidth
  }
  for (let rowIdx = minRow; rowIdx <= Math.min(rows.length - 1, maxRow); rowIdx++) {
    const rowHeight = rows[rowIdx].getBoundingClientRect().height
    height += rowHeight
  }

  for (let columnIdx = 0; columnIdx < Math.min(cells.length - 1, minColumn); columnIdx++) {
    const cellWidth = cells[columnIdx].getBoundingClientRect().width
    left += cellWidth
  }

  for (let rowIdx = 0; rowIdx < Math.min(rows.length - 1, minRow); rowIdx++) {
    const rowHeight = rows[rowIdx].getBoundingClientRect().height
    top += rowHeight
  }

  return { width, height, left, top }
}
