import { FC, memo, MutableRefObject } from 'react'

import { ExcelCells } from './ExcelCells'
import classes from './ExcelTable.module.scss'
import { usePagesSlice } from './handlers/usePagesSlice'

interface ExcelPagesProps {
  getPerPage: () => number
  getCountX: () => number
  getCountY: () => number
  getPaginateAxis: () => 'x' | 'y'
  setSlicePages: (slicePages: [number, number]) => void
  refCellsOptions: MutableRefObject<{
    onKeyDown: ((event: KeyboardEvent) => Promise<(number | null)[][] | undefined>)[][]
  }>
}

export const ExcelPages: FC<ExcelPagesProps> = memo(
  ({ getPerPage, getCountX, getCountY, getPaginateAxis, setSlicePages, refCellsOptions }) => {
    const perPage = getPerPage()
    const paginateAxis = getPaginateAxis()

    const { slice } = usePagesSlice(getPerPage, getCountX, getCountY, getPaginateAxis, setSlicePages)
    const pages = Array.from({ length: slice[1] + 1 - slice[0] })

    return (
      <div className={classes.cells}>
        {pages.map((_, index) => (
          <ExcelCells
            countX={getCountX()}
            countY={getCountY()}
            key={slice[0] + index + 1}
            page={slice[0] + index + 1}
            paginateAxis={paginateAxis}
            perPage={perPage}
            refCellsOptions={refCellsOptions}
          />
        ))}
      </div>
    )
  },
)
