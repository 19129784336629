import { isProtectedPage } from 'packages/core/auth/controlAuth'

import { logoutBroadcastChannel } from './logoutBroadcastChannel'

export const subscribeBroadcastLogout = (logoutClear: () => void) => {
  logoutBroadcastChannel.onmessage = (event: MessageEvent) => {
    if (event.data.type === 'logout') {
      if (document.visibilityState === 'visible') {
        return
      }
      logoutClear()
      if (isProtectedPage()) {
        window.sessionStorage.setItem('lastPage', window.location.pathname)
      }
    }
  }
}
