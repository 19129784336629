import { RefObject } from 'react'

import { useThrottleFn, useUpdate } from 'ahooks'
import cls from 'clsx'
import { useResizeObserver } from 'hooks/useResizeObserver'
import { getFrameStyle } from 'packages/ui/Table/helpers/getFrameStyle'
import { SelectionData } from 'packages/ui/Table/interfaces'

import classes from './SelectionFrame.module.scss'

interface Props {
  tableRef: RefObject<HTMLTableElement>
  selection: SelectionData
  showBorderCell?: boolean
}

export const SelectionFrame = ({ tableRef, selection }: Props) => {
  const { frame, inProgress, active } = selection
  const frameStyle = getFrameStyle(frame, tableRef)
  const update = useUpdate()
  const { run: onResizeThrottled } = useThrottleFn(update, { wait: 50 })
  useResizeObserver({ ref: tableRef, callback: onResizeThrottled, enabled: active })

  return (
    <div
      className={cls(classes.frame, {
        [classes.inProgress]: inProgress,
      })}
      style={frameStyle}
    />
  )
}
