import { ChartDataSeriesOptions } from 'canvasjs'
import { CellLocation, ChangedCell, ExcelState, TableState } from 'interfaces/excelTable.interfaces'

import { createChartOptions } from './createChartOptions'

export const createExcelChartOptions = (
  data: ExcelState['data'],
  tableState: TableState,
  visibleCharts: Record<string, boolean>,
  getChangedCell?: (location: CellLocation) => ChangedCell | undefined,
) => {
  if (!data) {
    return
  }
  const key = Number(Object.keys(data).at(-1)) || 0
  const columns = data[key]?.columns
  const rows = data[key]?.rows
  const rowsFormatter = tableState.chartOptions?.rowsFormatter
  const cells = rowsFormatter ? rowsFormatter(data[key]?.cells, rows) : data[key]?.cells
  const columnToChart = tableState.chartOptions?.columnToChart || {}
  const columnName = tableState.chartOptions?.columnName
  const columnGroup = Object.keys(columnToChart)

  const newData: ChartDataSeriesOptions[] = rows
    ?.map((row, index) => {
      const columnValue = String(row.find((cell) => cell.name === columnName)?.value)
      if (!columnGroup.includes(columnValue)) {
        return
      }

      const option: ChartDataSeriesOptions = {
        type: columnToChart[columnValue].type,
        showInLegend: false,
        dataPoints: cells?.[index].map((cell, cellIndex) => {
          const value = getChangedCell?.({
            indexes: [index, cellIndex],
            page: 1,
          })?.value

          return {
            x: cellIndex,
            label: tableState.formatDates
              ? String(tableState.formatDates(String(columns[cellIndex][0].value)))
              : String(columns[cellIndex][0].value),
            y: value || Number(cell.value || 0),
            tooltipY: data[key]?.cells[index][cellIndex].value || 0,
          }
        }),
        name: columnValue,
        legendText: columnValue,
        color: columnValue && columnToChart[columnValue].color,
        markerSize: 0,
        lineDashType: columnToChart[columnValue].lineDashType,
      }

      return option
    })
    .filter(Boolean)

  return createChartOptions(newData, { viewportMinimum: 0, viewportMaximum: cells?.[0]?.length })
}
