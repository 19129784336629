import { Streams } from 'constants/streams'
import { ModuleConnector } from 'packages/app'
import { t } from 'packages/localization'

export const prgmReportsPromoModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    reports: {
      label: () => t('prgm.navMenu.reports'),
      href: `/${Streams.Prgm}/reports`,
      dependence: (appContext) =>
        appContext.modules.permissions?.readReportsDb || appContext.modules.permissions?.editReportsDb,
    },
  },
})
