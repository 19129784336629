import { FC, ReactElement, ReactNode, RefObject, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { useIsClient } from 'usehooks-ts'

interface PortalProps {
  width?: number
  disable?: boolean
  enableSSR?: boolean
  disableRender?: boolean
  children: ReactNode
  container?: RefObject<HTMLElement> | HTMLElement | null
}

export const Portal: FC<PortalProps> = ({ children, width, disable, enableSSR, disableRender, container }) => {
  const containerNode = ((container as RefObject<HTMLElement>)?.current ?? (container as HTMLElement)) || null
  const [windowWidth, setWindowWidth] = useState(0)
  const [containerInternal, setContainerInternal] = useState<HTMLElement | null>(containerNode)

  const isClient = useIsClient()

  useEffect(() => {
    if (containerNode) {
      setContainerInternal(containerNode)
    }
  }, [containerNode])

  useEffect(() => {
    if (!container) {
      setContainerInternal(document.createElement('div'))
    }
  }, [isClient])

  useEffect(() => {
    const listener = () => {
      setWindowWidth(window.innerWidth)
    }
    if (width) {
      setWindowWidth(window.innerWidth)
      window.addEventListener('resize', listener)
    }
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [width])

  useEffect(() => {
    if (disableRender || !containerInternal || container) {
      return
    }
    document.body.appendChild(containerInternal)

    return () => {
      document.body.removeChild(containerInternal)
    }
  }, [containerInternal, disableRender])

  if (disableRender) {
    return null
  }

  if (enableSSR && !isClient && !containerInternal) {
    return children as ReactElement
  }

  if (!containerInternal) {
    return null
  }

  if (disable) {
    return children as ReactElement
  }

  if (width) {
    if (!windowWidth) {
      return null
    }
    return windowWidth > width ? (children as ReactElement) : createPortal(children, containerInternal)
  }

  return createPortal(children, containerInternal)
}
