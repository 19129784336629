import { useMemo } from 'react'

import { AppState } from 'packages/app/appState'
import { useModulesInit } from 'packages/app/modules'
import { ApplicationConfig, useApplicationInit } from 'packages/core'

export const useAppState = (applicationConfig: ApplicationConfig): AppState => {
  const appState: AppState = useMemo(() => ({}), [])

  const appOptions = useApplicationInit(applicationConfig, appState)
  const modules = useModulesInit(applicationConfig, appState)

  Object.assign(appState, appOptions, { modules: Object.assign(appState.modules || {}, modules) })

  return appState
}
