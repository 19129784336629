import { devtools } from 'packages/core'
import { MDProduct, ProductProp } from 'prgm/createPromoForm/api'
import { create } from 'zustand'

interface UseMDProduct {
  masterData: MDProduct
  setMasterDataProp: (prop: ProductProp, value?: number) => void
  setMasterData: (masterData: MDProduct) => void
  clear: () => void
}

export const useMDProduct = create<UseMDProduct>()(
  devtools(
    (set) => ({
      masterData: {},
      setMasterDataProp: (prop, value) =>
        set(({ masterData }) => {
          masterData[prop] = value
          return { masterData }
        }),
      setMasterData: (masterData) => set({ masterData }),
      clear: () => set({ masterData: {} }),
    }),
    {
      name: 'MDProduct',
    },
  ),
)
