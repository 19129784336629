import { forwardRef, Fragment, ReactNode } from 'react'

import cx from 'clsx'
import { toArray } from 'packages/helper'

import classes from './IndicatorsGrid.module.scss'

interface IndicatorsGridProps {
  className?: string
  children?: ReactNode
}

export const IndicatorsGrid = forwardRef<HTMLDivElement, IndicatorsGridProps>(({ className, children }, ref) => (
  <div className={cx(classes.indicators, className)} ref={ref}>
    {toArray(children).map((item, index) => (
      <Fragment key={index}>
        {item}
        <div className={classes.line} />
      </Fragment>
    ))}
  </div>
))
