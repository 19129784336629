import { RefObject, useEffect } from 'react'

export const useSyncStyleProp = (
  el: RefObject<HTMLElement> | undefined,
  elDependent: RefObject<HTMLElement> | null,
  styleProp1: keyof Element,
  styleProp2: keyof CSSStyleDeclaration,
  offset = 0,
  deps?: any[],
) => {
  useEffect(() => {
    if (!el?.current || !elDependent?.current) {
      return
    }
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const prop1 = Number(entry.target[styleProp1])
        if (elDependent.current) {
          elDependent.current.style[styleProp2 as any] = `${prop1 + offset}px`
        }
      })
    })
    resizeObserver.observe(el.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [el?.current, elDependent?.current, ...(deps || [])])
}
