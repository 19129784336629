import { useCallback } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import {
  ApplicationConfig,
  axios,
  GlobalContextProps,
  GlobalModulesState,
  setIsSessionStorageAuth,
  tokensBroadcastChannelListeners,
  tokensStorage,
  useUnsavedChangesState,
  ws,
} from 'packages/core'
import { clearTokens } from 'packages/core/auth/tokens'
import { getEffects } from 'packages/core/effects/getEffects'

export const useLogoutClear = <ModulesState extends GlobalModulesState = GlobalModulesState>(
  logout: ApplicationConfig['logout'],
  appState: Partial<GlobalContextProps<ModulesState>>,
) => {
  const logoutEffects = getEffects(logout?.onLogout)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const clearUnsavedChanges = useUnsavedChangesState((state) => state.clear)

  const logoutClear = useCallback(() => {
    clearTokens()
    logoutEffects.call()
    axios.setToken(null)
    navigate({ to: '/login' }).then(() => {
      appState.auth?.logout?.clearProfile?.()
      queryClient.removeQueries()
      clearUnsavedChanges()
      ws.getInstance()?.disconnect()
      setIsSessionStorageAuth(false)
      tokensStorage.clear()
      tokensBroadcastChannelListeners.length = 0
    })
  }, [queryClient])

  return { logoutClear }
}
