import Axios from 'axios'
import {
  headersJson,
  paramsSerializerToSnakeCaseArrayBrackets,
  transformRequestToSnakeCase,
  transformResponseToCamelCase,
} from 'packages/api'
import { GlobalParameters } from 'packages/core/config'
import { isTest } from 'packages/core/environment'

import { connectErrorAndTracingPipeline } from './errorAndTracingPipeline'
import { CustomAxiosInstance } from './types'
import { connectUnauthenticatedPipeline } from './unauthenticatedPipeline'

export const createAxiosInstance = () => {
  const axios = Axios.create({
    paramsSerializer: paramsSerializerToSnakeCaseArrayBrackets,
    transformResponse: transformResponseToCamelCase,
    transformRequest: transformRequestToSnakeCase,
    headers: headersJson,
  }) as unknown as CustomAxiosInstance

  if (isTest) {
    axios.defaults.adapter = require('axios/lib/adapters/http')
  }

  axios.setToken = (token) => {
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
    } else {
      delete axios.defaults.headers.common.Authorization
    }
  }

  axios.setBaseURL = (baseURL) => {
    axios.defaults.baseURL = baseURL
  }

  axios.connectPipelines = (globalParameters?: GlobalParameters, isTestingEnvironment?: boolean) => {
    if (!isTestingEnvironment) {
      connectUnauthenticatedPipeline(axios)
    }
    connectErrorAndTracingPipeline(axios, {
      tracing: {
        enable: globalParameters?.ENABLE_TRACING,
      },
    })
  }

  return axios
}
