import { ModuleConnector } from 'packages/app'
import { t } from 'packages/localization'

export const deployPpedModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    pped: {
      label: () => t('deploy.navMenu.reporting.pped'),
      href: '/deploy/pped',
    },
  },
})
