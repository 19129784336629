import { FC, ReactNode } from 'react'

import cx from 'clsx'
import { Error } from 'packages/ui/Error'

import classes from './FormItem.module.scss'

export enum FormItemSizes {
  Medium = 'medium',
  Regular = 'regular',
  Small = 'small',
}

export enum FormItemColors {
  Default = 'default',
  Primary = 'primary',
}

export enum FormItemPositions {
  Center = 'center',
  Top = 'top',
}

interface FormItemProps {
  className?: string
  classNameError?: string
  error?: string | ReactNode | null
  disabled?: boolean
  children?: ReactNode
  size?: FormItemSizes
  label?: string
  emptyLabel?: boolean
  spacing?: boolean
  spacingLarge?: boolean
  disabledDark?: boolean
  color?: FormItemColors
  labelPosition?: FormItemPositions
  labelClassName?: string
  containerClassName?: string
  labelFlexStart?: boolean
}

export const FormItem: FC<FormItemProps> = ({
  className,
  classNameError,
  error,
  children,
  disabled,
  disabledDark,
  size = FormItemSizes.Regular,
  label,
  spacing,
  spacingLarge,
  emptyLabel,
  color = FormItemColors.Default,
  labelPosition = FormItemPositions.Center,
  labelClassName,
  containerClassName,
  labelFlexStart,
}) => (
  <div
    className={cx(className, classes.inputWrap, classes[size], classes[color], classes[`label-${labelPosition}`], {
      disabledInput: disabled,
      [classes.spacing]: spacing,
      [classes.spacingLarge]: spacingLarge,
      [classes.disabledDark]: disabledDark,
      [classes.hasError]: !!error,
    })}
  >
    {(label || emptyLabel) && (
      <div className={cx(classes.label, labelClassName, { [classes.labelFlexStart]: labelFlexStart })}>{label}</div>
    )}
    <div className={cx(classes.input, containerClassName)}>
      {children}
      {error && <Error className={cx(classes.error, classNameError)}>{error}</Error>}
    </div>
  </div>
)
