import { useMutation } from '@tanstack/react-query'
import { feedbackApi } from 'modules/feedback/api'
import { Feedback, FeedbackForm } from 'modules/feedback/api/interfaces'
import { getError } from 'packages/api'
import { useCurrentStream, useProfile } from 'packages/app'
import { t } from 'packages/localization'

export const useSendFeedback = () => {
  const { profile } = useProfile()
  const { currentStream } = useCurrentStream()

  const { mutate, isPending, reset, error, isSuccess } = useMutation({
    mutationFn: (data: Feedback) => feedbackApi.sendFeedback(data),
    meta: {
      error: {
        message: t('feedback.sendError'),
        showToast: false,
      },
    },
  })

  const sendFeedback = (data: FeedbackForm) => {
    const feedback: Feedback = {
      ...data,
      url: window.location.href,
      user: profile?.id ?? 0,
      name: currentStream?.entity?.id ?? 0,
    }
    mutate(feedback)
  }

  return {
    sendFeedback,
    resetError: () => {
      reset()
    },
    isPending,
    error: getError(error),
    isSuccess,
  }
}
