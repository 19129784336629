import { CellFields, CellLocation, ExcelCellData, ExcelState } from 'interfaces/excelTable.interfaces'

export const getOffsetCell = <State extends ExcelState = ExcelState>(
  offsetY: number,
  offsetX: number,
  getState: (() => State) | undefined,
  indexColumn: number,
  indexRow: number,
  page: number,
): { cell: ExcelCellData | undefined | null; location: CellLocation; fields: CellFields } | undefined => {
  const state = getState?.()
  const count = state?.paginateAxis === 'y' ? state.countY : state?.countX
  let nextPage = page
  let nextRow = indexRow + offsetY
  let nextCell = indexColumn + offsetX

  if (state?.paginateAxis === 'x' && count && state.perPage) {
    const current = (page - 1) * state.perPage + 1 + indexColumn
    if (count < current + offsetX) {
      return
    }
    if ((state.data?.[page]?.rows.length || 0) < indexRow + 1 + offsetY) {
      return
    }
    nextPage = Math.ceil((current + offsetX) / state.perPage)
    if (nextPage !== page) {
      nextCell = current + offsetX - 1 - (nextPage - 1) * state.perPage
    }
  }

  if (state?.paginateAxis === 'y' && count && state.perPage) {
    const current = (page - 1) * state.perPage + 1 + indexRow
    if (count < current + offsetY) {
      return
    }
    if ((state.data?.[page]?.columns.length || 0) < indexColumn + 1 + offsetX) {
      return
    }
    nextPage = Math.ceil((current + offsetY) / state.perPage)
    if (nextPage !== page) {
      nextRow = current + offsetY - 1 - (nextPage - 1) * state.perPage
    }
  }

  const rows = state?.data?.[nextPage]?.rows[nextRow]
  const columns = state?.data?.[nextPage]?.columns[nextCell]
  const fields: CellFields = {}

  rows?.forEach(({ code, value }) => (fields[code] = value))
  columns?.forEach(({ code, value }) => (fields[code] = value))

  return {
    cell: state?.data?.[nextPage]?.cells[nextRow]?.[nextCell] || null,
    location: {
      page: nextPage,
      indexes: [nextRow, nextCell],
    },
    fields,
  }
}
