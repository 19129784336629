import { CustomAxiosRequestConfig } from 'packages/core/apiConfig'

import { defaultSpanOptions } from './defaultSpanOptions'
import { addHttpData } from './helpers/addHttpData'
import { tracer } from './tracer'

export const tracingRequestInterceptor = (request: CustomAxiosRequestConfig) => {
  request.span = tracer.startSpan(`${request.method?.toUpperCase()} ${request.apiRoute}`, defaultSpanOptions)
  addHttpData(request.span, request)
  request.span.setAttribute('request_url', String(request.url))
  request.span.setAttribute('request_baseURL', String(request.baseURL))
  request.span.setAttribute('request_params', JSON.stringify(request.params))
  if (request.tracing.stream) {
    request.span.setAttribute('stream', request.tracing.stream)
  }
  request.span.setAttribute('module', request.tracing.module)
  request.span.setAttribute('method_name', request.tracing.methodName)
  return request
}
