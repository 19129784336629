import { memo, MutableRefObject, useContext, useEffect, useMemo, useState } from 'react'

import { ExcelCellData, SubscribeCell } from 'interfaces/excelTable.interfaces'

import { ExcelTableContext } from './context/ExcelTableContext'
import { ExcelCellsRow } from './ExcelCellsRow'
import { useCellsReady } from './handlers/useCellsReady'
import { useCellsSliceX } from './handlers/useCellsSliceX'
import { useCellsSliceY } from './handlers/useCellsSliceY'
import { parseCells } from './helpers/parseCells'

interface ExcelCellsProps {
  page: number
  perPage: number
  countX: number
  countY: number
  paginateAxis: 'x' | 'y'
  refCellsOptions: MutableRefObject<{
    onKeyDown: ((event: KeyboardEvent) => Promise<(number | null)[][] | undefined>)[][]
  }>
}

export const ExcelCells = memo(({ page, perPage, countX, countY, paginateAxis, refCellsOptions }: ExcelCellsProps) => {
  const { tableState } = useContext(ExcelTableContext)
  const initCells = tableState.getState?.().data?.[page]?.cells
  const initializeCells = (resultCells: ExcelCellData[][] | undefined) =>
    parseCells(resultCells, tableState.subscribe, tableState.getState, page, perPage, countX, countY, paginateAxis)

  const { cellsWithSubscriber: initCellsWithSubscriber } = useMemo(() => initializeCells(initCells), [initCells])

  const [cells, setCells] = useState<SubscribeCell[][]>(initCellsWithSubscriber)
  const { sliceY } = useCellsSliceY(paginateAxis, page, perPage)
  const { sliceX } = useCellsSliceX(paginateAxis, page)
  const slicedCells = cells.slice(sliceY[0], sliceY[1] + 1)

  const { ready } = useCellsReady()

  useEffect(() => {
    if (!countX || !perPage) {
      return
    }
    return tableState.subscribe?.(
      ({ data }) => data?.[page]?.cells,
      (resultCells: ExcelCellData[][] | undefined) => {
        if (resultCells === initCells) {
          return
        }
        const { cellsWithSubscriber } = initializeCells(resultCells)
        setCells(cellsWithSubscriber)
      },
    )
  }, [tableState.subscribe, countX, perPage])

  return (
    <>
      {slicedCells.map((row, rowIndex) => {
        const indexRow = paginateAxis === 'x' ? sliceY[0] + rowIndex : (page - 1) * perPage + sliceY[0] + rowIndex
        return (
          <ExcelCellsRow
            cells={row.slice(sliceX[0], sliceX[1] + 1)}
            indexRow={indexRow}
            key={indexRow}
            pageX={page}
            paginateAxis={paginateAxis}
            perPage={perPage}
            ready={ready}
            refCellsOptions={refCellsOptions}
            startSliceX={sliceX[0]}
          />
        )
      })}
    </>
  )
})
