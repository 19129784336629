import { SELECT_ALL } from 'constants/components'
import { OptionTreeItem } from 'interfaces/components.interfaces'

interface ParseValueProps<ValueType extends string | number | (number | string)[], Meta = unknown> {
  valueStringWhenNotLoaded?: string
  value?: ValueType | null
  valueInternal?: ValueType | null
  setValueInternal: (value: ValueType | null) => void
  options?: OptionTreeItem<Meta>[]
  multiple?: boolean
  isTree?: boolean
  showSelectAll?: boolean
  total?: number
}

export const parseValue = <ValueType extends string | number | (number | string)[], Meta = unknown>({
  valueStringWhenNotLoaded,
  value,
  valueInternal,
  setValueInternal,
  options,
  multiple,
  isTree,
  showSelectAll,
  total,
}: ParseValueProps<ValueType, Meta>) => {
  if (
    !multiple &&
    !isTree &&
    !valueStringWhenNotLoaded &&
    valueInternal === undefined &&
    value !== undefined &&
    options !== undefined
  ) {
    return options?.find((item) => item.value === value)?.value as ValueType
  }

  if (!multiple && !isTree && valueStringWhenNotLoaded && !options?.find((item) => item.value === valueInternal)) {
    return valueStringWhenNotLoaded as ValueType
  }

  if (
    Array.isArray(valueInternal) &&
    showSelectAll &&
    total &&
    valueInternal.filter((item) => item !== SELECT_ALL).length === total &&
    !valueInternal.includes(SELECT_ALL)
  ) {
    setValueInternal([SELECT_ALL, ...valueInternal] as ValueType)
  }

  return valueInternal
}
