import { createEndpoint, NoParamEndpointConstructor } from 'packages/core'

const baseUrl = '/feedback'

interface Endpoints {
  sendFeedback: NoParamEndpointConstructor
}

export const feedbackEndpoints: Endpoints = {
  sendFeedback: () => createEndpoint(`${baseUrl}`),
}
