import { axios } from 'packages/core/apiConfig'

import { logoutEndpoints } from './endpoints'
import { LogoutInformParams } from './interfaces'

const logout = async (params: LogoutInformParams): Promise<void> => {
  await axios.request({
    method: 'POST',
    data: params,
    ...logoutEndpoints.logout(),
    tracing: {
      stream: null,
      module: 'core.auth',
      methodName: 'logout',
    },
  })
}

export const logoutApi = {
  logout,
}
