import { isEffectFn, isEffectHook, isEffectHookWithFn } from './guards'
import { EffectOptions, Hook } from './types'

export class Effect<Args extends any[] = any[], H extends Hook = Hook> {
  public fn?: (...args: Args) => void
  public hook?: H
  public fnName?: keyof ReturnType<H>

  constructor(options: EffectOptions<Args, H>) {
    if (isEffectFn(options)) {
      this.fn = options.fn
    } else if (isEffectHookWithFn<Args, H>(options)) {
      this.hook = options.hook
      this.fnName = options.fnName
    } else if (isEffectHook<Args, H>(options)) {
      this.hook = options.hook
    }
  }

  getFunction(): (...args: Args) => void {
    return this.fn ?? (this.fnName ? this.hook?.()?.[this.fnName as string] : this.hook?.())
  }
}
