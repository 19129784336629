import { defaultPerPage } from 'constants/pagination'
import { Pagination } from 'packages/core'
import { deleteUndefined } from 'packages/helper'

export const defaultPagination = <T extends Pagination>(pagination?: T, perPage = defaultPerPage) => ({
  page: pagination?.page && pagination.page > 0 ? pagination.page : 1,
  perPage: pagination?.perPage && pagination.perPage > 0 ? pagination.perPage : perPage,
})

export const withoutPagination = <T extends Pagination>(data?: T) =>
  deleteUndefined({
    ...data,
    page: undefined,
    perPage: undefined,
    per_page: undefined,
  })
