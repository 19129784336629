import { forwardRef, HTMLAttributes } from 'react'

import { DraggableItem } from 'packages/ui/Draggable'

export const Tr = forwardRef<
  HTMLTableRowElement,
  Omit<HTMLAttributes<HTMLTableRowElement>, 'onChange'> & { isDraggable?: boolean; meta?: any; index: number }
>(({ isDraggable, children, meta, ...props }, ref) =>
  isDraggable ? (
    <DraggableItem as="tr" isDragElement={false} {...props} meta={meta} ref={ref}>
      {children}
    </DraggableItem>
  ) : (
    <tr {...props} ref={ref}>
      {children}
    </tr>
  ),
)
