import {
  ExcelAxisItem,
  ExcelState,
  Subscribe,
  SubscribeFixedColumnCell,
  TableState,
} from 'interfaces/excelTable.interfaces'

export const parsedFixedColumns = <State extends ExcelState = ExcelState>(
  columnsState: ExcelAxisItem[] | undefined,
  subscribe: Subscribe<State, any> | undefined,
  getState: (() => State) | undefined,
  defaultWidthColumn: number,
  setWidths: (widths: Record<string, number> | null) => void,
  tableState: TableState,
): { columnsCells: SubscribeFixedColumnCell[] } => {
  if (!columnsState) {
    return {
      columnsCells: [],
    }
  }

  const widths: Record<string, number> = {}

  const columnsCells = columnsState.reduce((newArray: SubscribeFixedColumnCell[], item, index) => {
    item.initWidth =
      tableState.storageWidths?.getFixedWidthByKey(item.code) || widths?.[item.code] || defaultWidthColumn
    widths[item.code] = item.initWidth

    newArray.push({
      subscribeCell: (listener: (selectedState: any, previousSelectedState: any) => void) =>
        subscribe?.(({ data }) => data?.[1].rows[0][index], listener),
      subscribeWidth: (listener: (selectedState: any, previousSelectedState: any) => void) =>
        subscribe?.(({ data, widthColumns }) => {
          const cellData = data?.[1].rows[0][index]
          return cellData?.code ? widthColumns.widths?.[cellData.code] : undefined
        }, listener),
      getWidth: () => getState?.().widthColumns.widths?.[item.code] || null,
      key: item.code,
      item,
      lastIndex: index,
      index,
      type: 'fixed',
      isLastIndex: getState?.().data?.[1]?.rows?.[0]?.length === index + 1,
    })
    return newArray
  }, [])

  setWidths(widths)

  tableState.getKeyFixedByIndex = (indexKey) => columnsState[indexKey]?.code

  return { columnsCells }
}
