import { useAuthBuilder } from 'packages/core/auth'
import { ApplicationConfig, GlobalContextProps } from 'packages/core/config'
import { useEnv } from 'packages/core/environment'
import { useMenuBuilder } from 'packages/core/menu'
import { useLangInitiated } from 'packages/localization'

export const useApplicationInit = (
  applicationConfig: ApplicationConfig,
  appState: Partial<GlobalContextProps>,
): Partial<GlobalContextProps> => ({
  ...useEnv(applicationConfig.global),
  ...useLangInitiated(applicationConfig.localization),
  ...useMenuBuilder(applicationConfig),
  ...useAuthBuilder(appState, applicationConfig),
})
