import { AppModulesState } from 'packages/app/modules/modulesState'
import { useSession } from 'packages/app/modules/session/store'
import { AuthBuilder } from 'packages/core/auth/controlAuth/authBuilder'

export const applySessionActions = async (builder: AuthBuilder<AppModulesState>, clearFunctions?: (() => void)[]) => {
  const session = useSession.getState()
  const currentUserId = builder.appState.modules?.profile?.id || null
  const currentActiveProjectId = builder.appState.modules?.profile?.activeProjectId || null

  const clearSessionData = () => {
    builder.appState.modules?.session?.clearCommonData?.()
    Object.values(builder.appState.modules?.streams || {}).forEach((stream) => {
      stream.session?.clearStreamData?.()
    })
    clearFunctions?.forEach((fn) => fn())
  }

  if (currentUserId === session.userId) {
    if (session.page) {
      builder.redirectPage = session.page
    }
  } else {
    session.setUserId(currentUserId)
    clearSessionData()
  }

  if (currentActiveProjectId !== session.activeProjectId) {
    session.setActiveProjectId(currentActiveProjectId)
    clearSessionData()
  }
}
