import { useEventListener } from 'ahooks'

interface CursorState {
  pageX: number
  pageY: number
}

export const mouseMoveState: CursorState = {
  pageX: NaN,
  pageY: NaN,
}

export const useGlobalMouseMove = () => {
  useEventListener(
    'mousemove',
    (event: MouseEvent) => {
      const { pageX, pageY } = event
      mouseMoveState.pageX = pageX
      mouseMoveState.pageY = pageY
    },
    {
      target: () => document,
    },
  )
}
