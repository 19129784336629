import { SelectedCells, SelectRect } from 'interfaces/excelTable.interfaces'
import { defaultSelectedCells } from 'packages/ui/ExcelTable/constants/selectedCells'

export const parseSelectRect = (
  selectedCells: SelectedCells | undefined,
  isFixed: boolean,
  indexRow: number,
  indexColumn: number,
): SelectRect => {
  const selected = selectedCells || defaultSelectedCells
  const positions = isFixed ? selected.fixed : selected.cells
  const isBoth =
    Math.max(selected.fixed.columns.start, selected.fixed.columns.end) > -1 &&
    Math.min(selected.cells.columns.start, selected.cells.columns.end) > -1

  const startColumn = Math.min(positions.columns.start, positions.columns.end)
  const endColumn = Math.max(positions.columns.start, positions.columns.end)
  const startRow = Math.min(positions.rows.start, positions.rows.end)
  const endRow = Math.max(positions.rows.start, positions.rows.end)

  const top = startRow === indexRow && startColumn <= indexColumn && endColumn >= indexColumn
  const bottom = endRow === indexRow && startColumn <= indexColumn && endColumn >= indexColumn
  const left = !isFixed && isBoth ? false : startColumn === indexColumn && startRow <= indexRow && endRow >= indexRow
  const right = isFixed && isBoth ? false : endColumn === indexColumn && startRow <= indexRow && endRow >= indexRow

  const isFirst =
    !isFixed &&
    startColumn === indexColumn &&
    startRow <= indexRow &&
    endRow >= indexRow &&
    startRow === indexRow &&
    startColumn <= indexColumn &&
    endColumn >= indexColumn

  return { top, bottom, left, right, isFirst, positions: { startColumn, endColumn, startRow, endRow } }
}
