import { useMemo } from 'react'

import { axios } from 'packages/core/apiConfig'
import { GlobalParameters } from 'packages/core/config'
import { tracer } from 'packages/core/tracing'

export const useApplyEnv = (global: GlobalParameters) => {
  useMemo(() => {
    if (global.ENABLE_TRACING && global.DSN) {
      tracer.configure(global.DSN)
    }
    axios.setBaseURL(global.API_URL)
    axios.connectPipelines(global)
  }, [global.API_URL, global.ENABLE_TRACING, global.DSN])
}
