import { storageAccessTokenName, storageIsSessionStorageAuthName } from 'packages/core/auth/authStorageNames'

export const setIsSessionStorageAuth = (value: boolean) => {
  window.localStorage.setItem(storageIsSessionStorageAuthName, String(value))
}

export const getIsSessionStorageAuth = () => window.localStorage.getItem(storageIsSessionStorageAuthName) === 'true'

export const enableSessionStorageAuthListener = () => {
  const accessTokenSessionStorage = window.sessionStorage.getItem(storageAccessTokenName)
  if (accessTokenSessionStorage) {
    setIsSessionStorageAuth(true)
    window.addEventListener('storage', (event) => {
      if (event.key === storageIsSessionStorageAuthName && event.newValue === 'false') {
        setIsSessionStorageAuth(true)
      }
    })
  }
}
