export const getParentIds = <TData extends { id: number | string; _level?: number }>(
  rows: TData[],
  id: number | string,
  itemIndex?: number,
) => {
  const itemIndexInternal = itemIndex ?? rows?.findIndex((row) => row.id === id)
  if (itemIndexInternal === undefined || itemIndexInternal === -1 || !rows) {
    return []
  }

  const rowData = rows[itemIndexInternal]
  let i = itemIndexInternal - 1
  const result = []
  const itemLevel = rowData?._level
  let parentOptionData = rows[i]
  let parentLevel = parentOptionData?._level
  while (parentLevel !== undefined && itemLevel !== undefined && parentLevel !== 0) {
    if (parentLevel < itemLevel) {
      result.push(rows[i].id)
    }
    i--
    parentOptionData = rows[i]
    parentLevel = parentOptionData?._level
  }
  if (parentLevel !== undefined && itemLevel !== undefined && parentLevel < itemLevel) {
    result.push(rows[i].id)
  }

  return result
}
