import { storageAccessTokenName, storageRefreshTokenName } from 'packages/core/auth/authStorageNames'

import { TokensDefined } from './types'

export const setTokens = (tokens: TokensDefined, remember = !!window.localStorage.getItem(storageRefreshTokenName)) => {
  const store = remember ? window.localStorage : window.sessionStorage
  const clearedStore = remember ? window.sessionStorage : window.localStorage
  store.setItem(storageAccessTokenName, tokens.accessToken)
  clearedStore.removeItem(storageAccessTokenName)

  store.setItem(storageRefreshTokenName, tokens.refreshToken)
  clearedStore.removeItem(storageRefreshTokenName)
}
