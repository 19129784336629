import { axios, GetArrayResponse } from 'packages/core/apiConfig'

import { permissionsEndpoints } from './endpoints'
import { Permission } from './interfaces'

const getPermissions = async (): GetArrayResponse<Permission> => {
  const { data } = await axios.request<Permission[]>({
    method: 'GET',
    ...permissionsEndpoints.getPermissions(),
    tracing: {
      stream: null,
      module: 'app.permissions',
      methodName: 'getPermissions',
    },
  })
  return data
}

export const permissionsApi = {
  getPermissions,
}
