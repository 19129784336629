import { getIsSessionStorageAuth, setIsSessionStorageAuth } from 'packages/core/auth/login'
import { tokensStorage } from 'packages/core/auth/tokens/tokensStorage'

import { tokensBroadcastChannel, tokensBroadcastChannelListeners } from './tokensBroadcastChannel'
import { Tokens } from './types'

export const requestBroadcastTokens = async (): Promise<Tokens> =>
  new Promise((resolve) => {
    if (!getIsSessionStorageAuth()) {
      return resolve({
        accessToken: null,
        refreshToken: null,
      })
    }
    const timer = window.setTimeout(() => {
      resolve({
        accessToken: null,
        refreshToken: null,
      })
      setIsSessionStorageAuth(false)
    }, 2000)

    tokensBroadcastChannel.postMessage({
      type: 'tokens_request',
    })

    tokensBroadcastChannelListeners.push((event: MessageEvent) => {
      if (event.data.type === 'tokens_receive') {
        clearTimeout(timer)
        tokensStorage.set(event.data.tokens)
        resolve(event.data.tokens)
      }
    })
  })
