import { Streams } from 'constants/streams'
import { jobsModule } from 'modules/jobs/jobsModule'
import { StreamConnector } from 'packages/app'
import { prgmApprovePromoModule } from 'prgm/approvePromo/prgmApprovePromoModule'
import { prgmCreatePromoModule } from 'prgm/createPromo/prgmCreatePromoModule'
import { prgmDataHeathPromoModule } from 'prgm/dataHealth/prgmDataHeathPromoModule'
import { prgmDataHubPromoModule } from 'prgm/dataHub/prgmDataHubPromoModule'
import { prgmPulseModule } from 'prgm/pulse/prgmPulseModule'
import { prgmReportsPromoModule } from 'prgm/reports/prgmReportsPromoModule'
import { prgmScorecardPromoModule } from 'prgm/scorecard/prgmScorecardPromoModule'
import { prgmSearchPromoModule } from 'prgm/searchPromo/prgmSearchPromoModule'

const prgmModules = {
  pulse: prgmPulseModule(),
  create: prgmCreatePromoModule(),
  search: prgmSearchPromoModule(),
  approve: prgmApprovePromoModule(),
  scorecard: prgmScorecardPromoModule(),
  dataHub: prgmDataHubPromoModule(),
  reports: prgmReportsPromoModule(),
  dataHealth: prgmDataHeathPromoModule(),
  jobs: jobsModule(undefined, { streamName: Streams.Prgm }),
}

export const connectPrgmStream: StreamConnector = () => ({
  name: Streams.Prgm,
  startPage: `/${Streams.Prgm}/pulse`,
  modules: prgmModules,
  menu: (menuBuilder) => {
    menuBuilder.addItem(prgmModules.pulse.menuItems.pulse)
    menuBuilder.addItem(prgmModules.create.menuItems.create)
    menuBuilder.addItem(prgmModules.search.menuItems.search)
    menuBuilder.addItem(prgmModules.approve.menuItems.approve)
    menuBuilder.addItem(prgmModules.scorecard.menuItems.scorecard)
    menuBuilder.addItem(prgmModules.dataHub.menuItems.dataHub)
    menuBuilder.addItem(prgmModules.reports.menuItems.reports)
    menuBuilder.addItem(prgmModules.dataHealth.menuItems.dataHealth)
    menuBuilder.addItem(prgmModules.jobs.menuItems.jobs)
  },
})
