import { useCallback, useMemo, useRef, useState } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { useAsyncEffect } from 'ahooks'
import {
  broadcastLogin,
  enableSessionStorageAuthListener,
  setIsSessionStorageAuth,
  subscribeBroadcastLogin,
} from 'packages/core/auth/login'
import { broadcastLogout, subscribeBroadcastLogout, useLogoutClear } from 'packages/core/auth/logout'
import { subscribeRequestTokens } from 'packages/core/auth/tokens'
import { ApplicationConfig, GlobalContextProps } from 'packages/core/config'
import { ws } from 'packages/core/ws'
import { global } from 'packages/helper'

import { AuthBuilder } from './authBuilder'
import {
  defineRedirectPage,
  defineTokens,
  redirectToDefinedPage,
  redirectToLoginPage,
  saveLastPage,
  setAuthenticatedPipe,
  setLocationIsCorrect,
} from './pipes'

export const useAuthBuilder = (
  appState: Partial<GlobalContextProps>,
  applicationConfig: ApplicationConfig,
): Partial<GlobalContextProps> => {
  const [authReady, setAuthReady] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)
  const navigate = useNavigate()
  const authBuilder = useMemo(() => new AuthBuilder({ setAuthReady, setAuthenticated, navigate, appState }), [])
  const { logoutClear } = useLogoutClear(applicationConfig.logout, appState)

  const runInitialRequests = useCallback(async () => {
    setAuthReady(false)
    await appState.modules?.initialRequests?.run(authBuilder)
  }, [appState.modules?.initialRequests, authBuilder])

  const initWs = useCallback(() => {
    ws.init(applicationConfig.global.WS_URL)
  }, [applicationConfig.global.WS_URL])

  const authenticate = useCallback(
    (...args: Parameters<typeof authBuilder.authenticate>) => {
      const [tokens, remember] = args
      if (tokens) {
        broadcastLogin(tokens, !!remember)
        setIsSessionStorageAuth(!remember)
      }
      return authBuilder.authenticate(...args)
    },
    [authBuilder],
  )

  useAsyncEffect(async () => {
    global.logout = () => {
      setAuthenticated(false)
      setAuthReady(true)
      broadcastLogout()
      logoutClear()
    }

    authBuilder.afterAuthenticate = async (needInitialRequests = true) => {
      if (needInitialRequests) {
        await runInitialRequests()
        initWs()
        enableSessionStorageAuthListener()
      }
      await authBuilder.apply(defineRedirectPage)
      await authBuilder.apply(redirectToDefinedPage)
    }

    await authBuilder.apply(subscribeRequestTokens)
    await authBuilder.apply(() => subscribeBroadcastLogin((tokens) => authBuilder.authenticate(tokens)))
    await authBuilder.apply(() => subscribeBroadcastLogout(logoutClear))
    await authBuilder.apply(defineTokens)
    enableSessionStorageAuthListener()

    if (authBuilder.isAuthenticated) {
      await runInitialRequests()
      initWs()
      await authBuilder.apply(setLocationIsCorrect)
      await authBuilder.apply(setAuthenticatedPipe)
      if (!authBuilder.locationIsCorrect) {
        authBuilder.afterAuthenticate(false)
      }
    } else {
      await authBuilder.apply(saveLastPage)
      await authBuilder.apply(redirectToLoginPage)
    }
  }, [])

  const refAuth = useRef({
    authReady,
    authenticated,
    authenticate,
    setAuthenticated,
    loginForm: applicationConfig.loginForm,
    logout: applicationConfig.logout || {},
  })

  return {
    auth: Object.assign(refAuth.current, { authReady, authenticated }),
  }
}
