import { localization } from 'config/i18n'
import { API_URL, APP_NAME, DSN, ENABLE_SSO, ENABLE_TRACING, LOGO, WS_URL } from 'constants/env'
import { Streams } from 'constants/streams'
import { connectDemandStream } from 'demand/connectDemandStream'
import { connectDeployStream } from 'deploy/connectDeployStream'
import { useAfterLogout } from 'hooks/useAfterLogout'
import { Feedback } from 'modules/feedback/components/Feedback'
import { Permissions } from 'modules/permissions'
import { connectOptiStream } from 'opti/connectOptiStream'
import { connectStreams } from 'packages/app'
import { ApplicationConfig, createEffect } from 'packages/core'
import { connectPrgmStream } from 'prgm/connectPrgmStream'

export const applicationConfig: ApplicationConfig = {
  global: {
    API_URL,
    WS_URL,
    APP_NAME,
    LOGO,
    DSN,
    ENABLE_SSO,
    ENABLE_TRACING,
  },
  permissionsEnum: Permissions,
  localization,
  logout: {
    onLogout: createEffect({
      hook: useAfterLogout,
    }),
  },
  streams: connectStreams<Streams.Prgm | Streams.Demand | Streams.Deploy | Streams.Opti>({
    [Streams.Prgm]: connectPrgmStream(),
    [Streams.Demand]: connectDemandStream(),
    [Streams.Deploy]: connectDeployStream(),
    [Streams.Opti]: connectOptiStream(),
  }),
  commonBottomMenu: (builder) => {
    builder.addItem({ component: <Feedback /> })
  },
}
