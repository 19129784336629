import { forwardRef, MouseEventHandler, ReactNode, useEffect, useRef } from 'react'

import { useSafeState } from 'ahooks'
import cx from 'clsx'
import { useCombinedRef } from 'hooks/useCombinedRef'
import { useDisabledScroll } from 'hooks/useDisabledScroll'
import { useIsMountedMs } from 'hooks/useIsMountedMs'
import { Animation, AnimationTypes } from 'packages/ui/Animation'
import { Portal } from 'packages/ui/Portal'

import classes from './OverlayingModal.module.scss'

interface OverlayingModalProps {
  className?: string
  classNamePopup?: string
  onClose?: () => void
  isOpened?: boolean
  disabledScroll?: boolean
  animationType?: AnimationTypes
  animationDuration?: number
  children?: ReactNode
}

export const OverlayingModal = forwardRef<HTMLDivElement, OverlayingModalProps>(
  (
    {
      className,
      classNamePopup,
      children,
      onClose,
      isOpened,
      disabledScroll,
      animationType = AnimationTypes.SlideUpScale,
      animationDuration,
    },
    ref,
  ) => {
    const [ready, setReady] = useSafeState(false)
    const [isMounted] = useIsMountedMs(isOpened)

    const refInternal = useRef<HTMLDivElement>(null)
    const { cbRef } = useCombinedRef<HTMLDivElement>(ref, refInternal)

    const onClickAway: MouseEventHandler<HTMLDivElement> = (e) => {
      if (
        e.target === refInternal.current &&
        !refInternal.current?.querySelector('input[data-focus=true], textarea[data-focus=true]')
      ) {
        onClose?.()
      }
    }

    useDisabledScroll(typeof disabledScroll !== 'undefined' ? disabledScroll : isOpened, animationDuration, ready)

    useEffect(() => {
      setTimeout(() => {
        setReady(!!refInternal.current)
      }, 100)
    }, [isOpened, refInternal.current])

    return (
      <Portal disableRender={!isMounted}>
        <Animation
          className={cx(classes.wrap, className, animationType && classes[animationType])}
          classNameHide={classes.hide}
          delayAnimationShow={1}
          duration={animationDuration}
          type={animationType}
        >
          {isOpened ? (
            <div className={classes.overflow} onClick={onClickAway} ref={cbRef}>
              <div className={cx(classes.popup, classNamePopup)}>{children}</div>
            </div>
          ) : null}
        </Animation>
      </Portal>
    )
  },
)
