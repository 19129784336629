import { ColumnDef } from '@tanstack/table-core'
import { DefaultNumberToFixedCell } from 'packages/ui'
import { scenarioKeyToTitle } from 'prgm/scorecard/constants'
import { ControlCalculate } from 'prgm/scorecard/interfaces'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

interface UseControlCalculate {
  columnsLeft: ColumnDef<any, any>[]
  rows: Record<string, string>[]
  columnsRight: ColumnDef<any, any>[]
  setControlCalculate: (rows: ControlCalculate[]) => void
  clear: () => void
}

export const useControlCalculate = create<UseControlCalculate>()(
  immer((set) => ({
    columnsLeft: [],
    columnsRight: [],
    rows: [],
    setControlCalculate: (rows) =>
      set(() => {
        const dateKeys = rows.map((row) => row.dateTitle)
        const rowsKeys = Object.keys(rows[0]).filter((key) => key !== 'dateTitle')
        const newRows = rowsKeys.map((rowKey) => ({
          name: scenarioKeyToTitle[rowKey],
          ...dateKeys.reduce(
            (acc, key, index) => ({
              ...acc,
              [key]: (rows[index] as any)[rowKey],
            }),
            {},
          ),
        }))

        const newColumns = dateKeys.map((key) => ({
          id: key,
          accessorKey: key,
          header: key,
          cell: DefaultNumberToFixedCell,
        })) as ColumnDef<any, any>[]

        return {
          rows: newRows,
          columnsLeft: [
            {
              id: 'name',
              accessorKey: 'name',
              header: 'Name',
            },
          ],
          columnsRight: newColumns,
        }
      }),
    clear: () =>
      set({
        columnsLeft: [],
        columnsRight: [],
        rows: [],
      }),
  })),
)
