import { FC, PropsWithChildren, useContext } from 'react'

import { AppContext } from 'packages/app/providers'
import { Loader, LoaderTypes } from 'packages/ui'

import classes from './AuthLayout.module.scss'

export const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  const { global, modules } = useContext(AppContext)

  if (modules.profile) {
    return <Loader className="bg-white h100vh" type={LoaderTypes.SpinnerCenter} />
  }

  return (
    <section className={classes.wrap} data-container="true">
      <img alt="Logo" className={classes.logo} src={global.LOGO} />
      {children}
    </section>
  )
}
