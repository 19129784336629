import { Stream } from 'packages/app'

export enum Streams {
  Jume = 'jume',
  Opti = 'opti',
  Demand = 'demand',
  Deploy = 'deploy',
  Ppds = 'ppds',
  Prgm = 'prgm',
}

export const isJume = (stream: Stream | null) => stream?.name === Streams.Jume
export const isOpti = (stream: Stream | null) => stream?.name === Streams.Opti
export const isDemand = (stream: Stream | null) => stream?.name === Streams.Demand
export const isPrgm = (stream: Stream | null) => stream?.name === Streams.Prgm
export const isDeploy = (stream: Stream | null) => stream?.name === Streams.Deploy
export const isPpds = (stream: Stream | null) => stream?.name === Streams.Ppds
