import { FC } from 'react'

import { t } from 'packages/localization'
import RcPagination from 'rc-pagination'

interface PaginationProps {
  current: number
  total: number
  pageSize?: number
  onChange?: (current: number) => void
}

export const Pagination: FC<PaginationProps> = (props) => (
  <RcPagination
    {...props}
    hideOnSinglePage
    jumpNextIcon="..."
    jumpPrevIcon="..."
    nextIcon={t('next')}
    prevIcon={t('prev')}
    showSizeChanger={false}
    showTitle={false}
  />
)
