import { devtools } from 'packages/core'
import { create } from 'zustand'
import { persist, subscribeWithSelector } from 'zustand/middleware'

export interface UseOpenMenu {
  open: boolean
  set: (open: boolean) => void
  toggle: () => void
  clear: () => void
}

export const useOpenMenu = create<UseOpenMenu>()(
  subscribeWithSelector(
    devtools(
      persist(
        (set, get) => ({
          open: true,
          set: (open) => set({ open }),
          toggle: () => set({ open: !get().open }),
          clear: () => set({ open: true }),
        }),
        {
          name: 'openMenu',
        },
      ),
      {
        store: 'openMenu',
      },
    ),
  ),
)
