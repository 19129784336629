import { useRef } from 'react'

import { CellContext } from '@tanstack/table-core'
import { useHover } from 'ahooks'
import { TRow } from 'interfaces/table.interfaces'
import { Truncate } from 'packages/ui/Truncate'

import classes from './DefaultTableCell.module.scss'

interface Props {
  light?: boolean
}

export const DefaultTableCell = <TData extends TRow>({
  getValue,
  getCellProps,
}: CellContext<TData, string | number | null> & Props) => {
  const value = getValue()
  const cellProps = getCellProps()
  const cellRef = useRef(null)
  const isHover = useHover(cellRef)

  if (cellProps.dashIfEmpty && (typeof value === 'undefined' || value === null)) {
    return <span className={classes.empty}>–</span>
  }

  const content = (
    <>
      {cellProps.beforeContent}
      {value?.toString()}
      {cellProps.afterContent}
    </>
  )

  return (
    <div className={classes.defaultCell} ref={cellRef}>
      {isHover ? (
        <Truncate enabled={cellProps.ellipsis}>{content}</Truncate>
      ) : (
        <div className={classes.text}>{content}</div>
      )}
    </div>
  )
}
