import { Streams } from 'constants/streams'
import { axios, GetArrayResponse, GetOneResponse } from 'packages/core'
import { deleteEmpty } from 'packages/helper'

import { scorecardEndpoints } from './endpoints'
import {
  CalculateScenarioOptions,
  ControlYearOption,
  Scorecard,
  ScorecardCalculate,
  TargetYearOption,
} from './interfaces'

const getScenarios = async (): GetOneResponse<Scorecard> => {
  const { data } = await axios.request({
    method: 'GET',
    ...scorecardEndpoints.scenarios(),
    tracing: {
      stream: Streams.Prgm,
      module: 'scorecard',
      methodName: 'getScenarios',
    },
  })
  return data
}

const calculateScenarios = async ({
  scenarios,
  filters,
  differenceType,
  controlYear,
  targetYear,
  timeGranularity,
  periodEnd,
  periodStart,
}: CalculateScenarioOptions): GetOneResponse<ScorecardCalculate> => {
  const { data } = await axios.request({
    method: 'POST',
    ...scorecardEndpoints.calculate(),
    data: deleteEmpty({
      scenarios,
      filters,
      differenceType,
      controlYear,
      targetYear,
      timeGranularity,
      periodEnd,
      periodStart,
    }),
    tracing: {
      stream: Streams.Prgm,
      module: 'scorecard',
      methodName: 'calculateScenarios',
    },
  })
  return data
}

const getControlYearOptions = async (): GetArrayResponse<ControlYearOption> => {
  const { data } = await axios.request({
    method: 'GET',
    ...scorecardEndpoints.controlYear(),
    tracing: {
      stream: Streams.Prgm,
      module: 'scorecard',
      methodName: 'getControlYearOptions',
    },
  })
  return data
}

const getTargetYearOptions = async (): GetArrayResponse<TargetYearOption> => {
  const { data } = await axios.request({
    method: 'GET',
    ...scorecardEndpoints.targetYear(),
    tracing: {
      stream: Streams.Prgm,
      module: 'scorecard',
      methodName: 'getTargetYearOptions',
    },
  })
  return data
}

export const scorecardApi = {
  getScenarios,
  calculateScenarios,
  getTargetYearOptions,
  getControlYearOptions,
}
