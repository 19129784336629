import { ModuleConnector } from 'packages/app'
import { t } from 'packages/localization'

export const deployTransportOptimizationModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    transportOptimization: {
      label: () => t('deploy.navMenu.deployment.optimization'),
      href: '/deploy/transport-optimization',
    },
  },
})
