import { Streams } from 'constants/streams'
import { ModuleConnector } from 'packages/app'
import { t } from 'packages/localization'

export const prgmScorecardPromoModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    scorecard: {
      label: () => t('prgm.navMenu.scorecard'),
      href: `/${Streams.Prgm}/scorecard`,
      dependence: (appContext) =>
        appContext.modules.permissions?.readScorecardDashboard ||
        appContext.modules.permissions?.editScorecardDashboard,
    },
  },
})
