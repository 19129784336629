import { useCallback, useContext, useEffect, useState } from 'react'

import { removeElementFromArray } from 'packages/helper'
import { ExcelTableContext } from 'packages/ui/ExcelTable/context/ExcelTableContext'

export const useCellsSliceX = (paginateAxis: 'x' | 'y', page: number) => {
  const { tableState } = useContext(ExcelTableContext)
  const pageX = paginateAxis === 'y' ? 1 : page
  const [sliceX, setSliceX] = useState<[number, number]>(tableState.currentSliceX[pageX] || [-1, -1])

  const onChangeSliceX = useCallback((newSliceX: [number, number]) => {
    requestAnimationFrame(() => setSliceX(newSliceX))
  }, [])

  useEffect(() => {
    if (!tableState.onChangeSliceX[pageX]) {
      tableState.onChangeSliceX[pageX] = []
    }
    onChangeSliceX(tableState.currentSliceX[pageX] || [-1, -1])
    tableState.onChangeSliceX[pageX].push(onChangeSliceX)
    return () => removeElementFromArray(tableState.onChangeSliceX[pageX], onChangeSliceX)
  }, [onChangeSliceX])

  return { sliceX }
}
