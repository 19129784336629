import { SubscribeColumnCell } from 'interfaces/excelTable.interfaces'
import { deleteUndefinedInArray } from 'packages/helper'

export const addDependenciesChangeColumns = (
  headerCells: (SubscribeColumnCell | null)[][],
  setDependenciesChange: (dependenciesChange: Map<string, Set<string>>) => void,
  setDependenciesMinWidth: (notDependenceMinWidth: Map<string, Set<string>>) => void,
) => {
  const dependenciesChange = new Map()
  const notDependenceMinWidth = new Map()

  headerCells.forEach((item, index) => {
    item.forEach((columnCell, indexColumn) => {
      const nextKeys = deleteUndefinedInArray(
        item?.slice(indexColumn + 1, item.length).map((column, indexSlice) => {
          if (column?.key) {
            return column.key
          }
          let prevIndex = 1
          while (headerCells[index - prevIndex][indexSlice + 1] === null) {
            prevIndex++
          }
          const prevColumn = headerCells[index - prevIndex][indexSlice + 1]
          if (prevColumn) {
            return prevColumn.key
          }
        }),
      )

      if (columnCell) {
        if (!headerCells[index + 1] || headerCells[index + 1][indexColumn] !== null) {
          if (dependenciesChange.has(columnCell.key)) {
            nextKeys.forEach((nextKey) => dependenciesChange.get(columnCell.key)?.add(nextKey))
          } else {
            dependenciesChange.set(columnCell.key, new Set(nextKeys))
          }
        }
        nextKeys.forEach((nextKey) => {
          if (dependenciesChange.has(nextKey)) {
            dependenciesChange.get(nextKey)?.add(columnCell.key)
          } else {
            dependenciesChange.set(nextKey, new Set([columnCell.key]))
          }
        })
      } else {
        for (let currentIndex = indexColumn; currentIndex >= 0; currentIndex--) {
          let prevIndex = 1
          while (headerCells[index - prevIndex][currentIndex] === null) {
            prevIndex++
          }
          const prevColumn = headerCells[index - prevIndex][currentIndex]

          if (index === 0 || headerCells[index + 1]?.[indexColumn] === null) {
            if (prevColumn) {
              nextKeys.forEach((nextKey) => {
                if (dependenciesChange.has(nextKey)) {
                  dependenciesChange.get(nextKey)?.add(prevColumn.key)
                } else {
                  dependenciesChange.set(nextKey, new Set([prevColumn.key]))
                }
              })
            }
            return
          }

          if (prevColumn) {
            nextKeys.forEach((nextKey) => {
              if (currentIndex === indexColumn) {
                if (dependenciesChange.has(prevColumn.key)) {
                  dependenciesChange.get(prevColumn.key)?.add(nextKey)
                } else {
                  dependenciesChange.set(prevColumn.key, new Set([nextKey]))
                }

                if (notDependenceMinWidth.has(prevColumn.key)) {
                  notDependenceMinWidth.get(prevColumn.key)?.add(nextKey)
                } else {
                  notDependenceMinWidth.set(prevColumn.key, new Set([nextKey]))
                }

                if (notDependenceMinWidth.has(nextKey)) {
                  notDependenceMinWidth.get(nextKey)?.add(prevColumn.key)
                } else {
                  notDependenceMinWidth.set(nextKey, new Set([prevColumn.key]))
                }
              }

              if (dependenciesChange.has(nextKey)) {
                dependenciesChange.get(nextKey)?.add(prevColumn.key)
              } else {
                dependenciesChange.set(nextKey, new Set([prevColumn.key]))
              }
            })
          }
        }
      }
    })
  })

  setDependenciesChange(dependenciesChange)
  setDependenciesMinWidth(notDependenceMinWidth)
}
