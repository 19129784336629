import { DraggableParams } from 'packages/ui/Draggable/types'

import { getItems } from './getItems'
import { initPositions } from './initPositions'

export const recalculatePositions = (
  params: DraggableParams,
  indexDataset: string | undefined,
  elementPosition: { elementX: number; elementY: number; startPosition: number } | null,
  event: MouseEvent,
) => {
  if (!params.refDraggableItem.current || !params.refList.current || !params.refDraggableContainer.current) {
    return
  }
  const items = Array.from(params.refList.current?.querySelectorAll<HTMLElement>('[data-index]') || [])
  const index = items.findIndex((item) => (item as HTMLDivElement).dataset.index === indexDataset)
  if (index < 0) {
    return
  }
  params.refInitialIndex.current = index
  params.refDraggableItem.current = items[index]

  initPositions(params, getItems(params), elementPosition, event, true)
}
