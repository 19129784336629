import { loginBroadcastChannel } from 'packages/core/auth/login/loginBroadcastChannel'
import { TokensDefined } from 'packages/core/auth/tokens'

export const broadcastLogin = (tokens: TokensDefined, remember: boolean) => {
  loginBroadcastChannel.postMessage({
    type: 'login',
    tokens,
    remember,
  })
}
