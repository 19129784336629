import { FC } from 'react'

import cx from 'clsx'
import { t } from 'packages/localization'
import { Button, ButtonColors, ButtonSizes } from 'packages/ui/Button'

import classes from './ConfirmButtons.module.scss'

interface Props {
  className?: string
  buttonClassName?: string
  danger?: boolean
  cancelText?: string
  okText?: string
  onCancel?: () => void
  onOk?: () => void
  disabledOk?: boolean
  disabledCancel?: boolean
  isLoading?: boolean
}

export const ConfirmButtons: FC<Props> = ({
  danger,
  buttonClassName,
  cancelText = t('cancel'),
  okText = 'OK',
  onCancel,
  onOk,
  className,
  disabledOk,
  disabledCancel,
  isLoading,
}) => (
  <div className={cx(classes.root, className)}>
    <Button
      className={buttonClassName}
      color={ButtonColors.Border}
      disabled={disabledCancel}
      fullWidth
      onClick={onCancel}
      size={ButtonSizes.Medium}
    >
      {cancelText}
    </Button>

    <Button
      className={buttonClassName}
      color={danger ? ButtonColors.Danger : ButtonColors.Primary}
      disabled={disabledOk}
      fullWidth
      loading={isLoading}
      onClick={onOk}
      size={ButtonSizes.Medium}
    >
      {okText}
    </Button>
  </div>
)
