import { MutableRefObject } from 'react'

import { useGetState } from 'ahooks'
import { useResizeObserver } from 'hooks/useResizeObserver'

export const useCellOverflow = (ref: MutableRefObject<HTMLElement | null>, initIsOverflowed?: boolean) => {
  const [isOverflowed, setIsOverflowed, getIsOverflowed] = useGetState(initIsOverflowed ?? false)

  useResizeObserver({
    ref,
    callback: () => {
      const cellContent = ref.current?.querySelector('[data-value-hidden]')
      if (cellContent) {
        const currentIsOverflowed = cellContent.scrollWidth > cellContent.clientWidth
        if (currentIsOverflowed !== getIsOverflowed()) {
          setIsOverflowed(currentIsOverflowed)
        }
      }
    },
  })

  return { isOverflowed }
}
