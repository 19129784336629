import { SubscribeColumnCell, TableState } from 'interfaces/excelTable.interfaces'

import { rotateAndReverseMatrix } from './rotateAndReverseMatrix'

export const initWidths = (
  headerCells: (SubscribeColumnCell | null)[][],
  setWidths: (widths: Record<string, number> | null) => void,
  setMinimalWidths: (minimalWidths: Record<string, number> | null) => void,
  minWidthColumn: number,
  getDependencies: () => Map<string, Set<string>> | null,
  tableState: TableState,
  pageX: number,
  perPage: number,
) => {
  const dependencies = getDependencies()
  const widths: Record<string, number> = {}
  const minimalWidths: Record<string, number> = {}

  const getWidth = (key: string, index?: number): number => {
    let width = 0
    const set = dependencies?.get(key)
    if (set) {
      set.forEach((depKey) => (width += getWidth(depKey)))
    } else {
      width =
        (index !== undefined && tableState.storageWidths?.getWidth(index)) ||
        widths?.[key] ||
        tableState.defaultWidthColumn
    }
    return width
  }

  rotateAndReverseMatrix(headerCells).forEach((column, indexColumn) =>
    column.forEach((item, index) => {
      if (!item) {
        return
      }
      const initWidth = getWidth(item.key, indexColumn === 0 ? index + (pageX - 1) * perPage : undefined)
      const initMinWidth =
        indexColumn === 0 ? minWidthColumn : initWidth - (tableState.defaultWidthColumn - minWidthColumn)
      widths[item.key] = initWidth
      minimalWidths[item.key] = initMinWidth
      item.item.initWidth = initWidth
      item.item.initMinWidth = initMinWidth

      if (!tableState.columns[item.key]) {
        tableState.columns[item.key] = {} as any
      }
      tableState.columns[item.key].initWidth = initWidth
    }),
  )

  setWidths(widths)
  setMinimalWidths(minimalWidths)
}
