import { AppModulesState } from 'packages/app/modules/modulesState'
import { GlobalContextProps, GlobalModulesState } from 'packages/core'

export const isValidStreamForCurrentUser = <ModulesState extends GlobalModulesState = AppModulesState>(
  appState: Partial<GlobalContextProps<ModulesState>>,
  streamName: string,
): boolean =>
  Boolean(
    streamName &&
      appState.modules?.streams &&
      Object.values(appState.modules.streams)
        .map((stream) => stream.name)
        .includes(streamName) &&
      (appState.modules as unknown as AppModulesState).profile?.streams.find(
        (stream) => stream.title.toLowerCase() === streamName,
      ),
  )
