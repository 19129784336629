import { MouseEvent as ReactMouseEvent, RefObject, useCallback, useEffect, useState } from 'react'

import { useClickAway, useThrottleFn } from 'ahooks'
import { calcGridPos } from 'packages/ui/Table/helpers/calcGridPos'
import { Frame, Vector2 } from 'packages/ui/Table/interfaces'

import { useCopyPaste } from './useCopyPaste'

const initFrame = { startRowIdx: 0, startColumnIdx: 0, endRowIdx: 0, endColumnIdx: 0 }

export const useTableSelection = (
  ref: RefObject<HTMLTableElement>,
  copyPaste?: boolean,
  selectionAreaRef?: RefObject<HTMLDivElement>,
) => {
  const [active, setActive] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [frame, setFrame] = useState<Frame>(initFrame)
  const [mousePosition, setMousePosition] = useState<Vector2 | null>()

  useCopyPaste(active, frame, ref, copyPaste)

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      const { rowIdx: endRowIdx, columnIdx: endColumnIdx, detected } = calcGridPos(event.target as HTMLElement)
      if (detected && (endRowIdx !== frame.endRowIdx || endColumnIdx !== frame.endColumnIdx)) {
        setFrame((prevValue) => ({ ...prevValue, endRowIdx, endColumnIdx }))
      }
    },
    [frame],
  )

  const { run: onMouseMoveThrottled } = useThrottleFn(onMouseMove, { wait: 50 })

  const unsubscribeMouseMove = () => {
    ref?.current?.removeEventListener('mousemove', onMouseMoveThrottled)
  }

  useClickAway(() => {
    if (active) {
      unsubscribeMouseMove()
      setActive(false)
      setInProgress(false)
      setMousePosition(null)
      setFrame(initFrame)
    }
  }, [ref, selectionAreaRef])

  const onMouseDown = useCallback(
    (event: ReactMouseEvent) => {
      if (!copyPaste) {
        return
      }
      const { rowIdx: startRowIdx, columnIdx: startColumnIdx, detected } = calcGridPos(event.target as HTMLElement)
      if (detected) {
        setFrame((prevValue) =>
          active && event.shiftKey
            ? {
                ...prevValue,
                endRowIdx: startRowIdx,
                endColumnIdx: startColumnIdx,
              }
            : { startRowIdx, startColumnIdx, endRowIdx: startRowIdx, endColumnIdx: startColumnIdx },
        )
        setActive(true)
        setInProgress(true)
        setMousePosition({ x: event.clientX, y: event.clientY })
        ref?.current?.addEventListener('mousemove', onMouseMoveThrottled)
      }
    },
    [onMouseMoveThrottled, ref?.current, active],
  )

  const onMouseUp = useCallback(
    (event: ReactMouseEvent) => {
      if (!copyPaste || !inProgress) {
        return
      }
      unsubscribeMouseMove()
      setInProgress(false)
      const { rowIdx: endRowIdx, columnIdx: endColumnIdx, detected } = calcGridPos(event.target as HTMLElement)
      const clickOnColumn = mousePosition?.x === event.clientX && mousePosition?.y === event.clientY && endRowIdx === 0
      const { rows } = ref?.current ?? {}
      const tableRows = (rows?.length || 0) - 1
      if (detected) {
        setFrame((prevValue) =>
          clickOnColumn && prevValue.startRowIdx === 0
            ? { ...prevValue, endRowIdx: tableRows, endColumnIdx }
            : { ...prevValue, endRowIdx, endColumnIdx },
        )
      }
    },
    [onMouseMoveThrottled, ref?.current, mousePosition, inProgress],
  )

  const onMouseDownArea = useCallback(
    (event: MouseEvent) => {
      if (!ref.current) {
        return
      }
      const mousePosY = event.clientY
      const rows = ref.current.rows

      let closestRowIdx = -1
      for (let idx = 0; idx < rows.length - 1; idx++) {
        const row = rows[idx]
        const rowRect = row.getBoundingClientRect()
        if (mousePosY >= rowRect.top && mousePosY <= rowRect.bottom) {
          closestRowIdx = idx
          break
        }
      }

      if (closestRowIdx > -1) {
        setFrame((prevValue) =>
          active && event.shiftKey
            ? {
                startRowIdx: prevValue.startRowIdx,
                endRowIdx: closestRowIdx,
                startColumnIdx: 0,
                endColumnIdx: rows[0].cells.length - 1,
              }
            : {
                startRowIdx: closestRowIdx,
                endRowIdx: closestRowIdx,
                startColumnIdx: 0,
                endColumnIdx: rows[0].cells.length - 1,
              },
        )
        setActive(true)
        setInProgress(false)
        setMousePosition(null)
      }
    },
    [ref?.current, active],
  )

  useEffect(() => {
    if (!copyPaste) {
      return
    }
    selectionAreaRef?.current?.addEventListener('mousedown', onMouseDownArea)
    return () => {
      selectionAreaRef?.current?.removeEventListener('mousedown', onMouseDownArea)
    }
  }, [selectionAreaRef?.current, copyPaste, onMouseDownArea])

  return { frame, active, inProgress, onTableMouseDown: onMouseDown, onTableMouseUp: onMouseUp }
}
