import { FC, ReactNode, useCallback, useRef } from 'react'

import { useAsyncEffect, useClickAway, useEventListener, useSafeState } from 'ahooks'
import cx from 'clsx'
import { Portal } from 'packages/ui/Portal'
import { useIsMounted } from 'usehooks-ts'
import { isClosestElement } from 'utils/isClosestElement'
import { renderElement } from 'utils/renderElement'

import classes from './ContextMenu.module.scss'

const INDENT = 10

interface Props {
  open?: boolean
  children?: ReactNode
  top?: number
  left?: number
  onClose?: () => void
}

const ContextMenuInternal: FC<Props> = ({ open, children, top = 0, left = 0, onClose }) => {
  const [isOpened, setIsOpened] = useSafeState<boolean>(false)
  const [isRenderForPosition, setIsRenderForPosition] = useSafeState(false)
  const [position, setPosition] = useSafeState({ top: 0, left: 0 })

  const refContextMenu = useRef<HTMLDivElement>(null)

  const openDropdown = useCallback(async () => {
    setIsRenderForPosition(true)
    await renderElement(refContextMenu)
    const rect = refContextMenu.current?.getBoundingClientRect() || {
      height: 0,
      width: 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    }
    const height = rect.height
    const width = rect.width

    let positionTop = top
    let positionLeft = left

    const rightExtra = window.innerWidth - left - width - INDENT
    if (rightExtra < 0) {
      positionLeft += rightExtra
    }
    const bottomExtra = window.innerHeight - top - height - INDENT
    if (bottomExtra < 0) {
      positionTop += bottomExtra
    }

    setPosition({
      top: positionTop,
      left: positionLeft,
    })
    setIsRenderForPosition(false)
  }, [])

  const isMounted = useIsMounted()

  const close = useCallback(() => {
    setIsOpened(false)
    setTimeout(() => {
      if (isMounted()) {
        onClose?.()
      }
    }, 100)
  }, [onClose])

  useClickAway(close, refContextMenu, ['mousedown', 'click'])

  useEventListener('wheel', (event) => {
    if (
      refContextMenu.current &&
      !isClosestElement(event.target as HTMLElement, refContextMenu.current as HTMLElement)
    ) {
      close()
    }
  })

  useEventListener('closeContextMenu' as any, close)

  useAsyncEffect(async () => {
    if (open) {
      await openDropdown()
      setTimeout(() => {
        setIsOpened(true)
      })
    }
  }, [open, top, left])

  if (!open) {
    return null
  }

  return (
    <Portal disableRender={children === null}>
      <div
        className={cx(classes.menu, 'dropdown', {
          open: isOpened,
          [classes.isRenderForPosition]: isRenderForPosition,
        })}
        ref={refContextMenu}
        style={isRenderForPosition ? { top, left } : position}
      >
        <div className={cx('scroll', 'scroll-mini')}>{children}</div>
      </div>
    </Portal>
  )
}

export const ContextMenu: FC<Props> = ({ open, children, top, left, onClose }) => (
  <ContextMenuInternal children={children} key={`${top}-${left}`} left={left} onClose={onClose} open={open} top={top} />
)
