import { GlobalContextProps } from 'packages/core/config'

import { MenuItem } from './types'

export class MenuBuilder {
  private menu: MenuItem[] = []
  private appContext: GlobalContextProps | null = null

  public init(appContext: GlobalContextProps) {
    this.menu = []
    this.appContext = appContext
    return this
  }

  public addMenu(fnMenu?: (builder: this) => void) {
    fnMenu?.(this)
    return this
  }

  public addItem(item: MenuItem<any>) {
    if (item && this.appContext && (item.dependence === undefined || item.dependence(this.appContext))) {
      this.menu.push(item)
    }
    return this
  }

  public getMenu() {
    return this.menu
  }
}
