import { Centrifuge, UnauthorizedError } from 'centrifuge'
import { axios } from 'packages/core/apiConfig'
import { RefreshException, refreshTokens, tokensStorage } from 'packages/core/auth'

class WS {
  private instance?: Centrifuge

  init(wsUrl?: string) {
    if (!wsUrl) {
      return
    }
    const token = tokensStorage.get()?.accessToken
    if (token) {
      this.instance = new Centrifuge(wsUrl, {
        token,
        getToken: () =>
          refreshTokens(axios)
            .then(({ tokens }) => tokens.accessToken)
            .catch((error) => {
              if (error instanceof RefreshException) {
                tokensStorage.clear()
              }
              throw new UnauthorizedError('incorrect token')
            }),
      })
    }
  }

  getInstance() {
    return this.instance
  }
}

export const ws = new WS()
