import { Streams } from 'constants/streams'
import { ModuleConnector } from 'packages/app'
import { t } from 'packages/localization'

export const demandRealignmentModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    realignment: {
      label: () => t('menu.realignment'),
      href: `/${Streams.Demand}/realignment`,
      dependence: (appContext) => appContext.modules.permissions?.viewRealignmentDashboard,
    },
  },
})
