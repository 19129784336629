import { devtools } from 'packages/core'
import { create } from 'zustand'

import { UseICErrors } from './types'

export const useICErrors = create<UseICErrors>()(
  devtools(
    (set) => ({
      errors: [],
      setErrors: (errors) => set({ errors }),
      removeError: (id, key) =>
        set(({ errors }) => ({ errors: errors.filter((item) => item.id !== id || item.key !== key) })),
      removeById: (id) => set(({ errors }) => ({ errors: errors.filter((item) => item.id !== id) })),
      removeHierarchyError: (id, hierarchyId) => {
        if (!hierarchyId) {
          return
        }
        set(({ errors }) => ({
          errors: errors.filter(
            (item) => item.id !== id || item.key !== 'hierarchies' || item.hierarchyId !== hierarchyId,
          ),
        }))
      },
    }),
    {
      store: 'ICErrors',
    },
  ),
)
