import { useContext } from 'react'

import { useTitle as useTitleBase } from 'ahooks'
import { AppContext } from 'packages/app/index'
import { t } from 'packages/localization'

export const useTitle = (key: Parameters<typeof t>[0], params?: Record<string, string | number>, title?: string) => {
  const { global } = useContext(AppContext)
  useTitleBase(title ?? t(key, { appName: global.APP_NAME || 'App Name', ...params }))
}
