import { forwardRef, ReactNode } from 'react'

import InfoIcon from 'assets/images/info.svg?react'
import cx from 'clsx'
import { useModal } from 'hooks/useModal'
import { TextFieldColors } from 'interfaces/components.interfaces'
import { capitalize } from 'lodash'
import { t } from 'packages/localization'
import { TextModal } from 'packages/ui/modals/TextModal'
import { Skeleton, SkeletonSizes } from 'packages/ui/Skeleton'
import { Tag, TagColors, TagSizes } from 'packages/ui/Tag'

import classes from './Indicator.module.scss'

export enum IndicatorSizes {
  Default = 'defaultSize',
  Small = 'small',
}

interface IndicatorProps {
  className?: string
  classNameIndicator?: string
  value?: ReactNode
  color?: TextFieldColors
  loading?: boolean
  error?: ReactNode
  label?: ReactNode
  labelString?: string
  labelMini?: ReactNode
  isBold?: boolean
  size?: IndicatorSizes
  children?: ReactNode
  mini?: boolean
}

export const Indicator = forwardRef<HTMLDivElement, IndicatorProps>(
  (
    {
      className,
      classNameIndicator,
      value,
      color = TextFieldColors.Default,
      loading,
      error,
      isBold,
      label,
      labelString,
      labelMini,
      size = IndicatorSizes.Default,
      children,
      mini,
    },
    ref,
  ) => {
    const [openError, onOpenError, onCloseError] = useModal()

    return (
      <div className={cx(classes.wrap, className, classes[color], classes[size], { [classes.mini]: mini })} ref={ref}>
        <div className={cx(classes.indicator, classNameIndicator)}>
          {!((loading || error) && size === IndicatorSizes.Small) && label && (
            <div className={cx(classes.label, classes[color], { [classes.bold]: isBold })}>
              {mini ? labelMini ?? label : label}
            </div>
          )}
          <div className={classes.num}>
            {loading && (
              <Skeleton
                className={cx({ [classes.skeleton]: size !== IndicatorSizes.Small })}
                size={size === IndicatorSizes.Small ? SkeletonSizes.Small : SkeletonSizes.Large}
                width={size === IndicatorSizes.Small ? 90 : 70}
              />
            )}
            {!loading && !error && value !== 0 && value}
            {!loading && !error && !value && (
              <span className={classes.noData}>{size === IndicatorSizes.Small ? '—' : t('noData')}</span>
            )}
            {!loading && !!error && (
              <>
                <Tag
                  className={classes.error}
                  color={TagColors.Danger}
                  isClickable
                  onClick={onOpenError}
                  size={TagSizes.Small}
                  startIcon={size !== IndicatorSizes.Small && <InfoIcon />}
                >
                  {t('errorCalculation')}
                </Tag>
                <TextModal
                  isOpened={openError}
                  onClose={onCloseError}
                  title={`${capitalize(t('errorCalculation'))} ${labelString ?? label}`}
                >
                  {error}
                </TextModal>
              </>
            )}
          </div>
        </div>
        {!mini && children && <div className={classes.children}>{children}</div>}
      </div>
    )
  },
)
