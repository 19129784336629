import { AuthPipeFn } from 'packages/core/auth/controlAuth/authBuilder'

import { requestTokensFromBroadcastChannel } from './getTokensFromBroadcastChannnel'
import { getTokensFromStorage } from './getTokensFromStorage'

export const defineTokens: AuthPipeFn = async (builder) => {
  await builder.apply(getTokensFromStorage)
  if (!builder.isAuthenticated) {
    await builder.apply(requestTokensFromBroadcastChannel)
  }
}
