import { SubscribeColumnCell } from 'interfaces/excelTable.interfaces'

export const addDependenciesColumns = (
  headerCells: (SubscribeColumnCell | null)[][],
  setDependencies: (dependencies: Map<string, Set<string>>) => void,
) => {
  const dependencies = new Map()

  headerCells.forEach((item, index) => {
    item.forEach((columnCell, indexColumn) => {
      if (columnCell && item[indexColumn + 1]) {
        const depCell = item[indexColumn + 1]
        const dependentKeys = depCell?.key ? [depCell.key] : []
        let nextIndex = 1
        while (headerCells[index + nextIndex]?.[indexColumn] === null) {
          nextIndex++
          const nextCell = headerCells[index + nextIndex - 1]?.[indexColumn + 1]
          if (nextCell?.key) {
            dependentKeys.push(nextCell.key)
          }
        }
        dependentKeys.forEach((dependentKey) => {
          if (dependencies.has(columnCell.key)) {
            dependencies.get(columnCell.key)?.add(dependentKey)
          } else {
            dependencies.set(columnCell.key, new Set([dependentKey]))
          }
        })
      }
    })
  })

  setDependencies(dependencies)
}
