import { decode } from 'js-base64'

export const getUserIdFormJWT = (token: string) => {
  const jwtString = decode(token)

  const regex = /"user_id":(\d+)/
  const match = jwtString.match(regex)

  if (match && match[1]) {
    return parseInt(match[1])
  }
}
