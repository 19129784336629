import { tokensBroadcastChannel, tokensBroadcastChannelListeners, tokensStorage } from 'packages/core/auth'

export const subscribeRequestTokens = () => {
  tokensBroadcastChannelListeners.push((event: MessageEvent) => {
    if (event.data.type === 'tokens_request') {
      const tokens = tokensStorage.get()
      if (tokens.accessToken && tokens.refreshToken) {
        tokensBroadcastChannel.postMessage({
          type: 'tokens_receive',
          tokens,
        })
      }
    }
  })
}
