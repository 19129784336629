import { FC } from 'react'

import { useMatchRoute, useRouterState } from '@tanstack/react-router'
import { IS_DEV } from 'constants/env'
import { useCurrentRecommendations } from 'demand/recommendations/store'
import { t, TaggedText } from 'packages/localization'
import { Switch, SwitchColors, SwitchVariants } from 'packages/ui'

export const RecommendationsSwitch: FC = () => {
  const { open, setOpen } = useCurrentRecommendations()
  const matchRoute = useMatchRoute()
  useRouterState()
  const isSelectionRoute = !!matchRoute({ to: '/demand/selections/$selectionId/forecast' })

  if (!IS_DEV || !isSelectionRoute) {
    return null
  }

  return (
    <Switch
      color={SwitchColors.Primary}
      label={
        <TaggedText
          tags={{
            1: (text) => <span className="bold">{text}</span>,
          }}
          text={t('demand.recommendations.label')}
        />
      }
      onChange={() => setOpen(!open)}
      value={open}
      variant={SwitchVariants.Gradient}
    />
  )
}
