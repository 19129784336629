import { useEffect, useMemo, useState } from 'react'

import { GlobalContextProps } from 'packages/core/config'
import { getLang, localizationState } from 'packages/localization/localizationState'

import { Localization } from './i18n'

export const useLangInitiated = (localization: Localization): Partial<GlobalContextProps> => {
  const [langInitiated, setLangInitiated] = useState(false)
  useMemo(() => localizationState.setLocalization(localization), [])

  useEffect(() => {
    localization.languagesLoaders[getLang()]().then(() => setLangInitiated(true))
  }, [])

  return { langInitiated }
}
