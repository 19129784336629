import { PromoScenarioTabsCodes } from 'opti/promoDataSources/interfaces/common'
import { devtools } from 'packages/core'
import { create } from 'zustand'

import { UseCurrentPromoScenarioTab } from './types'

export const useCurrentPromoScenarioTab = create<UseCurrentPromoScenarioTab>()(
  devtools(
    (set) => ({
      currentTab: PromoScenarioTabsCodes.Input,
      setCurrentTab: (currentTab) => set({ currentTab }),
    }),
    {
      store: 'currentPromoScenarioTab',
    },
  ),
)
