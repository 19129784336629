import { CfrScenariosQueries } from 'opti/cfrScenario/queries/types'
import { useCfrScenariosParams } from 'opti/cfrScenario/store'
import { PromoScenariosQueries } from 'opti/promoScenario/queries/types'
import { usePromoScenariosParams } from 'opti/promoScenario/store'
import { PromoScenarioOutputQueries } from 'opti/promoScenarioOutput/queries/types'
import { RtmScenariosQueries } from 'opti/rtmScenario/queries/types'
import { useRtmScenariosParams } from 'opti/rtmScenario/store'
import { TetrisScenariosQueries } from 'opti/tetrisScenario/queries/types'
import { useTetrisScenariosParams } from 'opti/tetrisScenario/store'
import { useQueryClient } from 'packages/react-query'

export const useClearAppCashOpti = () => {
  const queryClient = useQueryClient()

  const {
    clearFilters: clearPromoFilters,
    clearSearch: clearPromoSearch,
    clearOrdering: clearPromoOrdering,
  } = usePromoScenariosParams()
  const {
    clearFilters: clearRtmFilters,
    clearSearch: clearRtmSearch,
    clearOrdering: clearRtmOrdering,
  } = useRtmScenariosParams()
  const {
    clearFilters: clearTetrisFilters,
    clearSearch: clearTetrisSearch,
    clearOrdering: clearTetrisOrdering,
  } = useTetrisScenariosParams()
  const {
    clearFilters: clearCfrFilters,
    clearSearch: clearCfrSearch,
    clearOrdering: clearCfrOrdering,
  } = useCfrScenariosParams()

  const clearAppCashOpti = (_newProjectId?: number) => {
    queryClient.removeQueries({ queryKey: [PromoScenarioOutputQueries.PromoScenarioResult] })
    queryClient.removeQueries({ queryKey: [CfrScenariosQueries.Scenarios] })
    queryClient.removeQueries({ queryKey: [PromoScenariosQueries.PromoScenarios] })
    queryClient.removeQueries({ queryKey: [TetrisScenariosQueries.Scenarios] })
    queryClient.removeQueries({ queryKey: [RtmScenariosQueries.RtmScenarios] })

    clearPromoFilters()
    clearPromoSearch()
    clearPromoOrdering()
    clearRtmFilters()
    clearRtmSearch()
    clearRtmOrdering()

    clearTetrisFilters()
    clearTetrisSearch()
    clearTetrisOrdering()
    clearCfrFilters()
    clearCfrSearch()
    clearCfrOrdering()
  }

  return { clearAppCashOpti }
}
