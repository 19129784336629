import { devtools } from 'packages/core'
import { create } from 'zustand'

import { UseTetrisScenarioCopyingListParams } from './types'

export const useTetrisScenarioCopyingList = create<UseTetrisScenarioCopyingListParams>()(
  devtools(
    (set) => ({
      list: [],
      setList: (list) => set({ list }),
      clearList: () => set({ list: [] }),
    }),
    {
      store: 'tetrisScenarioCopyingList',
    },
  ),
)
