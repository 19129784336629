import { devtools } from 'packages/core'
import {
  BaselineAlgorithmOptionValue,
  TotalBaselineFromOptionValue,
  TotalVolumeFromOptionValue,
  VolumeAlgorithmOptionVolume,
} from 'prgm/shared/interfaces/calculateModal'
import { create } from 'zustand'

export interface CalculateParams {
  totalBaselineFrom?: TotalBaselineFromOptionValue
  baselineAlgorithm?: BaselineAlgorithmOptionValue
  totalVolumeFrom?: TotalVolumeFromOptionValue
  volumeAlgorithm?: VolumeAlgorithmOptionVolume
}

interface UseCalculateParams {
  calculateParams: CalculateParams
  calculateCompleted: boolean
  setParam: (param: keyof CalculateParams, value: CalculateParams[keyof CalculateParams]) => void
  setCalculateCompleted: (calculateCompleted: boolean) => void
  clear: () => void
}

export const useCalculateParams = create<UseCalculateParams>()(
  devtools(
    (set) => ({
      calculateParams: {
        totalVolumeFrom: 'promo_card',
      },
      calculateCompleted: true,
      setCalculateCompleted: (calculateCompleted) => set({ calculateCompleted }),
      setParam: (param, value) =>
        set(({ calculateParams }) => ({
          calculateParams: {
            ...calculateParams,
            [param]: value,
          },
        })),
      clear: () =>
        set({
          calculateParams: {
            totalVolumeFrom: 'promo_card',
          },
          calculateCompleted: true,
        }),
    }),
    {
      store: 'prgm.customerSplit.calculateParams',
    },
  ),
)
