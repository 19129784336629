import { SelectionData } from 'packages/ui/Table/interfaces'

import { getBorders } from './getBorders'

interface SelectionArgs {
  index: number
  selection?: SelectionData
  colExtra?: number
  copyPaste?: boolean
}

export const getHeaderSelection = ({ index, selection, colExtra = 0, copyPaste }: SelectionArgs) => {
  if (!selection || !copyPaste) {
    return false
  }
  const { frame, active, inProgress } = selection
  const { minRow, minColumn, maxColumn, height } = getBorders(frame)
  const showHeaderFrame = minRow === 0 && active
  const columnIdx = index + colExtra
  const inRange = columnIdx <= maxColumn && columnIdx >= minColumn
  const isSelected = showHeaderFrame && inRange
  const isStart = columnIdx === minColumn && !inProgress
  const isEnd = columnIdx === maxColumn && !inProgress
  const haveEdgeBorder = ((height === 1 && minRow === 0) || minRow === 1) && inRange && active
  return { isSelected, isStart, isEnd, inProgress, haveEdgeBorder }
}
