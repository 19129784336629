import { devtools } from 'packages/core'
import { deleteEmptyWithArrays } from 'packages/helper'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

import { UseUpdateMasterDataTable } from './types'

export const useUpdateMasterDataTable = create<UseUpdateMasterDataTable>()(
  devtools(
    subscribeWithSelector((set) => ({
      archivedIds: {},
      returnedArchivedIds: {},
      isAll: false,
      addToArchivedIds: (hierarchyId, ids) =>
        set(({ archivedIds }) => ({
          archivedIds: deleteEmptyWithArrays({
            ...archivedIds,
            [hierarchyId]: [...(archivedIds[hierarchyId] || []), ...ids],
          }),
        })),
      addToReturnedArchivedIds: (hierarchyId, ids) =>
        set(({ returnedArchivedIds }) => ({
          returnedArchivedIds: deleteEmptyWithArrays({
            ...returnedArchivedIds,
            [hierarchyId]: [...(returnedArchivedIds[hierarchyId] || []), ...ids],
          }),
        })),
      pushToArchivedIds: (hierarchyId, id) =>
        set(({ archivedIds }) => ({
          archivedIds: {
            ...archivedIds,
            [hierarchyId]: [...(archivedIds[hierarchyId] || []), id],
          },
        })),
      removeFromArchivedIds: (hierarchyId, id, replacedIds) =>
        set(({ archivedIds }) => ({
          archivedIds: deleteEmptyWithArrays({
            ...archivedIds,
            [hierarchyId]: replacedIds ?? archivedIds[hierarchyId]?.filter((item) => item !== id),
          }),
          isAll: false,
        })),
      removeFromReturnedArchivedIds: (hierarchyId, id, replacedIds) =>
        set(({ returnedArchivedIds }) => ({
          returnedArchivedIds: deleteEmptyWithArrays({
            ...returnedArchivedIds,
            [hierarchyId]: replacedIds ?? returnedArchivedIds[hierarchyId]?.filter((item) => item !== id),
          }),
          isAll: false,
        })),
      setIsAll: () => set({ isAll: true }),
      clearArchivedIds: () => set({ archivedIds: {}, isAll: false }),
      clearReturnArchivedIds: () => set({ returnedArchivedIds: {} }),
    })),
    {
      store: 'masterDataTable',
    },
  ),
)
