import { ExcelState } from 'interfaces/excelTable.interfaces'

export const isChartEnabled = (state: ExcelState['data'] | undefined) => {
  if (!state) {
    return false
  }
  const key = Number(Object.keys(state).at(-1)) || 0
  const column = state[key]?.columns[0]

  return column.length === 1 && column[0].code === 'date'
}
