import { Dispatch, SetStateAction } from 'react'

import { SELECT_ALL } from 'constants/components'
import { devtools } from 'packages/core'
import { t } from 'packages/localization'
import { CustomerSplit } from 'prgm/createPromo/api'
import {
  checkCalculateParamsChanged,
  checkHasManualBaseline,
  getRowsValues,
  getTotalBaseline,
  getTotalVolume,
} from 'prgm/customerSplit/helpers'
import { CustomerTableRow } from 'prgm/customerSplit/interfaces'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface UseCustomerTable {
  rows: CustomerTableRow[]
  total: CustomerTableRow
  initialSelectedIds: (number | string)[]
  selectedIds: (number | string)[]
  blockedIds: (number | string)[]
  totalId: number
  totalBaseline: number
  totalVolume: number
  x5Flag: boolean
  initialValues: {
    id: number
    baseline: number
    volume: number
  }[]
  open: boolean
  calculateParamsChanged: boolean
  hasManualBaseline: boolean
  resetChanges: boolean

  setResetChanges: (resetChanges: boolean) => void
  setSelectedIds: Dispatch<SetStateAction<(number | string)[]>>
  clear: () => void
  setCustomerTable: (rows: CustomerSplit[], total: CustomerTableRow, x5Flag: boolean) => void
  setBaseline: (id: number, value: number) => void
  setVod: (id: number, value: number) => void
  setOpen: (open: boolean) => void
}

export const useCustomerTable = create<UseCustomerTable>()(
  devtools(
    persist(
      (set, getState) => ({
        rows: [],
        total: {} as CustomerTableRow,
        selectedIds: [],
        blockedIds: [],
        totalId: 0,
        initialValues: [],
        x5Flag: false,
        open: true,
        initialSelectedIds: [],
        calculateParamsChanged: false,
        hasManualBaseline: false,
        totalBaseline: 0,
        totalVolume: 0,
        resetChanges: false,

        setResetChanges: (resetChanges) => set({ resetChanges }),

        setOpen: (open) => set({ open }),

        setCustomerTable: (newRows, total, x5Flag) => {
          set(() => {
            const rowsWithIds: CustomerTableRow[] = newRows
              ? newRows.map((row, index) => ({
                  ...row,
                  id: index,
                  isFooter: false,
                  baseline: Number(row.baseline.toFixed(2)),
                  volume: Number(row.volume.toFixed(2)),
                }))
              : []
            const initialValues = newRows
              ? newRows.map((row, index) => ({
                  id: index,
                  baseline: Number(row.baseline.toFixed(2)),
                  volume: Number(row.volume.toFixed(2)),
                }))
              : []
            const normalizedTotal: CustomerTableRow = total
              ? {
                  ...total,
                  baseline: Number(total.baseline.toFixed(2)),
                  volume: Number(total.volume.toFixed(2)),
                }
              : ({} as CustomerTableRow)

            if (x5Flag) {
              normalizedTotal.customerCode = t('prgm.create.customerCodeX5')
            }

            const selectedIds = rowsWithIds.filter((item) => item.isSelected).map((item) => item.id)
            const totalBaseline = getTotalBaseline(rowsWithIds, selectedIds)
            const totalVolume = getTotalVolume(rowsWithIds, selectedIds)

            return {
              rows: rowsWithIds,
              totalId: newRows?.length,
              total: {
                ...normalizedTotal,
                id: newRows?.length,
                isFooter: true,
              },
              initialValues,
              hasManualBaseline: false,
              initialSelectedIds: selectedIds,
              calculateParamsChanged: false,
              selectedIds: [],
              blockedIds: [],
              x5Flag,
              totalBaseline,
              totalVolume,
            }
          })

          const selectedIds = getState()
            .rows.filter((item) => item.isSelected)
            .map((item) => item.id)
          getState().setSelectedIds(selectedIds)
        },
        setSelectedIds: (state) => {
          set((prev) => {
            const selectedIds = typeof state === 'function' ? state(prev.selectedIds) : state
            const parentId = getState().totalId
            const x5Flag = getState().x5Flag
            const total = getState().total
            const rowsLength = getState().rows.length
            const isParentSelected = selectedIds.includes(parentId)
            prev.rows.forEach((row) => {
              row.isSelected = selectedIds.includes(row.id)
            })

            if (!x5Flag) {
              if (selectedIds.length < rowsLength) {
                total.customerCode = t('prgm.create.customerCodeSome')
              } else {
                total.customerCode = t('prgm.create.customerCodeAll')
              }
            }

            const calculateParamsChanged = checkCalculateParamsChanged({
              rowsValues: getRowsValues(prev.rows),
              selectedIds,
              initialSelectedIds: prev.initialSelectedIds,
              initialValues: prev.initialValues,
            })

            const hasManualBaseline = checkHasManualBaseline(prev.rows, selectedIds)
            const totalBaseline = getTotalBaseline(prev.rows, selectedIds)
            const totalVolume = getTotalVolume(prev.rows, selectedIds)

            if (isParentSelected) {
              return {
                selectedIds,
                blockedIds: selectedIds.length ? [SELECT_ALL] : [],
                calculateParamsChanged,
                hasManualBaseline,
                totalBaseline,
                totalVolume,
              }
            }

            return {
              selectedIds,
              blockedIds: selectedIds.length ? [parentId] : [],
              total: { ...total },
              calculateParamsChanged,
              hasManualBaseline,
              totalBaseline,
              totalVolume,
            }
          })
        },
        setBaseline: (id, value) => {
          set(({ rows, initialValues, selectedIds, initialSelectedIds }) => {
            const foundRow = rows.find((row) => row.id === id)
            const initialBaseline = initialValues.find((row) => row.id === id)?.baseline

            if (foundRow) {
              foundRow.baseline = value
              foundRow.isManualBaseline = initialBaseline !== value
            }

            const rowsValues = rows ? getRowsValues(rows) : []
            const calculateParamsChanged = checkCalculateParamsChanged({
              rowsValues,
              initialValues,
              initialSelectedIds,
              selectedIds,
            })
            const hasManualBaseline = checkHasManualBaseline(rows, selectedIds)
            const totalBaseline = getTotalBaseline(rows, selectedIds)

            return { rows, calculateParamsChanged, hasManualBaseline, totalBaseline }
          })
        },
        setVod: (id, value) => {
          set(({ rows, initialValues, selectedIds, initialSelectedIds }) => {
            const foundRow = rows.find((row) => row.id === id)
            const initialVolume = initialValues.find((row) => row.id === id)?.volume

            if (foundRow) {
              foundRow.volume = value
              foundRow.isManualVolume = initialVolume !== value
            }

            const rowsValues = rows ? getRowsValues(rows) : []

            const calculateParamsChanged = checkCalculateParamsChanged({
              rowsValues,
              initialValues,
              initialSelectedIds,
              selectedIds,
            })

            const totalVolume = getTotalVolume(rows, selectedIds)

            return { rows, calculateParamsChanged, totalVolume }
          })
        },
        clear: () =>
          set({
            selectedIds: [],
            blockedIds: [],
            totalId: 0,
            rows: [],
            total: {} as CustomerTableRow,
            initialValues: [],
            x5Flag: false,
            initialSelectedIds: [],
            calculateParamsChanged: false,
            hasManualBaseline: false,
            totalBaseline: 0,
            totalVolume: 0,
            resetChanges: false,
          }),
      }),
      { name: 'prgmCustomersTable' },
    ),
    {
      store: 'prgmCustomersTable',
    },
  ),
)
