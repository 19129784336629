import { FC, useContext, useRef } from 'react'

import cx from 'clsx'
import { SubscribeColumnCell, SubscribeFixedColumnCell } from 'interfaces/excelTable.interfaces'

import { ExcelTableContext } from './context/ExcelTableContext'
import { ExcelColumnCellWithSubscriber } from './ExcelColumnCellWithSubscriber'
import classes from './ExcelTable.module.scss'
import { useCellOverflow } from './handlers/useCellOverflow'
import { getIsSelected } from './helpers/getIsSelected'
import { getTitleFormatted } from './helpers/getTitleFormatted'

interface ExcelColumnCellProps {
  setWidth?: (width: number, keys?: string[]) => void
  disableLeftResize?: boolean
  disableRightResize?: boolean
  disableSelect?: boolean
  valueIsName?: boolean
  column: SubscribeColumnCell | SubscribeFixedColumnCell
  onResizing?: () => void
  ready?: boolean
  initWidth?: number
}

export const ExcelColumnCell: FC<ExcelColumnCellProps> = ({ column, valueIsName, ready, initWidth, ...props }) => {
  const refCell = useRef<HTMLDivElement | null>(null)
  const { tableState } = useContext(ExcelTableContext)
  const { isOverflowed } = useCellOverflow(refCell)

  const refReady = useRef(!!ready)
  if (ready) {
    refReady.current = ready
  }

  const title = valueIsName ? column.item?.name : column.item?.value

  const getTitleFormattedInner = (oversize: boolean) =>
    getTitleFormatted(title, column.item?.name, valueIsName, tableState, oversize)

  const titleFormatted = getTitleFormattedInner(false)
  const titleFormattedWithExponent = getTitleFormattedInner(isOverflowed)

  if (refReady.current) {
    return (
      <ExcelColumnCellWithSubscriber
        column={column}
        initWidth={initWidth}
        isOverflowed={isOverflowed}
        valueIsName={valueIsName}
        {...props}
      />
    )
  }

  const selectedColumns = tableState.getStateSelectedColumns?.().selectedColumns
  const { isSelected, isSelectedStart, isSelectedEnd } = getIsSelected({ selectedColumns, column })

  return (
    <div
      className={cx(classes.cell, {
        [classes.noTitleColumn]: titleFormatted === '',
        [classes.withBorderColumn]: column.item.withBorder,
        [classes.isSelected]: isSelected,
        [classes.isSelectedStart]: isSelectedStart,
        [classes.isSelectedEnd]: isSelectedEnd,
      })}
      data-not-selected="true"
      ref={refCell}
      style={{ width: initWidth || undefined }}
    >
      <span className="truncateInline">{titleFormattedWithExponent}</span>
      <span className={cx('truncateInline', classes.valueHidden)} data-value-hidden="true">
        {titleFormatted}
      </span>
    </div>
  )
}
