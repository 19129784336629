import { NoParamEndpointConstructor } from 'packages/core'
import { createEndpoint } from 'packages/core/apiConfig'

interface Endpoints {
  logout: NoParamEndpointConstructor
}

export const logoutEndpoints: Endpoints = {
  logout: () => createEndpoint('/account/termination-session'),
}
