import {
  createContext,
  useId as createId,
  useMemo as memoize,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useRef,
} from 'react'

interface SelectableParamsContextProps {
  refSelectableParams: RefObject<{
    id?: string
    x?: number
    y?: number
    observers: Record<string, (x?: number, y?: number) => void>
  }>
  useCreateSelectableParams: (enable?: boolean, observeAll?: boolean) => SelectableCellParams | undefined
}

export const SelectableParamsContext = createContext<SelectableParamsContextProps>({
  refSelectableParams: { current: { observers: {} } },
  useCreateSelectableParams: () => undefined,
})

export interface SelectableCellParams {
  set: (x?: number, y?: number) => void
  get: () => { x?: number; y?: number } | undefined
  clear: () => void
  setObserver?: (observer: (x?: number, y?: number) => void) => void
}

export const useSelectableCellProvider = () => {
  const refSelectableParams = useRef<{
    id?: string
    x?: number
    y?: number
    observers: Record<string, (x?: number, y?: number) => void>
  }>({ observers: {} })

  const useCreateSelectableParams = useCallback(
    (enable = true, observeAll?: boolean): SelectableCellParams | undefined => {
      const id = createId()
      if (!enable) {
        return
      }
      return memoize(
        () => ({
          set: (x?: number, y?: number) => {
            refSelectableParams.current.x = x
            refSelectableParams.current.y = y
            refSelectableParams.current.id = id
            for (const observerId in refSelectableParams.current.observers) {
              const observer = refSelectableParams.current.observers[observerId]
              if (observer && (observerId === id || observerId.startsWith('all_'))) {
                observer(x, y)
              }
              if (observer && observerId !== id && !observerId.startsWith('all_')) {
                observer(undefined, undefined)
              }
            }
          },
          get: () => {
            if (refSelectableParams.current.id !== id) {
              return
            }
            return {
              x: refSelectableParams.current.x,
              y: refSelectableParams.current.y,
            }
          },
          clear: () => {
            refSelectableParams.current.x = undefined
            refSelectableParams.current.y = undefined
            refSelectableParams.current.id = id
            for (const observerId in refSelectableParams.current.observers) {
              const observer = refSelectableParams.current.observers[observerId]
              observer(undefined, undefined)
            }
          },
          setObserver: (observer: (x?: number, y?: number) => void) => {
            refSelectableParams.current.observers[observeAll ? `all_${id}` : id] = observer
          },
        }),
        [],
      )
    },
    [],
  )

  return {
    SelectableCellProvider: useCallback(
      ({ children }: { children: ReactNode }) => (
        <SelectableParamsContext.Provider
          value={{
            refSelectableParams,
            useCreateSelectableParams,
          }}
        >
          {children}
        </SelectableParamsContext.Provider>
      ),
      [],
    ),
  }
}

export const useSelectableCell = (setState?: (value: { x?: number; y?: number }) => void) => {
  const { useCreateSelectableParams } = useContext(SelectableParamsContext)
  useCreateSelectableParams(!!setState, true)?.setObserver?.((x, y) => setState?.({ x, y }))
  return { useCreateSelectableParams }
}
