import { isProtectedPageWithoutMain } from 'packages/core/auth/controlAuth'
import { AuthPipeFn } from 'packages/core/auth/controlAuth/authBuilder'

export const redirectToLoginPage: AuthPipeFn = (builder) => {
  builder.locationIsCorrect = true
  builder.setAuthReady(true)
  if (isProtectedPageWithoutMain()) {
    builder.navigate({ to: `/login${location.search}` })
  }
}
