import { devtools } from 'packages/core'
import { create } from 'zustand'

import { UseCfrScenariosParams } from './types'

export const useCfrScenariosParams = create<UseCfrScenariosParams>()(
  devtools(
    (set) => ({
      filters: {},
      setFilters: (filters) => set({ filters }),
      clearFilters: () => set({ filters: {} }),
      search: '',
      setSearch: (search) => set({ search }),
      clearSearch: () => set({ search: '' }),
      ordering: null,
      setOrdering: (ordering) => set({ ordering }),
      clearOrdering: () => set({ ordering: null }),
    }),
    {
      store: 'cfrScenariosParams',
    },
  ),
)
