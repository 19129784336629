import { ExcelStorageWidths } from 'interfaces/excelTable.interfaces'

export const getIndexColumnByWidth = (
  storageWidths: ExcelStorageWidths | undefined,
  defaultWidthColumn: number,
  width: number,
) => {
  let index = 0
  let sumWidth = 0
  if (width > 0) {
    while (sumWidth <= width) {
      sumWidth += storageWidths?.getWidth(index) || defaultWidthColumn
      if (sumWidth <= width) {
        index++
      }
    }
  }
  return index
}

export const getIndexFixColumnByWidth = (
  storageWidths: ExcelStorageWidths | undefined,
  defaultWidthColumn: number,
  width: number,
) => {
  let index = 0
  let sumWidth = 0
  if (width > 0) {
    while (sumWidth <= width) {
      sumWidth += storageWidths?.getFixedWidth(index) || defaultWidthColumn
      if (sumWidth <= width) {
        index++
      }
    }
  }
  return index
}
