import { TokensDefined } from 'packages/core/auth/tokens'

import { loginBroadcastChannel } from './loginBroadcastChannel'

export const subscribeBroadcastLogin = (authenticate: (tokens: TokensDefined, remember: boolean) => void) => {
  loginBroadcastChannel.onmessage = (event: MessageEvent) => {
    if (event.data.type === 'login') {
      authenticate(event.data.tokens, event.data.remember)
    }
  }
}
