import { FC, ReactNode } from 'react'

import { ButtonEasy, ButtonEasyIcons } from 'packages/ui/ButtonEasy'
import { MarkItem, MarkItemSizes, MarkItemTypes } from 'packages/ui/MarkItem'

interface ButtonEasyErrorProps {
  className?: string
  children?: ReactNode
  onClick?: () => void
  isClickable?: boolean
}

export const ButtonEasyError: FC<ButtonEasyErrorProps> = ({ className, children, onClick, isClickable = true }) => (
  <ButtonEasy className={className} icon={ButtonEasyIcons.Help} isClickable={isClickable} onClick={onClick}>
    <MarkItem size={MarkItemSizes.Large} type={MarkItemTypes.DangerInfo}>
      {children}
    </MarkItem>
  </ButtonEasy>
)
