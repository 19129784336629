import { FC, ReactNode } from 'react'

import cx from 'clsx'

import classes from './ColoredValue.module.scss'

interface Props {
  color: string | 'green' | 'red' | 'gray'
  children: ReactNode
  className?: string
  size?: 'default' | 'small'
  'data-copy-text'?: string | number | null
}

export const ColoredValue: FC<Props> = ({ color, children, className, size = 'default', ...props }) => {
  const isHex = color.startsWith('#')

  return (
    <div className={cx(classes.root, className, classes[size])} {...props}>
      <span
        className={cx(classes.circle, { [classes[color]]: !isHex })}
        style={isHex ? { background: color } : undefined}
      />
      {children}
    </div>
  )
}
