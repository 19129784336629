import { FC, useContext, useEffect, useRef, useState } from 'react'

import { useGetState } from 'ahooks'
import cx from 'clsx'
import {
  ExcelAxisItem,
  SelectedColumns,
  SubscribeColumnCell,
  SubscribeFixedColumnCell,
} from 'interfaces/excelTable.interfaces'
import { Truncate } from 'packages/ui/Truncate'

import { ExcelTableContext } from './context/ExcelTableContext'
import classes from './ExcelTable.module.scss'
import { useCellOverflow } from './handlers/useCellOverflow'
import { useResizeColumn } from './handlers/useResizeColumn'
import { useSelectColumns } from './handlers/useSelectColumns'
import { getIsSelected } from './helpers/getIsSelected'
import { getTitleFormatted } from './helpers/getTitleFormatted'

interface ExcelColumnCellWithSubscriberProps {
  setWidth?: (width: number, keys?: string[]) => void
  disableLeftResize?: boolean
  disableRightResize?: boolean
  disableSelect?: boolean
  valueIsName?: boolean
  column: SubscribeColumnCell | SubscribeFixedColumnCell
  onResizing?: () => void
  initWidth?: number
  isOverflowed: boolean
}

export const ExcelColumnCellWithSubscriber: FC<ExcelColumnCellWithSubscriberProps> = ({
  setWidth,
  disableLeftResize,
  disableRightResize,
  disableSelect,
  valueIsName,
  column,
  onResizing,
  initWidth,
  isOverflowed,
}) => {
  const refCell = useRef<HTMLDivElement | null>(null)
  const { tableState } = useContext(ExcelTableContext)
  const [cell, setCell] = useState<ExcelAxisItem | null>(column.item || null)
  const [widthCell, setWidthCell, getWidthCell] = useGetState<number | null>(initWidth || null)
  const refMinimalWidthCell = useRef<number | null>(column.item?.initMinWidth || null)
  const columnCell = column as SubscribeColumnCell
  const [isHovered, setIsHovered] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [isSelectedStart, setIsSelectedStart] = useState(false)
  const [isSelectedEnd, setIsSelectedEnd] = useState(false)
  const { isOverflowed: isOverflowedInner } = useCellOverflow(refCell, isOverflowed)

  const title = valueIsName ? cell?.name : cell?.value

  const getTitleFormattedInner = (oversize: boolean) =>
    getTitleFormatted(title, column.item?.name, valueIsName, tableState, oversize)

  const titleFormatted = getTitleFormattedInner(false)
  const titleFormattedWithExponent = getTitleFormattedInner(isOverflowedInner)

  const { onMouseDown, isResizing } = useResizeColumn({
    setWidth,
    refMinimalWidthCell,
    getWidthCell,
    onResizing,
    getCurrentIndex: () => columnCell.lastIndex,
    getCurrentTypeColumn: () => columnCell.type,
  })

  const { onMouseDown: onMouseDownColumn } = useSelectColumns()

  const onMouseOver = () => {
    setIsHovered(true)
  }

  const onMouseOut = () => {
    setIsHovered(false)
  }

  useEffect(() => {
    if (!columnCell) {
      return
    }
    if (!columnCell.isCalculated?.()) {
      columnCell.setCalculated?.()
    } else {
      columnCell.recalculateLeft?.()
    }
    columnCell.setIsMounted?.(true)
    return () => {
      columnCell.setIsMounted?.(false)
    }
  }, [columnCell])

  useEffect(() => column.subscribeCell?.(setCell), [column.subscribeCell])
  useEffect(() => column.subscribeWidth?.(setWidthCell), [column.subscribeWidth])
  useEffect(
    () =>
      columnCell.subscribeMinimalWidth?.(
        (minimalWidthCell: number) => (refMinimalWidthCell.current = minimalWidthCell),
      ),
    [columnCell.subscribeMinimalWidth],
  )

  useEffect(() => {
    if (!tableState.subscribeSelectedColumns || disableSelect) {
      return
    }
    return tableState.subscribeSelectedColumns(
      ({ selectedColumns }) => selectedColumns,
      (selectedColumns: SelectedColumns) => {
        const selected = getIsSelected({ selectedColumns, column })
        setIsSelected(selected.isSelected)
        setIsSelectedStart(selected.isSelectedStart)
        setIsSelectedEnd(selected.isSelectedEnd)
      },
    )
  }, [tableState.subscribeSelectedColumns])

  return (
    <div
      className={cx(classes.cell, {
        [classes.noTitleColumn]: titleFormatted === '',
        [classes.withBorderColumn]: columnCell.item.withBorder,
        [classes.isSelected]: isSelected,
        [classes.isSelectedStart]: isSelectedStart,
        [classes.isSelectedEnd]: isSelectedEnd,
      })}
      data-not-selected="true"
      onMouseDown={disableSelect ? undefined : onMouseDownColumn}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      ref={refCell}
      style={{ width: widthCell || undefined }}
    >
      {isHovered ? (
        <Truncate>{titleFormattedWithExponent}</Truncate>
      ) : (
        <span className="truncateInline">{titleFormattedWithExponent}</span>
      )}
      <span className={cx('truncateInline', classes.valueHidden)} data-value-hidden="true">
        {titleFormatted}
      </span>
      {!disableLeftResize && (
        <div className={cx(classes.resizer, classes.left, { [classes.hide]: isResizing })} data-resizer="true" />
      )}
      {!disableRightResize && (
        <div
          className={cx(classes.resizer, classes.right, { [classes.isResizing]: isResizing })}
          data-resizer="true"
          onMouseDown={onMouseDown}
        />
      )}
    </div>
  )
}
