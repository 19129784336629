import {
  ExcelChangedCellsState,
  ExcelState,
  ExcelStateSelectedCells,
  ExcelStateSelectedColumns,
  TableState,
} from 'interfaces/excelTable.interfaces'

export const getTitleFormatted = (
  title: string | number | null | undefined,
  attribute: string | undefined,
  valueIsName: boolean | undefined,
  tableState: TableState<
    ExcelState,
    ExcelChangedCellsState,
    ExcelStateSelectedCells<ExcelState>,
    ExcelStateSelectedColumns
  >,
  oversize: boolean,
) => {
  const titleGloballyFormatted =
    title && tableState.formatCell && !valueIsName
      ? tableState.formatCell({ value: title, attribute, oversize })
      : title

  if (titleGloballyFormatted !== title) {
    return titleGloballyFormatted
  }
  if (title && tableState.formatDates) {
    return tableState.formatDates(title)
  }
  return title
}
