import { devtools } from 'packages/core'
import { create } from 'zustand'

import { UseSelectedDelistingTable } from './types'

export const useSelectedDelistingTable = create<UseSelectedDelistingTable>()(
  devtools(
    (set) => ({
      selectedIds: [],
      setSelectedIds: (state) => {
        set((prev) => {
          const selectedIds = typeof state === 'function' ? state(prev.selectedIds) : state
          return { selectedIds }
        })
      },
      clear: () => set({ selectedIds: [] }),
    }),
    {
      store: 'selectedDelistingTable',
    },
  ),
)
