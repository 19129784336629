import { trace, Tracer } from '@opentelemetry/api'
import { Resource } from '@opentelemetry/resources'
import { SEMRESATTRS_SERVICE_NAME } from '@opentelemetry/semantic-conventions'
import { configureOpentelemetry } from '@uptrace/web'

const tracer = trace.getTracer('@opentelemetry/api', '1.8.0') as Tracer & { configure: (dsn: string) => void }

tracer.configure = (dsn: string) => {
  configureOpentelemetry({
    dsn,
    resource: new Resource({
      [SEMRESATTRS_SERVICE_NAME]: 'api',
    }),
  })

  performance.setResourceTimingBufferSize(1000)
  performance.clearResourceTimings()
}

export { tracer }
