import { Project } from 'packages/app/modules/projects'
import { Stream } from 'packages/app/modules/streams'
import { defaultGlobalContextValue, GlobalModulesState, MenuCreator, StreamOptions } from 'packages/core'

import { PermissionsData } from './permissions'
import { Profile } from './profile'

export interface AppModulesState extends GlobalModulesState {
  stream: {
    currentStream: Stream | null
    setStream: (stream: Stream | StreamOptions | null) => void
  }
  project: {
    currentProject: Project | null
    setCurrentProject: (project: Project) => void
    disabledSelect: boolean
    setDisabledSelect: (value: boolean) => void
  }
  menuCreator: MenuCreator<AppModulesState>
  profile?: Profile | null
  permissions?: PermissionsData
}

export const defaultModulesState: AppModulesState = {
  ...defaultGlobalContextValue.modules,
  stream: {
    currentStream: null,
    setStream: () => {},
  },
  project: {
    currentProject: null,
    setCurrentProject: () => {},
    disabledSelect: false,
    setDisabledSelect: () => {},
  },
  menuCreator: () => {},
  profile: null,
}
