import { memo, MutableRefObject, useContext, useMemo, useRef } from 'react'

import InfoIcon from 'assets/images/info-mini.svg?react'
import cx from 'clsx'
import {
  CellFields,
  CellLocation,
  ExcelAxisItem,
  ExcelCellData,
  SelectedCells,
  SubscribeListener,
  SubscribeListenerWithLastUpdateDate,
} from 'interfaces/excelTable.interfaces'
import { Loader, LoaderColors, LoaderTypes } from 'packages/ui/Loader'
import { Skeleton } from 'packages/ui/Skeleton'
import { Tooltip } from 'packages/ui/Tooltip'

import { ExcelTableContext } from './context/ExcelTableContext'
import { ExcelCellWithSubscriber } from './ExcelCellWithSubscriber'
import classes from './ExcelTable.module.scss'
import { useCellOverflow } from './handlers/useCellOverflow'
import { getValueFormatted } from './helpers/getValueFormatted'
import { isLoadingCell } from './helpers/isLoadingCell'
import { parseSelectRect } from './helpers/parseSelectRect'

interface ExcelCellProps {
  subscribeCell?: SubscribeListenerWithLastUpdateDate
  subscribeWidth: SubscribeListener
  getFields?: () => CellFields
  getLocation?: () => CellLocation
  attribute?: string
  getOffsetCell?: (
    offsetY: number,
    offsetX: number,
  ) => { cell: ExcelCellData | undefined | null; location: CellLocation; fields: CellFields } | undefined
  getInitCell: () => ExcelCellData | ExcelAxisItem | undefined
  initWidth?: number | null
  ready: boolean
  getSelectedCells: () => SelectedCells | undefined
  indexRow: number
  indexColumn: number
  isFixed: boolean
  refCellsOptions?: MutableRefObject<{
    onKeyDown: ((event: KeyboardEvent) => Promise<(number | null)[][] | undefined>)[][]
  }>
}

export const ExcelCell = memo(
  ({
    attribute,
    subscribeCell,
    subscribeWidth,
    getFields,
    getOffsetCell,
    getInitCell,
    initWidth,
    ready,
    getLocation,
    getSelectedCells,
    indexRow,
    indexColumn,
    isFixed,
    refCellsOptions,
  }: ExcelCellProps) => {
    const refCell = useRef<HTMLDivElement | null>(null)
    const { tableState } = useContext(ExcelTableContext)
    const initCell = getInitCell()
    const fontColor = (initCell as ExcelCellData)?.fontColor
    const color = (initCell as ExcelCellData)?.color
    const isBold = !!(initCell as ExcelCellData)?.isBold
    const withBorder = (initCell as ExcelCellData)?.getColumn?.()?.withBorder
    const isLastRow = (initCell as ExcelCellData)?.isLastRow
    const { isOverflowed } = useCellOverflow(refCell)

    const refReady = useRef(ready)
    if (ready) {
      refReady.current = ready
    }

    const initChangedCell = useMemo(
      () => getLocation && tableState.stateChangedCells?.getState().getByLocation(getLocation()),
      [getLocation, tableState.stateChangedCells],
    )
    const isLoading = isLoadingCell(initChangedCell?.status)

    const initSelectRect = useMemo(
      () => parseSelectRect(getSelectedCells(), isFixed, indexRow, indexColumn),
      [getSelectedCells, isFixed, indexRow, indexColumn],
    )

    if (!initWidth) {
      return null
    }

    const value = initCell?.value !== undefined ? (initChangedCell ? initChangedCell?.value : initCell.value) : ''

    const getValueFormattedInner = (oversize: boolean) =>
      getValueFormatted(value, isFixed ? (initCell as ExcelAxisItem)?.name : attribute, tableState, oversize)

    const valueFormatted = getValueFormattedInner(false)
    const valueFormattedWithExponent = getValueFormattedInner(isOverflowed)

    if (initCell && (refReady.current || tableState.isSelecting)) {
      return (
        <ExcelCellWithSubscriber
          attribute={attribute}
          getFields={getFields}
          getLocation={getLocation}
          getOffsetCell={getOffsetCell}
          indexColumn={indexColumn}
          indexRow={indexRow}
          initCell={initCell}
          initChangedCell={initChangedCell}
          initSelectRect={initSelectRect}
          initWidth={initWidth}
          isFixed={isFixed}
          isOverflowed={isOverflowed}
          refCellsOptions={refCellsOptions}
          subscribeCell={subscribeCell}
          subscribeWidth={subscribeWidth}
        />
      )
    }

    return (
      <div
        className={cx(
          classes.cell,
          initChangedCell?.status && classes[initChangedCell?.status],
          classes[color],
          fontColor && classes[`font_${fontColor}`],
          {
            [classes.isBold]: isBold,
            [classes.withBorder]: withBorder,
            [classes.isLastRow]: isLastRow,
            [classes.selectTop]: initSelectRect.top,
            [classes.selectBottom]: initSelectRect.bottom,
            [classes.selectLeft]: initSelectRect.left,
            [classes.selectRight]: initSelectRect.right,
          },
        )}
        ref={refCell}
        style={{ width: initWidth }}
      >
        {initCell?.value !== undefined && (
          <>
            <span className="truncateInline" ref={refCell}>
              {valueFormattedWithExponent}
            </span>
            <span className={cx('truncateInline', classes.valueHidden)} data-value-hidden="true">
              {valueFormatted}
            </span>
          </>
        )}
        {initCell?.value === undefined && <Skeleton className={classes.skeleton} disableAnimation />}
        {initChangedCell?.error && initChangedCell.status === 'error' && (
          <Tooltip className={classes.errorIcon} isHovered tooltip={initChangedCell.error}>
            <InfoIcon className="svg-img" />
          </Tooltip>
        )}
        {isLoading && (
          <div className={classes.loader}>
            <Loader color={LoaderColors.Dark} type={LoaderTypes.SpinnerMini} />
          </div>
        )}
      </div>
    )
  },
)
