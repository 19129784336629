import { useMemo } from 'react'

import { ApplicationConfig, GlobalContextProps } from 'packages/core/config'

import { MenuBuilder } from './menuBuilder'

export const useMenuBuilder = (applicationConfig: ApplicationConfig): Partial<GlobalContextProps> => {
  const menuBuilder = useMemo(() => new MenuBuilder(), [])
  return {
    menuBuilder,
    commonBottomMenu: applicationConfig.commonBottomMenu,
  }
}
