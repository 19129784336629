export enum LoaderTypes {
  Default = 'default',
  Opacity = 'opacity',
  Spinner = 'spinner',
  SpinnerMini = 'spinnerMini',
  SpinnerLarge = 'spinnerLarge',
  SpinnerCenter = 'spinnerCenter',
  SpinnerCenterLarge = 'spinnerCenterLarge',
  Text = 'text',
  Mini = 'mini',
  Paginate = 'paginate',
}

export enum LoaderColors {
  Default = 'defaultColor',
  White = 'white',
  Gray = 'gray',
  Dark = 'dark',
  Danger = 'danger',
}
