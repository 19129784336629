import { TetrisScenarioTabsCodes } from 'opti/tetrisDataSource/interfaces/common'
import { devtools } from 'packages/core'
import { create } from 'zustand'

import { UseTetrisScenarioTab } from './types'

export const useTetrisScenarioTab = create<UseTetrisScenarioTab>()(
  devtools(
    (set) => ({
      currentTab: TetrisScenarioTabsCodes.Input,
      setCurrentTab: (currentTab) => set({ currentTab }),
    }),
    {
      store: 'currentTetrisScenarioTab',
    },
  ),
)
