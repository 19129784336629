import { FC, ReactNode, useEffect, useState } from 'react'

import { useEventListener } from 'ahooks'
import cx from 'clsx'
import { t } from 'packages/localization'
import { Button, ButtonColors, ButtonSizes } from 'packages/ui/Button'
import { ButtonEasy } from 'packages/ui/ButtonEasy'
import { Modal, OverlayingModal } from 'packages/ui/Modal'

import classes from './ConfirmModal.module.scss'

interface ConfirmModalProps {
  className?: string
  classNameButtons?: string
  classNameOverlayingModal?: string
  isOpened?: boolean
  disabledScroll?: boolean
  onClose?: () => void
  title?: ReactNode
  danger?: boolean
  cancelText?: string
  okText?: string
  onCancel?: () => void
  onOk?: () => void
  closeAfterSuccess?: boolean
  loading?: boolean
  okDisabled?: boolean
  isButtonEasyModal?: boolean
  isCancelButtonHide?: boolean
  children?: ReactNode
  width?: number
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  className,
  classNameButtons,
  isOpened,
  disabledScroll,
  onClose,
  title,
  danger,
  cancelText = t('cancel'),
  okText = 'OK',
  onCancel,
  onOk,
  closeAfterSuccess,
  loading,
  isButtonEasyModal,
  isCancelButtonHide,
  children,
  width,
  okDisabled,
  classNameOverlayingModal,
}) => {
  const [pressOk, setPressOk] = useState<boolean>(false)

  const onCancelInternal = () => {
    if (onCancel) {
      onCancel()
    }
    if (onClose) {
      onClose()
    }
  }

  const onOkInternal = () => {
    if (onOk) {
      onOk()
    }
    if (!closeAfterSuccess && onClose) {
      onClose()
    }
    requestAnimationFrame(() => setPressOk(true))
  }

  useEventListener('keydown', (event) => {
    if (!isOpened) {
      return
    }

    const key = event.key
    if (key === 'Enter') {
      onOkInternal()
    }

    if (key === 'Escape') {
      onCancelInternal()
    }
  })

  useEffect(() => {
    if (closeAfterSuccess && pressOk && !loading && onClose) {
      setPressOk(false)
      onClose()
    }
  }, [pressOk, loading, closeAfterSuccess, onClose])

  return (
    <OverlayingModal
      className={classNameOverlayingModal}
      disabledScroll={disabledScroll}
      isOpened={isOpened}
      onClose={onClose}
    >
      {!isButtonEasyModal ? (
        <Modal
          className={cx(classes.popup, className)}
          classNameTitle={cx(classes.title, { [classes.titleWithChildren]: children })}
          onClose={onClose}
          title={title}
          width={width}
        >
          {children}
          <div className={cx(classes.buttons, classNameButtons, { [classes.oneButton]: isCancelButtonHide })}>
            {!isCancelButtonHide && (
              <Button color={ButtonColors.Border} onClick={onCancelInternal}>
                {cancelText}
              </Button>
            )}
            <Button
              color={danger ? ButtonColors.Danger : ButtonColors.Primary}
              disabled={okDisabled}
              loading={loading}
              onClick={onOkInternal}
            >
              {okText}
            </Button>
          </div>
        </Modal>
      ) : (
        <div className={classes.buttonEasyPopup}>
          <ButtonEasy label={okText} loading={loading} onClick={onOkInternal} showLabelAlways>
            {title}
          </ButtonEasy>
          <Button
            className={classes.buttonEasyCancel}
            color={ButtonColors.Text}
            onClick={onCancelInternal}
            size={ButtonSizes.Medium}
          >
            {t('unSave')}
          </Button>
        </div>
      )}
    </OverlayingModal>
  )
}
