import { devtools } from 'packages/core'
import { create } from 'zustand'

export interface CustomerFilters {
  codes: string[]
  names: string[]
}

interface UseCustomerTableFilters {
  filters: CustomerFilters
  setCodes: (codes: string[]) => void
  setNames: (names: string[]) => void
  clear: () => void
}

export const useCustomerTableFilters = create<UseCustomerTableFilters>()(
  devtools(
    (set) => ({
      filters: {
        codes: [],
        names: [],
      },
      setCodes: (codes) =>
        set(({ filters }) => {
          filters.codes = codes
          return { filters: { ...filters } }
        }),
      setNames: (names) =>
        set(({ filters }) => {
          filters.names = names
          return { filters: { ...filters } }
        }),
      clear: () =>
        set({
          filters: {
            codes: [],
            names: [],
          },
        }),
    }),
    {
      store: 'customersTableFilters',
    },
  ),
)
