import { useMutation } from '@tanstack/react-query'
import { omit } from 'lodash'
import { getError } from 'packages/api'
import { scorecardApi } from 'prgm/scorecard/api'
import { transformScenarioToServer } from 'prgm/scorecard/helpers'
import {
  useControlCalculate,
  useControlScenario,
  useDiagrams,
  useDifference,
  useScorecardSettings,
  useTargetCalculate,
  useTargetScenario,
} from 'prgm/scorecard/stores'
import { useMutationState } from 'prgm/shared/handlers'

import { ScorecardQueries } from './types'

export const useCalculateScenarios = () => {
  const { isSuccess, isLoading } = useMutationState(ScorecardQueries.CalculateScenarios)
  const target = useTargetScenario((state) => state.targetScenario)
  const control = useControlScenario((state) => state.controlScenario)
  const { filters } = useScorecardSettings()
  const setDifferenceRows = useDifference((state) => state.setRows)
  const setDifference = useDifference((state) => state.setDifference)
  const setDiagrams = useDiagrams((state) => state.setDiagrams)
  const setTargetCalculate = useTargetCalculate((state) => state.setTargetCalculate)
  const setControlCalculate = useControlCalculate((state) => state.setControlCalculate)

  const {
    mutate: calculateScenarios,
    error,
    reset,
  } = useMutation({
    mutationKey: [ScorecardQueries.CalculateScenarios],
    mutationFn: () =>
      scorecardApi.calculateScenarios({
        filters: omit(filters, ['view', 'timeGranularity', 'controlYear', 'targetYear', 'periodStart', 'periodEnd']),
        scenarios: {
          target: transformScenarioToServer(target),
          control: transformScenarioToServer(control),
        },
        differenceType: filters.view,
        controlYear: filters.controlYear,
        targetYear: filters.targetYear,
        timeGranularity: filters.timeGranularity,
        periodEnd: filters.periodEnd,
        periodStart: filters.periodStart,
      }),
    onSuccess: (data) => {
      setDifferenceRows(data.difference.rows)
      setDifference(data.difference)
      setDiagrams(data.diagrams)
      setTargetCalculate(data.target)
      setControlCalculate(data.control)
    },
    meta: {
      error: (errorApi) => ({
        showToast: true,
        message: getError(errorApi),
      }),
    },
  })

  return { calculateScenarios, isSuccess, isLoading, error: getError(error), reset }
}
