import { Streams } from 'constants/streams'
import { ModuleConnector } from 'packages/app'
import { t } from 'packages/localization'
import { PrgmRoles } from 'prgm/shared/constants/roles'

export const prgmDataHeathPromoModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    dataHealth: {
      label: () => t('prgm.navMenu.dataHealth'),
      href: `/${Streams.Prgm}/data-health`,
      dependence: (appContext) =>
        !!appContext.modules.profile?.groups.find((group) => group.name === PrgmRoles.Administrator),
    },
  },
})
