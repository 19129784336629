import { forwardRef, HTMLAttributes } from 'react'

import { DragElement } from 'packages/ui/Draggable'

export const Td = forwardRef<
  HTMLTableCellElement,
  Omit<HTMLAttributes<HTMLTableCellElement>, 'onChange'> & { isDraggable?: boolean }
>(({ isDraggable, children, ...props }, ref) =>
  isDraggable ? (
    <DragElement as="td" enabled {...props} ref={ref}>
      {children}
    </DragElement>
  ) : (
    <td {...props} ref={ref}>
      {children}
    </td>
  ),
)
