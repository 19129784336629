import { DelistingTabsCodes } from 'jume/delistingTable/interfaces/common'
import { devtools } from 'packages/core'
import { create } from 'zustand'

import { UseCurrentDelistingTab } from './types'

export const useCurrentDelistingTab = create<UseCurrentDelistingTab>()(
  devtools(
    (set) => ({
      currentTab: DelistingTabsCodes.All,
      setCurrentTab: (currentTab) => set({ currentTab }),
      clear: () => set({ currentTab: DelistingTabsCodes.All }),
    }),
    {
      store: 'currentDelistingTab',
    },
  ),
)
