import { UseNavigateResult } from '@tanstack/react-router'
import { axios } from 'packages/core/apiConfig'
import { Tokens, TokensDefined, tokensStorage } from 'packages/core/auth/tokens'
import { GlobalContextProps, GlobalModulesState } from 'packages/core/config'

export type AuthPipeFn<ModulesState extends GlobalModulesState = GlobalModulesState> = (
  builder: AuthBuilder<ModulesState>,
) => void | Promise<void>

export class AuthBuilder<ModulesState extends GlobalModulesState = GlobalModulesState> {
  setAuthReady: (isReady: boolean) => void
  setAuthenticated: (authenticated: boolean) => void
  afterAuthenticate?: (runInitialRequests?: boolean) => Promise<void>
  navigate: UseNavigateResult<string>
  appState: Partial<GlobalContextProps<ModulesState>>

  accessToken: string | null = null
  refreshToken: string | null = null
  isAuthenticated = false
  locationIsCorrect = false
  redirectPage: string | null = null

  constructor(params: {
    setAuthReady: (isReady: boolean) => void
    setAuthenticated: (authenticated: boolean) => void
    navigate: UseNavigateResult<string>
    appState: Partial<GlobalContextProps<ModulesState>>
  }) {
    this.setAuthReady = params.setAuthReady
    this.setAuthenticated = params.setAuthenticated
    this.navigate = params.navigate
    this.appState = params.appState
  }

  setTokens(tokens: Tokens) {
    this.accessToken = tokens.accessToken
    this.refreshToken = tokens.refreshToken
    if (this.accessToken && this.refreshToken) {
      axios.setToken(tokens.accessToken)
      this.isAuthenticated = true
    }
  }

  async apply<T extends ModulesState>(fn?: AuthPipeFn<T>) {
    await fn?.(this as unknown as AuthBuilder<T>)
  }

  async authenticate(tokens: TokensDefined, remember = false) {
    this.setTokens(tokens)
    this.setAuthenticated(true)
    tokensStorage.set(tokens, remember)
    await this.afterAuthenticate?.()
    this.setAuthReady(true)
  }
}
