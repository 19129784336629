import { CustomAxiosInstance } from 'packages/core/apiConfig/types'

import { AxiosExtendedRequestConfig } from './types'

export class RequestQueue {
  private requestQueue: AxiosExtendedRequestConfig[] = []
  private axios: CustomAxiosInstance

  constructor(axios: CustomAxiosInstance) {
    this.axios = axios
  }

  pushToRequestQueue(request: AxiosExtendedRequestConfig) {
    this.requestQueue.push(request)
    return this.requestQueue.length - 1
  }

  setAccessToken(accessToken: string) {
    this.requestQueue.forEach((request) => {
      if (!request.headers) {
        request.headers = {}
      }
      request.headers.Authorization = `Bearer ${accessToken}`
    })
  }

  executeByQueue(request: AxiosExtendedRequestConfig) {
    const indexRequest = this.pushToRequestQueue(request)
    return new Promise((resolve, reject) => {
      const sendRetryRequest = async () => {
        window.removeEventListener('newToken', sendRetryRequest)
        await this.axios.request(this.requestQueue[indexRequest]).then(resolve).catch(reject)
      }
      window.addEventListener('newToken', sendRetryRequest)
    })
  }

  runQueue() {
    const tokenEvent = new Event('newToken')
    window.dispatchEvent(tokenEvent)
  }
}
