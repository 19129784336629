import { useCallback, useRef } from 'react'

export const useInterval = (fn: (any: []) => void, delay: number) => {
  const refId = useRef<number | null>(null)

  const enableInterval = useCallback(() => {
    if (refId.current === null) {
      refId.current = setInterval(fn, delay)
    }
  }, [])

  const disableInterval = useCallback(() => {
    if (refId.current !== null) {
      clearInterval(refId.current)
      refId.current = null
    }
  }, [])

  const isEnableInterval = useCallback(() => refId.current !== null, [])

  return {
    enableInterval,
    disableInterval,
    isEnableInterval,
  }
}
