import { StatusTableRow } from 'jume/statusTable/api'
import { isEqual } from 'lodash'
import { devtools } from 'packages/core'
import { deleteEmpty } from 'packages/helper'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

import { UseUpdateStatusTable } from './types'

const filterEquals = (updated: UseUpdateStatusTable['updated'], initialRow?: StatusTableRow) =>
  initialRow
    ? updated.filter(
        (item) =>
          !isEqual(
            {
              id: item.id,
              isArchived: item.isArchived ?? (initialRow?.isArchived || initialRow?.hasPendingArchivationRequest),
              statusId: item.statusId ?? initialRow.calculatedStatus?.id,
            },
            {
              id: initialRow.id,
              isArchived: initialRow?.isArchived || initialRow?.hasPendingArchivationRequest,
              statusId: initialRow.calculatedStatus?.id,
            },
          ),
      )
    : updated

const filterEqualsArray = (updated: UseUpdateStatusTable['updated'], initialRows?: StatusTableRow[]) =>
  initialRows?.length
    ? updated.filter((item) => {
        const initialRow = initialRows.find((row) => item.id === row.id)
        return !isEqual(
          {
            id: item.id,
            isArchived: item.isArchived ?? (initialRow?.isArchived || initialRow?.hasPendingArchivationRequest),
            statusId: item.statusId ?? initialRow?.calculatedStatus?.id,
          },
          {
            id: initialRow?.id,
            isArchived: initialRow?.isArchived || initialRow?.hasPendingArchivationRequest,
            statusId: initialRow?.calculatedStatus?.id,
          },
        )
      })
    : updated

export const useUpdateStatusTable = create<UseUpdateStatusTable>()(
  devtools(
    subscribeWithSelector((set) => ({
      updated: [],
      lastSavedErrorData: {
        updated: [],
      },
      setLastSavedErrorData: (lastSavedErrorData) => set(() => ({ lastSavedErrorData })),
      setArchive: (id, isArchived, initialRow, isAdmin) =>
        set(({ updated }) => {
          const row = updated.find((item) => item.id === id)
          if (isAdmin) {
            if (row) {
              row.isArchived = isArchived
              row.isArchivationRequestAccepted = isArchived
            } else {
              updated.push({ id, isArchived, isArchivationRequestAccepted: isArchived })
            }
            if (typeof row?.isArchived !== 'undefined' && row?.isArchived === initialRow?.isArchived) {
              delete row.isArchived
              delete row.isArchivationRequestAccepted
            }
          } else {
            if (row) {
              row.isArchived = isArchived
            } else {
              updated.push({ id, isArchived })
            }
            if (typeof row?.isArchived !== 'undefined' && row?.isArchived === initialRow?.isArchived) {
              delete row.isArchived
            }
          }
          return { updated: filterEquals(updated, initialRow) }
        }),
      setArchiveIds: (ids, initialRows, isAdmin) =>
        set(({ updated }) => {
          ids.forEach((id) => {
            const row = updated.find((item) => item.id === id)
            if (isAdmin) {
              if (row) {
                row.isArchived = true
                row.isArchivationRequestAccepted = true
              } else {
                updated.push({ id, isArchived: true, isArchivationRequestAccepted: true })
              }
            } else {
              if (row) {
                row.isArchived = true
              } else {
                updated.push({ id, isArchived: true })
              }
            }
          })
          return { updated: filterEqualsArray(updated, initialRows) }
        }),
      setReturnedFromArchiveIds: (ids, initialRows) =>
        set(({ updated }) => {
          ids.forEach((id) => {
            const row = updated.find((item) => item.id === id)
            if (row) {
              row.isArchived = false
            } else {
              updated.push({ id, isArchived: false })
            }
          })
          return { updated: filterEqualsArray(updated, initialRows) }
        }),
      updateStatus: (id, statusId, initialRow) => {
        if (!statusId) {
          set(({ updated }) => {
            const rowIndex = updated.findIndex((item) => item.id === id)
            if (rowIndex > -1) {
              delete updated[rowIndex].statusId
              if (!Object.keys(deleteEmpty({ ...updated[rowIndex], id: null })).length) {
                updated.splice(rowIndex, 1)
              }
            }
            return { updated }
          })
          return
        }
        set(({ updated }) => {
          const row = updated.find((item) => item.id === id)
          if (row) {
            row.statusId = statusId
          } else {
            updated.push({ id, statusId })
          }
          return { updated: filterEquals(updated, initialRow) }
        })
      },
      clearArchive: () =>
        set(({ updated }) => ({
          updated: updated
            .map((item) => {
              delete item.isArchived
              return item
            })
            .filter((item) => Object.keys(item).filter((key) => key !== 'id').length),
        })),
      clearReturnedFromArchive: () => set(({ updated }) => ({ updated: updated.filter((item) => item.isArchived) })),
      clear: () => set({ updated: [] }),
    })),
    {
      store: 'statusTable',
    },
  ),
)
