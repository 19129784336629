import { Endpoint, EndpointParam } from './types'

export const createEndpoint = (route: string, params?: Record<string, EndpointParam>): Endpoint => {
  let url = route
  if (params) {
    for (const key in params) {
      if (params[key] !== null && params[key] !== undefined) {
        const regex = new RegExp(':' + key, 'g')
        url = url.replace(regex, String(params[key]))
      }
    }
  }
  return {
    apiRoute: route,
    url,
  }
}
