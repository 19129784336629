import { DetailedHTMLProps, forwardRef, InputHTMLAttributes, ReactNode } from 'react'

import cx from 'clsx'

import classes from './Radio.module.scss'

export enum RadioButtonSizes {
  Medium = 'medium',
  Small = 'small',
}

interface RadioProps extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'size'> {
  children: ReactNode
  checked: boolean
  classNameLabel?: string
  value?: string
  size?: RadioButtonSizes
  disabled?: boolean
  noEditable?: boolean
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      checked,
      className,
      classNameLabel,
      value,
      children,
      name,
      size = RadioButtonSizes.Medium,
      disabled,
      noEditable,
      ...props
    },
    ref,
  ) => (
    <div
      className={cx(classes.wrap, classes[size], className, {
        [classes.checked]: checked,
        [classes.disabled]: disabled,
        [classes.noEditable]: noEditable,
      })}
    >
      <input
        aria-label={String(children)}
        className={classes.input}
        id={value}
        name={name}
        ref={ref}
        type="radio"
        value={value}
        {...props}
      />
      <label className={cx(classes.label, classNameLabel)} htmlFor={value}>
        {children}
      </label>
    </div>
  ),
)
