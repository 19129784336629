import { keySetsEn } from 'locale/en'
import { keySetsRu } from 'locale/ru'
import { createI18n } from 'packages/localization'

export const localizationKeySets = {
  ru: keySetsRu,
  en: keySetsEn,
}

export const localization = createI18n(localizationKeySets)
