import {
  memo,
  MouseEventHandler,
  RefObject,
  TouchEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  WheelEventHandler,
} from 'react'

import { useUpdate, useUpdateEffect } from 'ahooks'
import cx from 'clsx'
import {
  ChartOptions,
  ExcelAxisItem,
  ExcelChangedCellsState,
  ExcelState,
  ExcelStateSelectedCells,
  ExcelStateSelectedColumns,
  ExcelStorageWidths,
  FilterState as IFilterState,
  TableState,
  VirtualScrollPosition,
  VirtualScrollState,
} from 'interfaces/excelTable.interfaces'
import { StoreSubscribeWithSelector, Write } from 'interfaces/tanstackQuery.interfaces'
import { isEqual, sum } from 'lodash'
import { t } from 'packages/localization'
import { StoreApi, UseBoundStore } from 'zustand'

import { ExcelTableContext } from './context/ExcelTableContext'
import { ExcelChart } from './ExcelChart'
import { ExcelColumns } from './ExcelColumns'
import { ExcelFixedBlock } from './ExcelFixedBlock'
import { ExcelLoading } from './ExcelLoading'
import { ExcelPages } from './ExcelPages'
import classes from './ExcelTable.module.scss'
import { useSelectCells } from './handlers/useSelectCells'
import { VirtualScroll } from './VirtualScroll'

interface ExcelTableProps<
  State extends ExcelState = ExcelState,
  StateChangedCells extends ExcelChangedCellsState = ExcelChangedCellsState,
  StateSelectedCells extends ExcelStateSelectedCells<State> = ExcelStateSelectedCells<State>,
  StateSelectedColumns extends ExcelStateSelectedColumns = ExcelStateSelectedColumns,
  FilterState extends IFilterState = IFilterState,
> {
  state: UseBoundStore<Write<StoreApi<State>, StoreSubscribeWithSelector<State>>>
  defaultWidthColumn?: number
  minWidthColumn?: number
  addCommonColumn?: (columns: ExcelAxisItem[][]) => string
  storageWidths?: ExcelStorageWidths
  isLoading?: boolean
  stateChangedCells: UseBoundStore<Write<StoreApi<StateChangedCells>, StoreSubscribeWithSelector<StateChangedCells>>>
  stateSelectedCells: UseBoundStore<Write<StoreApi<StateSelectedCells>, StoreSubscribeWithSelector<StateSelectedCells>>>
  stateSelectedColumns: UseBoundStore<
    Write<StoreApi<StateSelectedColumns>, StoreSubscribeWithSelector<StateSelectedColumns>>
  >
  formatDates?: (title: string | number) => string | number
  formatCell?: ({
    value,
    attribute,
    oversize,
  }: {
    value: number | string
    attribute?: string
    oversize?: boolean
  }) => string | number | null | undefined
  getAttribute?: (rowId: number, columnId: number) => string
  filterState?: UseBoundStore<Write<StoreApi<FilterState>, StoreSubscribeWithSelector<FilterState>>>
  invalidFilterText?: string
  chartOptions?: ChartOptions
}

export const ExcelTable = memo(
  <
    State extends ExcelState = ExcelState,
    StateChangedCells extends ExcelChangedCellsState = ExcelChangedCellsState,
    StateSelectedCells extends ExcelStateSelectedCells<State> = ExcelStateSelectedCells<State>,
    StateSelectedColumns extends ExcelStateSelectedColumns = ExcelStateSelectedColumns,
  >({
    state,
    defaultWidthColumn = 75,
    minWidthColumn = 50,
    addCommonColumn,
    storageWidths,
    isLoading,
    stateChangedCells,
    stateSelectedCells,
    stateSelectedColumns,
    formatDates,
    formatCell,
    getAttribute,
    filterState,
    invalidFilterText,
    chartOptions,
  }: ExcelTableProps<State, StateChangedCells, StateSelectedCells, StateSelectedColumns>) => {
    const [paginateAxis, setPaginateAxis] = useState<'x' | 'y'>('y')
    const [countX, setCountX] = useState(0)
    const [countY, setCountY] = useState(0)
    const [perPage, setPerPage] = useState(0)
    const perPageCombined = perPage || state.getState().perPage || 0
    const count = paginateAxis === 'y' ? countY : countX
    const showChart = state.getState().showChart

    const update = useUpdate()

    const subscribe = useCallback(
      <U,>(selector: (state: State) => U, listener: (selectedState: U, previousSelectedState: U) => void) =>
        state.subscribe<U>(selector, listener, { fireImmediately: true }),
      [state],
    )
    const subscribeChangedCell = useCallback(
      <U,>(selector: (state: StateChangedCells) => U, listener: (selectedState: U, previousSelectedState: U) => void) =>
        stateChangedCells.subscribe<U>(selector, listener, { fireImmediately: true }),
      [stateChangedCells],
    )
    const subscribeSelectedCells = useCallback(
      <U,>(
        selector: (state: StateSelectedCells) => U,
        listener: (selectedState: U, previousSelectedState: U) => void,
      ) => stateSelectedCells.subscribe<U>(selector, listener, { fireImmediately: true }),
      [stateSelectedCells],
    )
    const subscribeSelectedColumns = useCallback(
      <U,>(
        selector: (state: StateSelectedColumns) => U,
        listener: (selectedState: U, previousSelectedState: U) => void,
      ) => stateSelectedColumns.subscribe<U>(selector, listener, { fireImmediately: true }),
      [stateSelectedColumns],
    )

    const getState = state.getState
    const getStateSelectedCells = stateSelectedCells.getState
    const getStateSelectedColumns = stateSelectedColumns.getState

    const tableState = useRef<TableState<State, StateChangedCells, StateSelectedCells>>({
      slices: {},
      currentSlices: {},
      currentSliceX: {},
      onChangeSliceX: {},
      columns: {},
      columnsKeys: [],
      storageWidths,
      isSelecting: false,
      defaultWidthColumn,
      heightRow: 32,
      minWidthColumn,
      formatDates,
      formatCell,
      getAttribute,
      state,
      stateChangedCells,
      stateSelectedCells,
      stateSelectedColumns,
      subscribe,
      subscribeChangedCell,
      subscribeSelectedCells,
      subscribeSelectedColumns,
      getState,
      getStateSelectedCells,
      getStateSelectedColumns,
      filterState,
      invalidFilterText,
      chartOptions,
    })

    useEffect(() => {
      tableState.current.formatCell = formatCell
    }, [formatCell])

    const virtualScrollState = useRef<VirtualScrollState>({
      position: {
        startX: null,
        endX: null,
        startY: null,
        endY: null,
      },
      onChangeFns: [],
      ready: true,
      onChangeReady: [],
    })

    const refCellsOptions = useRef<{
      onKeyDown: ((event: KeyboardEvent) => Promise<(number | null)[][] | undefined>)[][]
    }>({ onKeyDown: [] })

    const refWrap = useRef<HTMLDivElement>(null)
    const refCont = useRef<HTMLDivElement>(null)
    const refRows = useRef<HTMLDivElement>(null)
    const refCommonColumn = useRef<HTMLDivElement>(null)
    const refColumns = useRef<HTMLDivElement>(null)
    const refFixedRows = useRef<HTMLDivElement>(null)

    const getUploadingWidths = useCallback(() => {
      const widths = storageWidths?.getState() || {}
      const uploadingWidths = Object.entries(widths).map(([_, width]) => width)
      const newUploadingCountColumns = uploadingWidths.length
      const newUploadingWidthColumns = sum(uploadingWidths)
      return { newUploadingCountColumns, newUploadingWidthColumns }
    }, [storageWidths])
    const { newUploadingCountColumns, newUploadingWidthColumns } = useMemo(getUploadingWidths, [storageWidths])

    const [uploadingCountColumns, setUploadingCountColumns] = useState(newUploadingCountColumns)
    const [uploadingWidthColumns, setUploadingWidthColumns] = useState(newUploadingWidthColumns)

    const refScrollingData = useRef<{
      refWrap: RefObject<HTMLDivElement>
      refCont: RefObject<HTMLDivElement>
      refRows: RefObject<HTMLDivElement>
      refCommonColumn: RefObject<HTMLDivElement>
      refColumns: RefObject<HTMLDivElement>
      refFixedRows: RefObject<HTMLDivElement>
      onMouseMove?: MouseEventHandler<HTMLDivElement>
      onTouchMove?: TouchEventHandler<HTMLDivElement>
      onTouchStartContent?: TouchEventHandler<HTMLDivElement>
      onWheel?: WheelEventHandler<HTMLDivElement>
      scrollToPx?: (y: number, x: number) => void
    }>({ refWrap, refCont, refRows, refCommonColumn, refColumns, refFixedRows })

    const onMouseMove: MouseEventHandler<HTMLDivElement> = (event) => refScrollingData.current.onMouseMove?.(event)
    const onTouchMove: TouchEventHandler<HTMLDivElement> = (event) => refScrollingData.current.onTouchMove?.(event)
    const onTouchStartContent: TouchEventHandler<HTMLDivElement> = (event) =>
      refScrollingData.current.onTouchStartContent?.(event)
    const onWheel: WheelEventHandler<HTMLDivElement> = (event) => refScrollingData.current.onWheel?.(event)

    const setVirtualScrollPosition = useCallback((position: VirtualScrollPosition) => {
      if (!isEqual(virtualScrollState.current.position, position)) {
        virtualScrollState.current.position = position
        virtualScrollState.current.onChangeFns.forEach((fn) => fn(position))
      }
    }, [])

    const onChangeReady = useCallback((ready: boolean) => {
      if (virtualScrollState.current.ready !== ready) {
        virtualScrollState.current.ready = ready
        virtualScrollState.current.onChangeReady.forEach((fn) => fn(ready))
      }
    }, [])

    const setDependencies = useMemo(() => getState().setDependencies, [state])
    const setDependenciesChange = useMemo(() => getState().setDependenciesChange, [state])
    const setDependenciesMinWidth = useMemo(() => getState().setDependenciesMinWidth, [state])
    const setWidthState = useMemo(() => getState().setWidthByKeys, [state])
    const setWidths = useMemo(() => getState().setWidths, [state])
    const setMinimalWidths = useMemo(() => getState().setMinimalWidths, [state])
    const addKeyToCells = useMemo(() => getState().addKeyToCells, [state])
    const setSlicePages = useMemo(() => getState().setSlicePages, [state])
    const getDependencies = useCallback(() => getState().widthColumns.dependencies, [state])
    const getWidths = useCallback(() => getState().widthColumns.widths, [state])
    const getPerPage = useCallback(() => getState().perPage || 0, [state])
    const getCountX = useCallback(() => getState().countX || 0, [state])
    const getCountY = useCallback(() => getState().countY || 0, [state])
    const getPaginateAxis = useCallback(() => getState().paginateAxis || 'y', [state])
    const getDepth = useCallback(() => {
      let depth = getState().data?.[1]?.columns[0]?.length || 1
      if (depth > 1) {
        depth += Number(!!addCommonColumn)
      }
      return depth
    }, [state])
    const getDepthColumns = useCallback(() => getState().data?.[1]?.columns[0]?.length || 1, [state])

    const setWidth = useCallback((keys: string[], width: number) => {
      setWidthState(keys, width)
      const newUploadingWidths = getUploadingWidths()
      setUploadingCountColumns(newUploadingWidths.newUploadingCountColumns)
      setUploadingWidthColumns(newUploadingWidths.newUploadingWidthColumns)
    }, [])

    useEffect(() => state.subscribe((data) => data.countX || 0, setCountX, { fireImmediately: true }), [])
    useEffect(() => state.subscribe((data) => data.countY || 0, setCountY, { fireImmediately: true }), [])
    useEffect(() => state.subscribe((data) => data.perPage || 0, setPerPage, { fireImmediately: true }), [])
    useEffect(() => state.subscribe((data) => data.paginateAxis || 'y', setPaginateAxis, { fireImmediately: true }), [])

    useSelectCells({
      refWrap,
      refColumns,
      virtualScrollState: virtualScrollState.current,
      tableState: tableState.current,
      getState,
      getStateSelectedCells,
      getStateSelectedColumns,
      refScrollingData,
      refCellsOptions,
    })

    useUpdateEffect(() => {
      if (isLoading) {
        virtualScrollState.current.position = {
          startX: null,
          endX: null,
          startY: null,
          endY: null,
        }
        virtualScrollState.current.ready = false
        virtualScrollState.current.onChangeFns = []
        virtualScrollState.current.onChangeReady = []
        refWrap?.current?.setAttribute('data-left', '0')
        refWrap?.current?.setAttribute('data-top', '0')
      }
    }, [isLoading])

    if (!isLoading && !getCountX() && !getCountY()) {
      return <span className="gray">{t('noData')}</span>
    }

    return (
      <ExcelTableContext.Provider
        value={{
          tableState: tableState.current as unknown as TableState<
            ExcelState,
            ExcelChangedCellsState,
            ExcelStateSelectedCells
          >,
          virtualScroll: virtualScrollState.current,
          scrollingData: refScrollingData.current,
        }}
      >
        {showChart && <ExcelChart isLoading={isLoading} />}
        <div className={cx(classes.wrap, { [classes.wrapLoading]: isLoading })} ref={refWrap} tabIndex={0}>
          {isLoading && <ExcelLoading />}
          {!isLoading && (
            <>
              <ExcelFixedBlock
                countX={countX}
                countY={countY}
                getDepth={getDepth}
                getWidths={getWidths}
                paginateAxis={paginateAxis}
                perPage={perPageCombined}
                refFixedRows={refFixedRows}
                setWidth={setWidth}
                setWidths={setWidths}
              />
              <div
                className={classes.rowsCont}
                data-count-x={countX}
                data-count-y={countY}
                onMouseMove={onMouseMove}
                onTouchMove={onTouchMove}
                onTouchStart={onTouchStartContent}
                onWheel={onWheel}
                ref={(el) => {
                  if (!el || refCont.current === el) {
                    return
                  }
                  requestAnimationFrame(update)
                  ;(refCont as any).current = el
                }}
              >
                <div className={classes.rows} ref={refRows} style={{ transform: 'translate(0)' }}>
                  <div
                    className={classes.columns}
                    ref={refColumns}
                    style={{ height: (getDepth() - 1 + Number(!!addCommonColumn)) * tableState.current.heightRow }}
                  >
                    {Array.from({
                      length:
                        !!count && !!perPageCombined && paginateAxis === 'x' ? Math.ceil(count / perPageCombined) : 1,
                    }).map((_, index) => (
                      <ExcelColumns
                        addCommonColumn={addCommonColumn}
                        addKeyToCells={addKeyToCells}
                        getDependencies={getDependencies}
                        getDepthColumns={getDepthColumns}
                        initColumns={getState().data?.[index + 1]?.columns}
                        key={index}
                        page={index + 1}
                        paginateAxis={paginateAxis}
                        perPage={perPageCombined}
                        refCommonColumn={index === 0 ? refCommonColumn : undefined}
                        refRows={refCont}
                        setDependencies={setDependencies}
                        setDependenciesChange={setDependenciesChange}
                        setDependenciesMinWidth={setDependenciesMinWidth}
                        setMinimalWidths={setMinimalWidths}
                        setWidth={setWidth}
                        setWidths={setWidths}
                      />
                    ))}
                  </div>

                  <ExcelPages
                    getCountX={getCountX}
                    getCountY={getCountY}
                    getPaginateAxis={getPaginateAxis}
                    getPerPage={getPerPage}
                    refCellsOptions={refCellsOptions}
                    setSlicePages={setSlicePages}
                  />
                </div>
                <VirtualScroll
                  countX={countX}
                  countY={countY}
                  onChangeReady={onChangeReady}
                  setVirtualScrollPosition={setVirtualScrollPosition}
                  uploadingCountColumns={uploadingCountColumns}
                  uploadingWidthColumns={uploadingWidthColumns}
                />
              </div>
            </>
          )}
        </div>
      </ExcelTableContext.Provider>
    )
  },
)
