import { MutableRefObject, RefObject, useCallback, useEffect, useRef } from 'react'

import { useClickAway } from 'ahooks'
import { useInterval } from 'hooks/useInterval'
import {
  ExcelChangedCellsState,
  ExcelState,
  ExcelStateSelectedCells,
  ExcelStateSelectedColumns,
  SelectedCells,
  TableState,
  VirtualScrollState,
} from 'interfaces/excelTable.interfaces'
import { cloneDeep, findLastIndex, isEqual } from 'lodash'
import { copy } from 'packages/helper'
import { isShowChartIcon } from 'packages/ui/ExcelTable/helpers/isShowChartIcon'

interface UseSelectCellsProps<
  State extends ExcelState = ExcelState,
  StateChangedCells extends ExcelChangedCellsState = ExcelChangedCellsState,
  StateSelectedCells extends ExcelStateSelectedCells<State> = ExcelStateSelectedCells<State>,
  StateSelectedColumns extends ExcelStateSelectedColumns = ExcelStateSelectedColumns,
> {
  refWrap: RefObject<HTMLDivElement>
  refColumns: RefObject<HTMLDivElement>
  virtualScrollState: VirtualScrollState
  tableState: TableState<State, StateChangedCells, StateSelectedCells>
  getState: () => State
  getStateSelectedCells: () => StateSelectedCells
  getStateSelectedColumns: () => StateSelectedColumns
  refScrollingData: MutableRefObject<{
    scrollToPx?: (y: number, x: number) => void
  }>
  refCellsOptions: MutableRefObject<{
    onKeyDown: ((event: KeyboardEvent) => Promise<(number | null)[][] | undefined>)[][]
  }>
}

export const useSelectCells = <State extends ExcelState = ExcelState>({
  refWrap,
  refColumns,
  virtualScrollState,
  tableState,
  getState,
  getStateSelectedCells,
  getStateSelectedColumns,
  refScrollingData,
  refCellsOptions,
}: UseSelectCellsProps<State>) => {
  const widthDivider = 6
  const refPositionWrap = useRef({ top: 0, left: 0 })
  const refSizeWrap = useRef({ height: 0, width: 0 })
  const refColumnPositions = useRef<Record<'fixed' | 'cells', number[]>>({ fixed: [], cells: [] })
  const refSelectStarted = useRef(false)
  const refCurrentMove = useRef<Record<'pageY' | 'pageX', number | null>>({ pageY: null, pageX: null })
  const refMoveForScroll = useRef({ toBottom: false, toLeft: false, toTop: false, toRight: false })
  const refPositionCells = useRef<SelectedCells>({
    fixed: {
      columns: { start: -1, end: -1 },
      rows: { start: -1, end: -1 },
    },
    cells: {
      columns: { start: -1, end: -1 },
      rows: { start: -1, end: -1 },
    },
  })

  const onBlur = useCallback((event: MouseEvent) => {
    if (event.target === document.querySelector('html')) {
      return
    }
    getStateSelectedCells()?.clearSelectedCells()
    getStateSelectedColumns()?.clearSelectedColumns()
  }, [])

  const select = () => {
    if (!refCurrentMove.current.pageY || !refCurrentMove.current.pageX) {
      return
    }
    const top = refCurrentMove.current.pageY - refPositionWrap.current.top
    const left = refCurrentMove.current.pageX - refPositionWrap.current.left
    const topWithScroll = top + (virtualScrollState.position.startY || 0)
    const leftWithScroll = left + (virtualScrollState.position.startX || 0)

    const state = getState()
    const stateSelectedCells = getStateSelectedCells()
    const getFixedWidthByKey = tableState.storageWidths?.getFixedWidthByKey
    const fixedColumns = state.data?.[1]?.rows[0] || []

    const fixedSum = fixedColumns.reduce((sum, current) => {
      const width = getFixedWidthByKey?.(current.code) || tableState.defaultWidthColumn
      return sum + width
    }, 0)

    refPositionCells.current.fixed.columns.end = findLastIndex(
      refColumnPositions.current.fixed,
      (position) => left >= position && left < refColumnPositions.current.cells[0] - widthDivider,
    )
    refPositionCells.current.cells.columns.end = findLastIndex(
      refColumnPositions.current.cells,
      (position) => left >= fixedSum && leftWithScroll >= position,
    )

    const clonePositionCells = cloneDeep(refPositionCells.current)

    if (refPositionCells.current.fixed.columns.end > -1 && refPositionCells.current.fixed.columns.start === -1) {
      clonePositionCells.fixed.columns.start = refColumnPositions.current.fixed.length - 1
    }
    if (refPositionCells.current.cells.columns.end > -1 && refPositionCells.current.cells.columns.start === -1) {
      clonePositionCells.cells.columns.start = 0
    }
    if (refPositionCells.current.fixed.columns.start > -1 && refPositionCells.current.fixed.columns.end === -1) {
      clonePositionCells.fixed.columns.end = refColumnPositions.current.fixed.length - 1
    }
    if (refPositionCells.current.cells.columns.start > -1 && refPositionCells.current.cells.columns.end === -1) {
      clonePositionCells.cells.columns.end = 0
    }

    if (clonePositionCells.fixed.columns.start > -1 && clonePositionCells.fixed.columns.end > -1) {
      clonePositionCells.fixed.rows.end = Math.floor(topWithScroll / tableState.heightRow)
      if (clonePositionCells.fixed.rows.start === -1) {
        clonePositionCells.fixed.rows.start = clonePositionCells.cells.rows.start
      }
    } else {
      clonePositionCells.fixed.rows = { start: -1, end: -1 }
    }
    if (clonePositionCells.cells.columns.start > -1 && clonePositionCells.cells.columns.end > -1) {
      clonePositionCells.cells.rows.end = Math.floor(topWithScroll / tableState.heightRow)
      if (clonePositionCells.cells.rows.start === -1) {
        clonePositionCells.cells.rows.start = clonePositionCells.fixed.rows.start
      }
    } else {
      clonePositionCells.cells.rows = { start: -1, end: -1 }
    }

    const countY = tableState.getState?.().countY
    if (countY && clonePositionCells.cells.rows.end >= countY) {
      clonePositionCells.cells.rows.end = countY - 1
    }
    if (countY && clonePositionCells.fixed.rows.end >= countY) {
      clonePositionCells.fixed.rows.end = countY - 1
    }

    if (!isEqual(clonePositionCells, stateSelectedCells.selectedCells)) {
      stateSelectedCells.setSelectedCells(clonePositionCells, state)
    }
  }

  const { enableInterval, disableInterval, isEnableInterval } = useInterval(() => {
    refScrollingData.current.scrollToPx?.(
      (virtualScrollState.position.startY || 0) +
        (refMoveForScroll.current.toBottom ? 10 : refMoveForScroll.current.toTop ? -10 : 0),
      (virtualScrollState.position.startX || 0) +
        (refMoveForScroll.current.toLeft ? 10 : refMoveForScroll.current.toRight ? -10 : 0),
    )
    select()
  }, 16)

  const onMouseUp = useCallback(() => {
    refSelectStarted.current = false
    tableState.isSelecting = false
    disableInterval()
    refMoveForScroll.current = { toBottom: false, toLeft: false, toTop: false, toRight: false }
  }, [])

  const onMouseDown = useCallback((event: MouseEvent) => {
    if (!refWrap.current || !refColumns.current) {
      return
    }

    const target = event.target as HTMLDivElement
    if (
      target.getAttribute('data-scroll-element') === 'true' ||
      target.closest('[data-scroll-element=true]') ||
      target.getAttribute('data-not-selected') === 'true' ||
      target.closest('[data-not-selected=true]') ||
      target.getAttribute('data-resizer') === 'true'
    ) {
      return
    }
    if (refWrap.current.getAttribute('data-is-scrolling') === 'true') {
      return
    }

    getStateSelectedColumns()?.clearSelectedColumns()

    refSelectStarted.current = true
    tableState.isSelecting = true

    if (event.shiftKey) {
      refCurrentMove.current = { pageY: event.pageY, pageX: event.pageX }
      return select()
    }

    const rectWrap = refWrap.current.getBoundingClientRect()
    refPositionWrap.current = { top: rectWrap.top + refColumns.current.offsetHeight, left: rectWrap.left }
    refSizeWrap.current = { height: rectWrap.height - refColumns.current.offsetHeight, width: rectWrap.width }

    const top = event.pageY - refPositionWrap.current.top
    const left = event.pageX - refPositionWrap.current.left
    const topWithScroll = top + (virtualScrollState.position.startY || 0)
    const leftWithScroll = left + (virtualScrollState.position.startX || 0)

    const getFixedWidthByKey = tableState.storageWidths?.getFixedWidthByKey
    const getWidth = tableState.storageWidths?.getWidth

    const state = getState()
    const stateSelectedCells = getStateSelectedCells()
    const fixedColumns = state.data?.[1]?.rows[0] || []

    const showChartIcon = isShowChartIcon(tableState)

    const fixedSum = fixedColumns.reduce(
      (sum, current, index) => {
        const width = getFixedWidthByKey?.(current.code) || tableState.defaultWidthColumn
        refColumnPositions.current.fixed[index] = sum
        return sum + width
      },
      showChartIcon ? 32 : 0,
    )

    const columns =
      (state.paginateAxis === 'x'
        ? Object.values(state.data ?? {}).map((page) => page?.cells[0])
        : state.data?.[1]?.cells[0]) || []

    columns.reduce((sum, _, index) => {
      const width = getWidth?.(index) || tableState.defaultWidthColumn
      refColumnPositions.current.cells[index] = sum
      return sum + width
    }, fixedSum)

    refPositionCells.current.fixed.columns.start = findLastIndex(
      refColumnPositions.current.fixed,
      (position) => left >= position && left < refColumnPositions.current.cells[0] - widthDivider,
    )
    refPositionCells.current.cells.columns.start = findLastIndex(
      refColumnPositions.current.cells,
      (position) => left >= fixedSum && leftWithScroll >= position,
    )
    refPositionCells.current.fixed.columns.end = refPositionCells.current.fixed.columns.start
    refPositionCells.current.cells.columns.end = refPositionCells.current.cells.columns.start

    refPositionCells.current.fixed.rows.start =
      refPositionCells.current.fixed.columns.start > -1 && refPositionCells.current.fixed.columns.end > -1
        ? Math.floor(topWithScroll / tableState.heightRow)
        : -1
    refPositionCells.current.cells.rows.start =
      refPositionCells.current.cells.columns.start > -1 && refPositionCells.current.cells.columns.end > -1
        ? Math.floor(topWithScroll / tableState.heightRow)
        : -1
    refPositionCells.current.fixed.rows.end = refPositionCells.current.fixed.rows.start
    refPositionCells.current.cells.rows.end = refPositionCells.current.cells.rows.start

    if (!isEqual(refPositionCells.current, stateSelectedCells.selectedCells)) {
      stateSelectedCells.setSelectedCells(cloneDeep(refPositionCells.current), state)
    }
  }, [])

  const onMouseMove = useCallback((event: MouseEvent) => {
    if (event.buttons !== 1 || !refSelectStarted.current) {
      return
    }
    refCurrentMove.current = { pageY: event.pageY, pageX: event.pageX }
    const top = event.pageY - refPositionWrap.current.top
    const left = event.pageX - refPositionWrap.current.left
    refMoveForScroll.current.toBottom = top > refSizeWrap.current.height - 40
    refMoveForScroll.current.toLeft = left > refSizeWrap.current.width - 40
    refMoveForScroll.current.toTop = top < 40
    refMoveForScroll.current.toRight = left < 40

    if (
      refMoveForScroll.current.toBottom ||
      refMoveForScroll.current.toLeft ||
      refMoveForScroll.current.toTop ||
      refMoveForScroll.current.toRight
    ) {
      enableInterval()
    } else if (isEnableInterval()) {
      disableInterval()
    }

    const target = event.target as HTMLDivElement

    if (
      !refSelectStarted.current &&
      (target.getAttribute('data-scroll-element') === 'true' || target.closest('[data-scroll-element=true]'))
    ) {
      return
    }
    if (!refSelectStarted.current && refWrap.current?.getAttribute('data-is-scrolling') === 'true') {
      return
    }

    select()
  }, [])

  const onKeyDown = useCallback(async (event: KeyboardEvent) => {
    const state = getState()
    const stateSelectedCells = getStateSelectedCells()
    const selectedCells = stateSelectedCells.selectedCells
    const paginateAxis = state.paginateAxis
    const perPage = state.perPage

    if (!perPage) {
      return
    }

    if (event.code === 'KeyC' && document.activeElement === refWrap.current) {
      const rowStart = Math.max(
        Math.min(selectedCells.fixed.rows.start, selectedCells.fixed.rows.end),
        Math.min(selectedCells.cells.rows.start, selectedCells.cells.rows.end),
      )
      const rowEnd = Math.max(
        Math.max(selectedCells.fixed.rows.start, selectedCells.fixed.rows.end),
        Math.max(selectedCells.cells.rows.start, selectedCells.cells.rows.end),
      )
      const fixedColumnStart = Math.min(selectedCells.fixed.columns.start, selectedCells.fixed.columns.end)
      const fixedColumnEnd = Math.max(selectedCells.fixed.columns.start, selectedCells.fixed.columns.end)
      const columnStart = Math.min(selectedCells.cells.columns.start, selectedCells.cells.columns.end)
      const columnEnd = Math.max(selectedCells.cells.columns.start, selectedCells.cells.columns.end)

      let text = ''

      for (let row = rowStart; row <= rowEnd; row++) {
        if (fixedColumnStart > -1 && fixedColumnEnd > -1) {
          const page = paginateAxis === 'x' ? 1 : Math.floor(row / perPage) + 1
          const indexRow = paginateAxis === 'x' ? row : row - (page - 1) * perPage
          for (let fixedColumn = fixedColumnStart; fixedColumn <= fixedColumnEnd; fixedColumn++) {
            text +=
              (state.data?.[page]?.rows[indexRow]?.[fixedColumn]?.value ?? '') +
              (fixedColumn < fixedColumnEnd ? '\t' : '')
          }
        }
        if (columnStart > -1 && columnEnd > -1) {
          if (fixedColumnStart > -1 && fixedColumnEnd > -1) {
            text += '\t'
          }
          for (let column = columnStart; column <= columnEnd; column++) {
            const page = paginateAxis === 'x' ? Math.floor(column / perPage) + 1 : Math.floor(row / perPage) + 1
            const indexRow = paginateAxis === 'x' ? row : row - (page - 1) * perPage
            const indexColumn = paginateAxis === 'y' ? column : column - (page - 1) * perPage
            text += (state.data?.[page]?.cells[indexRow]?.[indexColumn]?.value ?? '') + (column < columnEnd ? '\t' : '')
          }
        }
        if (row < rowEnd) {
          text += '\n'
        }
      }
      return copy(text)
    } else if (event.code === 'ArrowDown' || event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault()
      refWrap.current?.focus()
      const fixedColumnStart = Math.min(selectedCells.fixed.columns.start, selectedCells.fixed.columns.end)
      const columnStart = Math.min(selectedCells.cells.columns.start, selectedCells.cells.columns.end)
      const rowEnd = Math.max(
        Math.max(selectedCells.fixed.rows.start, selectedCells.fixed.rows.end),
        Math.max(selectedCells.cells.rows.start, selectedCells.cells.rows.end),
      )
      let newRow = rowEnd + 1
      if (newRow > (state.countY || 0) - 1) {
        newRow = (state.countY || 0) - 1
      }

      if (fixedColumnStart > -1) {
        stateSelectedCells.setSelectedCells(
          {
            fixed: {
              columns: { start: fixedColumnStart, end: fixedColumnStart },
              rows: { start: newRow, end: newRow },
            },
            cells: {
              columns: { start: -1, end: -1 },
              rows: { start: -1, end: -1 },
            },
          },
          state,
        )
      } else if (columnStart > -1) {
        stateSelectedCells.setSelectedCells(
          {
            fixed: {
              columns: { start: -1, end: -1 },
              rows: { start: -1, end: -1 },
            },
            cells: {
              columns: { start: columnStart, end: columnStart },
              rows: { start: newRow, end: newRow },
            },
          },
          state,
        )
      }
      if ((virtualScrollState.position.endY || 0) - 40 < (rowEnd + 2) * tableState.heightRow) {
        refScrollingData.current.scrollToPx?.(
          (virtualScrollState.position.startY || 0) + 50,
          virtualScrollState.position.startX || 0,
        )
      }
    } else if (event.code === 'ArrowUp') {
      event.preventDefault()
      refWrap.current?.focus()
      const fixedColumnStart = Math.min(selectedCells.fixed.columns.start, selectedCells.fixed.columns.end)
      const columnStart = Math.min(selectedCells.cells.columns.start, selectedCells.cells.columns.end)
      const rowStart = Math.max(
        Math.min(selectedCells.fixed.rows.start, selectedCells.fixed.rows.end),
        Math.min(selectedCells.cells.rows.start, selectedCells.cells.rows.end),
      )
      let newRow = rowStart - 1
      if (newRow === -1) {
        newRow = 0
      }

      if (fixedColumnStart > -1) {
        stateSelectedCells.setSelectedCells(
          {
            fixed: {
              columns: { start: fixedColumnStart, end: fixedColumnStart },
              rows: { start: newRow, end: newRow },
            },
            cells: {
              columns: { start: -1, end: -1 },
              rows: { start: -1, end: -1 },
            },
          },
          state,
        )
      } else if (columnStart > -1) {
        stateSelectedCells.setSelectedCells(
          {
            fixed: {
              columns: { start: -1, end: -1 },
              rows: { start: -1, end: -1 },
            },
            cells: {
              columns: { start: columnStart, end: columnStart },
              rows: { start: newRow, end: newRow },
            },
          },
          state,
        )
      }

      if ((virtualScrollState.position.startY || 0) + 40 > (rowStart - 1) * tableState.heightRow) {
        refScrollingData.current.scrollToPx?.(
          (virtualScrollState.position.startY || 0) - 50,
          virtualScrollState.position.startX || 0,
        )
      }
    } else if (event.code === 'ArrowLeft') {
      event.preventDefault()
      refWrap.current?.focus()
      const fixedColumnStart = Math.min(selectedCells.fixed.columns.start, selectedCells.fixed.columns.end)
      const columnStart = Math.min(selectedCells.cells.columns.start, selectedCells.cells.columns.end)
      const rowStart = Math.max(
        Math.min(selectedCells.fixed.rows.start, selectedCells.fixed.rows.end),
        Math.min(selectedCells.cells.rows.start, selectedCells.cells.rows.end),
      )
      if (fixedColumnStart > -1) {
        stateSelectedCells.setSelectedCells(
          {
            fixed: {
              columns: {
                start: fixedColumnStart === 0 ? 0 : fixedColumnStart - 1,
                end: fixedColumnStart === 0 ? 0 : fixedColumnStart - 1,
              },
              rows: { start: rowStart, end: rowStart },
            },
            cells: {
              columns: { start: -1, end: -1 },
              rows: { start: -1, end: -1 },
            },
          },
          state,
        )
      } else if (columnStart > 0) {
        stateSelectedCells.setSelectedCells(
          {
            fixed: {
              columns: { start: -1, end: -1 },
              rows: { start: -1, end: -1 },
            },
            cells: {
              columns: { start: columnStart === 0 ? 0 : columnStart - 1, end: columnStart === 0 ? 0 : columnStart - 1 },
              rows: { start: rowStart, end: rowStart },
            },
          },
          state,
        )
      } else {
        const maxIndexFixedColumn = (state.data?.[1]?.rows[0]?.length || 0) - 1
        stateSelectedCells.setSelectedCells(
          {
            fixed: {
              columns: { start: maxIndexFixedColumn, end: maxIndexFixedColumn },
              rows: { start: rowStart, end: rowStart },
            },
            cells: {
              columns: { start: -1, end: -1 },
              rows: { start: -1, end: -1 },
            },
          },
          state,
        )
      }
    } else if (event.code === 'ArrowRight') {
      event.preventDefault()
      refWrap.current?.focus()
      const fixedColumnEnd = Math.max(selectedCells.fixed.columns.start, selectedCells.fixed.columns.end)
      const columnEnd = Math.max(selectedCells.cells.columns.start, selectedCells.cells.columns.end)
      const rowStart = Math.max(
        Math.min(selectedCells.fixed.rows.start, selectedCells.fixed.rows.end),
        Math.min(selectedCells.cells.rows.start, selectedCells.cells.rows.end),
      )
      const maxIndexFixedColumn = (state.data?.[1]?.rows[0]?.length || 0) - 1
      const maxIndexColumn = (state.countX || 0) - 1
      if (fixedColumnEnd > -1 && fixedColumnEnd < maxIndexFixedColumn) {
        stateSelectedCells.setSelectedCells(
          {
            fixed: {
              columns: { start: fixedColumnEnd + 1, end: fixedColumnEnd + 1 },
              rows: { start: rowStart, end: rowStart },
            },
            cells: {
              columns: { start: -1, end: -1 },
              rows: { start: -1, end: -1 },
            },
          },
          state,
        )
      } else if (columnEnd > -1) {
        stateSelectedCells.setSelectedCells(
          {
            fixed: {
              columns: { start: -1, end: -1 },
              rows: { start: -1, end: -1 },
            },
            cells: {
              columns: {
                start: columnEnd === maxIndexColumn ? maxIndexColumn : columnEnd + 1,
                end: columnEnd === maxIndexColumn ? maxIndexColumn : columnEnd + 1,
              },
              rows: { start: rowStart, end: rowStart },
            },
          },
          state,
        )
      } else {
        stateSelectedCells.setSelectedCells(
          {
            fixed: {
              columns: { start: -1, end: -1 },
              rows: { start: -1, end: -1 },
            },
            cells: {
              columns: { start: 0, end: 0 },
              rows: { start: rowStart, end: rowStart },
            },
          },
          state,
        )
      }
    }

    const indexColumn = Math.min(selectedCells.cells.columns.start, selectedCells.cells.columns.end)
    const indexRow = Math.min(selectedCells.cells.rows.start, selectedCells.cells.rows.end)
    const maxIndexColumn = paginateAxis === 'x' ? (state.countX || 0) - 1 : (state.data?.[1]?.columns.length || 0) - 1
    const maxIndexRow = paginateAxis === 'y' ? (state.countY || 0) - 1 : (state.data?.[1]?.rows.length || 0) - 1

    const cells = await refCellsOptions.current.onKeyDown[indexRow]?.[indexColumn]?.(event)
    refWrap.current?.focus()
    const positions = cloneDeep(selectedCells)

    if (positions.cells.columns.end === -1 || !cells) {
      return
    }

    if (positions.cells.columns.end >= positions.cells.columns.start) {
      positions.cells.columns.end = Math.min(
        positions.cells.columns.start + (cells?.[0]?.length || 1) - 1,
        maxIndexColumn,
      )
    } else {
      positions.cells.columns.start = Math.min(
        positions.cells.columns.end + (cells?.[0]?.length || 1) - 1,
        maxIndexColumn,
      )
    }
    if (positions.cells.rows.end >= positions.cells.rows.start) {
      positions.cells.rows.end = Math.min(positions.cells.rows.start + (cells?.length || 1) - 1, maxIndexRow)
      positions.fixed.rows.end = positions.cells.rows.end
    } else {
      positions.cells.rows.start = Math.min(positions.cells.rows.end + (cells?.length || 1) - 1, maxIndexRow)
      positions.fixed.rows.start = positions.cells.rows.start
    }
    stateSelectedCells.setSelectedCells(positions, state)
  }, [])

  useClickAway(onBlur, refWrap.current)

  useEffect(() => {
    refWrap.current?.addEventListener('mousemove', onMouseMove)
    refWrap.current?.addEventListener('mousedown', onMouseDown)
    refWrap.current?.addEventListener('mouseup', onMouseUp)
    refWrap.current?.addEventListener('keydown', onKeyDown)

    return () => {
      refWrap.current?.removeEventListener('mousemove', onMouseMove)
      refWrap.current?.removeEventListener('mousedown', onMouseDown)
      refWrap.current?.removeEventListener('mouseup', onMouseUp)
      refWrap.current?.removeEventListener('keydown', onKeyDown)
    }
  }, [refWrap.current])
}
