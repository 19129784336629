import { devtools } from 'packages/core'
import { create } from 'zustand'

import { UseLoadingUpdatedForecast } from './types'

export const useLoadingUpdatedForecast = create<UseLoadingUpdatedForecast>()(
  devtools(
    (set) => ({
      loading: false,
      set: (loading) => set({ loading }),
      clear: () => set({ loading: false }),
    }),
    {
      store: 'loadingUpdatedForecast',
    },
  ),
)
