import { Streams } from 'constants/streams'
import { jobLockEndpoints } from 'demand/jobLock/api/endpoints'
import { axios, GetOneResponse } from 'packages/core'

import { JobLockStatus } from './interfaces'

const getJobLockStatus = async (): GetOneResponse<JobLockStatus> => {
  const { data } = await axios.request<JobLockStatus>({
    method: 'GET',
    ...jobLockEndpoints.getJobLockStatus(),
    tracing: {
      stream: Streams.Demand,
      module: 'jobLock',
      methodName: 'getJobLockStatus',
    },
  })
  return data
}

export const jobLockApi = {
  getJobLockStatus,
}
