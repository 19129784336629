import { ChartDataSeriesOptions } from 'canvasjs'
import { colors } from 'constants/colors'
import { CanvasJsChartOptions } from 'packages/ui/Chart'

import { tooltipContent } from './tooltipContent'

interface Params {
  viewportMinimum?: number
  viewportMaximum?: number
}

export const createChartOptions = (data: ChartDataSeriesOptions[], params?: Params): CanvasJsChartOptions => ({
  rangeSelector: {
    enabled: false,
  },
  height: 400,
  charts: [
    {
      axisX: {
        gridColor: colors.grid,
        tickColor: colors.grid,
        lineColor: colors.grid,
        tickLength: 0,
        labelFontColor: colors.labels,
        labelFontFamily: '"Montserrat", sans-serif',
        labelFontSize: 12,
        labelFontWeight: '500',
        viewportMinimum: params?.viewportMinimum,
        viewportMaximum: params?.viewportMaximum,
      },
      axisY: {
        gridColor: colors.grid,
        tickColor: colors.grid,
        lineColor: colors.grid,
        tickLength: 0,
        labelFontColor: colors.labels,
        labelFontFamily: '"Montserrat", sans-serif',
        labelFontSize: 12,
        labelFontWeight: '500',
      },
      toolTip: {
        shared: true,
        borderThickness: 0,
        backgroundColor: colors.black,
        cornerRadius: 8,
        fontFamily: '"Montserrat", sans-serif',
        fontSize: 13,
        fontColor: '#ffffff',
        fontWeight: '500',
        content: tooltipContent(),
      },
      height: 375,
      data,
    },
  ],
  navigator: {
    data,
    enabled: false,
    verticalAlign: 'top',
    slider: {
      outlineInverted: true,
      maskColor: colors.grid,
      maskOpacity: 0.5,
      outlineColor: colors.outlineColor,
      handleBorderColor: colors.handleBorderColor,
    },
    height: 0,
    axisX: {
      gridColor: colors.grid,
      tickColor: colors.grid,
      lineColor: colors.grid,
      tickLength: 0,
      labelFontColor: colors.labels,
      labelFontFamily: '"Montserrat", sans-serif',
      labelFontSize: 12,
      labelFontWeight: '500',
      labelPlacement: 'outside',
      viewportMinimum: params?.viewportMinimum,
      viewportMaximum: params?.viewportMaximum,
    },
    axisY: {
      visible: false,
    },
  },
})
