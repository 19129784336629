import { FC, memo, useRef } from 'react'

import cx from 'clsx'
import { FilterItem, OptionTreeItem } from 'interfaces/components.interfaces'
import { Select, SelectSizes } from 'packages/ui/Select'

import classes from './Filter.module.scss'
import { getClassNameFilterItem } from './getClassNameFilterItem'

interface FilterProps {
  className?: string
  allFilters?: FilterItem[] | null
  filter?: Record<string, string | number | (string | number)[]>
  onChange?: (
    key: string,
    options: OptionTreeItem[] | undefined,
  ) => (value: string | number | (string | number)[], optionsChecked?: OptionTreeItem[]) => void
  closeSelectTrigger?: any
  onDropdownVisibleChange?: (value: boolean, id?: number) => void
  setParentId?: (id: number) => void
  loading?: boolean
  onSearch?: (inputValue: string) => void
  isFilterSingle?: boolean
  isFilterSmall?: boolean
  actualSearchValue?: string
  selectedParentIds?: Record<number, number[]>
  selectedElementsNames?: Record<number, Record<number, string>>
}

export const Filter: FC<FilterProps> = memo(
  ({
    className,
    allFilters = [],
    filter,
    onChange,
    closeSelectTrigger,
    onDropdownVisibleChange,
    setParentId,
    loading,
    onSearch,
    isFilterSingle,
    isFilterSmall,
    actualSearchValue,
    selectedParentIds,
    selectedElementsNames,
  }) => {
    const refFilter = useRef<HTMLDivElement>(null)

    return (
      <div
        className={cx(classes.wrap, className, { [classes.single]: isFilterSingle, [classes.small]: isFilterSmall })}
        ref={refFilter}
      >
        {allFilters?.map((item, index) => (
          <Select
            actualSearchValue={actualSearchValue}
            className={cx(classes.selectWrap, getClassNameFilterItem(index, allFilters?.length))}
            classNameSelect={classes.select}
            closeSelectTrigger={closeSelectTrigger}
            defaultIsReset={item.defaultIsReset}
            defaultValue={item.defaultValue}
            dropdownClassName={classes[`dropdown${(index + 1) % 3}`]}
            getPopupContainer={() => refFilter.current}
            isSelectWithChildren
            isTree={item.isTree}
            key={item.name === 'year' ? item.name : item.id}
            label={item.label}
            labelWithItems
            loading={loading}
            multiple={item.multiple}
            offsetLeft={index === allFilters.length - 1 ? 2 : 0}
            onChange={onChange?.(item.name === 'year' ? item.name : (item.id?.toString() as string), item.options)}
            onDropdownVisibleChange={(value) => onDropdownVisibleChange?.(value, item?.id)}
            onOpenTree={(key) => setParentId?.(Number(key))}
            onSearch={onSearch}
            options={item.options}
            searchInDropdown={item.showSearch}
            selectedElementsNames={item.id ? selectedElementsNames?.[item.id] : undefined}
            selectedParentIds={selectedParentIds}
            showApplyButton={item.name !== 'year'}
            showMultipleLabel
            showSearch={item.showSearch}
            size={isFilterSmall ? SelectSizes.Small : SelectSizes.Large}
            value={filter?.[item.name === 'year' ? item.name : (item.id?.toString() as string)]}
          />
        ))}
      </div>
    )
  },
)
