import { storageAccessTokenName, storageRefreshTokenName } from 'packages/core/auth/authStorageNames'

import { Tokens } from './types'

export const getTokens = (): Tokens => {
  const accessTokenLocalStorage = window.localStorage.getItem(storageAccessTokenName)
  const accessTokenSessionStorage = window.sessionStorage.getItem(storageAccessTokenName)

  const refreshTokenLocalStorage = window.localStorage.getItem(storageRefreshTokenName)
  const refreshTokenSessionStorage = window.sessionStorage.getItem(storageRefreshTokenName)

  const accessToken = accessTokenLocalStorage ? accessTokenLocalStorage : accessTokenSessionStorage
  const refreshToken = refreshTokenLocalStorage ? refreshTokenLocalStorage : refreshTokenSessionStorage

  return {
    accessToken,
    refreshToken,
  }
}
