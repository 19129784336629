import { Streams } from 'constants/streams'
import { ModuleConnector } from 'packages/app'
import { t } from 'packages/localization'

export const prgmDataHubPromoModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    dataHub: {
      label: () => t('prgm.navMenu.dataHub'),
      href: `/${Streams.Prgm}/data-hub`,
      dependence: (appContext) =>
        appContext.modules.permissions?.readDatahubDb || appContext.modules.permissions?.editDatahubDb,
    },
  },
})
