import { useLatest } from 'ahooks'
import { AppState } from 'packages/app/appState'
import { ApplicationConfig } from 'packages/core'

export const useSessionClearFunctions = (applicationConfig: ApplicationConfig, appState: AppState) =>
  useLatest(
    [
      ...Object.values(applicationConfig?.streams || {}).map((stream) =>
        stream.session?.clearStreamDataHook?.(appState),
      ),
      applicationConfig.session?.clearCommonDataHook?.(appState),
    ].filter(Boolean),
  )
