import { MutableRefObject } from 'react'

import { TableState, VirtualScrollPosition, VirtualScrollState } from 'interfaces/excelTable.interfaces'
import { isEqual } from 'lodash'
import { removeElementFromArray } from 'packages/helper'

export const getSliceColumns = (
  virtualScroll: VirtualScrollState,
  tableState: TableState,
  indexRow: number,
  pageX: number,
  getDepthColumns: () => number,
  onChange?: (position: VirtualScrollPosition) => void,
  refRemoveOnChange?: MutableRefObject<() => void>,
): [number, number] => {
  const overscan = 500
  if (onChange) {
    virtualScroll.onChangeFns.push(onChange)
  }

  if (refRemoveOnChange) {
    refRemoveOnChange.current = () => removeElementFromArray(virtualScroll.onChangeFns, onChange)
  }

  const slice: [number, number] = [-1, -1]
  const columns = tableState.columnsKeys[pageX]?.[indexRow]
  const indexes = Object.keys(columns)
    .map((key) => Number(key))
    .sort((a, b) => a - b)

  let lastIndex = -1

  for (let i = 0; i <= indexes[indexes.length - 1]; i++) {
    const key = columns[i]
    if (!key) {
      continue
    }
    lastIndex = lastIndex + 1
    const left = tableState.columns[key]?.position.left || 0
    const initWidth = tableState.columns[key]?.initWidth || 0
    if (left + initWidth < (virtualScroll.position.startX || 0) - overscan) {
      continue
    }
    if (slice[0] === -1) {
      slice[0] = lastIndex
      slice[1] = lastIndex
    } else if (
      left - (virtualScroll.position.startX || 0) <=
      (virtualScroll.position.endX || document.body.offsetWidth) + overscan
    ) {
      slice[1] = lastIndex + 1
    } else {
      break
    }
  }

  if (slice[1] === -1) {
    slice[0] = -1
  }

  if (!tableState.currentSlices[pageX]) {
    tableState.currentSlices[pageX] = {}
  }
  tableState.currentSlices[pageX][indexRow] = slice

  if (indexRow === getDepthColumns() - 1 && !isEqual(tableState.currentSliceX[pageX], slice)) {
    tableState.currentSliceX[pageX] = slice
    tableState.onChangeSliceX[pageX]?.forEach((fn) => fn(slice))
    if (!tableState.onChangeSliceX[pageX]) {
      tableState.onChangeSliceX[pageX] = []
    }
  }

  return slice
}
