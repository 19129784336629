import { MenuSelects } from 'packages/app/menu'
import { AppModulesState } from 'packages/app/modules'
import { LogoutMenuItem } from 'packages/app/modules/logout'
import { ProjectSelect } from 'packages/app/modules/projects'
import { StreamSelect } from 'packages/app/modules/streams'
import { MenuCreator } from 'packages/core'

export const defaultMenuCreator: MenuCreator<AppModulesState> = (appContext) => {
  if (appContext.menuBuilder) {
    const currentStream = appContext.modules.stream.currentStream

    appContext.menuBuilder.addMenu((builder) => {
      builder.addItem({
        component: (
          <MenuSelects>
            <StreamSelect />
            {currentStream?.projects?.enabled && <ProjectSelect />}
          </MenuSelects>
        ),
      })
    })

    if (currentStream) {
      appContext.menuBuilder.addMenu((builder) => {
        appContext.modules.streams[currentStream.name]?.menu?.(builder)
      })
    }

    if (appContext.commonBottomMenu) {
      appContext.menuBuilder.addMenu(appContext.commonBottomMenu)
    }

    appContext.menuBuilder.addMenu((builder) => {
      builder.addItem({ component: <LogoutMenuItem appContext={appContext} /> })
    })
  }
}
