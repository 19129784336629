import { EffectFn, EffectHook, EffectHookWithFn, EffectOptions, Hook } from 'packages/core/effects/types'

export const isEffectFn = <Args extends any[] = any[], H extends Hook = Hook>(
  options: EffectOptions<Args, H>,
): options is EffectFn<Args> => 'fn' in options

export const isEffectHook = <Args extends any[] = any[], H extends Hook = Hook>(
  options: EffectOptions<Args, H>,
): options is EffectHook<H> => 'hook' in options && !('fnName' in options)

export const isEffectHookWithFn = <Args extends any[] = any[], H extends Hook = Hook>(
  options: EffectOptions<Args, H>,
): options is EffectHookWithFn<H> => 'hook' in options && 'fnName' in options
