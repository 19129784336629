import { Dispatch, memo, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef } from 'react'

import { useDeepCompareEffect, useGetState } from 'ahooks'
import cx from 'clsx'
import { SubscribeColumnCell } from 'interfaces/excelTable.interfaces'
import { isEqual } from 'lodash'

import { ExcelTableContext } from './context/ExcelTableContext'
import { ExcelColumnCell } from './ExcelColumnCell'
import classes from './ExcelTable.module.scss'
import { getSliceColumns } from './helpers/getSliceColumns'

interface ExcelColumnsRowProps {
  isCommonColumn: boolean
  indexRow: number
  columns: SubscribeColumnCell[]
  setWidth: (keys: string[], width: number) => void
  page: number
  getDepthColumns: () => number
  ready: boolean
  disableSelect?: boolean
}

export const ExcelColumnsRow = memo(
  ({
    isCommonColumn,
    indexRow,
    columns,
    setWidth,
    page,
    getDepthColumns,
    ready,
    disableSelect,
  }: ExcelColumnsRowProps) => {
    const { tableState, virtualScroll } = useContext(ExcelTableContext)
    const refRemoveOnChange = useRef<() => void>(() => {})
    const refCb = useRef<Dispatch<SetStateAction<[number, number]>>>()
    const refGetSlice = useRef<() => [number, number]>()

    const calculateSlice = useCallback(() => {
      const newSlice = getSliceColumns(virtualScroll, tableState, indexRow, page, getDepthColumns)
      if (!isEqual(refGetSlice.current?.(), newSlice)) {
        refCb.current?.(newSlice)
      }
    }, [virtualScroll, indexRow])

    const initSlice = useMemo(
      () => getSliceColumns(virtualScroll, tableState, indexRow, page, getDepthColumns, calculateSlice),
      [virtualScroll, indexRow],
    )
    const [slice, setSlice, getSlice] = useGetState<[number, number]>(initSlice)

    const slicedColumns = columns.slice(slice[0], slice[1] + 1)

    useEffect(() => {
      refCb.current = setSlice
      refGetSlice.current = getSlice
    }, [setSlice, getSlice])

    useDeepCompareEffect(() => {
      setSlice(initSlice)
    }, [initSlice])

    useEffect(() => () => refRemoveOnChange.current(), [])

    return (
      <div
        className={cx('flex', { [classes.hearer]: !isCommonColumn, [classes.hearerLight]: isCommonColumn })}
        style={{
          height: tableState.heightRow,
          position: 'absolute',
          left: 0,
          top: (indexRow + Number(isCommonColumn)) * tableState.heightRow,
          transform: `translateX(${slicedColumns[0]?.getPosition?.().left || 0}px)`,
        }}
      >
        {slicedColumns.map((column) => {
          if (!column) {
            return null
          }

          return (
            <ExcelColumnCell
              column={column}
              disableLeftResize={column.index === 0}
              disableSelect={disableSelect}
              initWidth={
                column.getWidth?.() ||
                column.item?.initWidth ||
                tableState.storageWidths?.getWidth(column.index) ||
                tableState.defaultWidthColumn
              }
              key={column.key}
              onResizing={() => {
                calculateSlice()
                tableState.recalculateColumnsLeft?.()
              }}
              ready={ready}
              setWidth={(width, keys) => {
                if (column?.key || keys) {
                  setWidth(keys ?? [column?.key as string], width)
                }
                if (!keys) {
                  const sumPrevWidths =
                    tableState.storageWidths?.getSumWidths(
                      column.index,
                      column.lastIndex - 1,
                      tableState.defaultWidthColumn,
                    ) || 0
                  tableState.storageWidths?.setWidth(column.lastIndex, width - sumPrevWidths)
                }
              }}
            />
          )
        })}
      </div>
    )
  },
)
