import { devtools } from 'packages/core'
import { RowIdNotNullable } from 'packages/ui/Table/types'
import { PnlRow } from 'prgm/plTable/interfaces'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const mappedGroups: Record<string, string[]> = {
  group1: ['tt_on'],
  group2: ['promo_off', 'tt_off'],
  group3: ['var_log_cost', 'material_cost', 'var_ind_cost', 'var_cogs'],
  group4: ['fix_ind_cost', 'fix_cogs', 'fix_log_cost', 'total_cogs'],
  group5: ['var_cogs_kg', 'total_cogs_kg'],
}

interface UsePnlTable {
  rows: PnlRow[]
  open: boolean
  expandedRows: RowIdNotNullable[]
  setExpandedRows: (expandedRows: RowIdNotNullable[]) => void
  setOpen: (open: boolean) => void
  setPnlTableRows: (rows: PnlRow[]) => void
  clearPnlTable: () => void
  clearRows: () => void
}

export const usePnlTable = create<UsePnlTable>()(
  devtools(
    persist(
      (set) => ({
        rows: [],
        open: true,
        expandedRows: [],
        setExpandedRows: (expandedRows) => set({ expandedRows }),
        setOpen: (open) => set({ open }),
        setPnlTableRows: (rows) => set({ rows }),
        clearRows: () => set({ rows: [] }),
        clearPnlTable: () => set({ rows: [], expandedRows: [] }),
      }),
      {
        name: 'prgmPnlTable',
      },
    ),
    {
      store: 'prgmPnlTable',
    },
  ),
)
