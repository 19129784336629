import { FC, memo, ReactNode, useEffect, useRef, useState } from 'react'

import { useSafeState, useThrottleEffect } from 'ahooks'
import cx from 'clsx'

import classes from './SlideToggle.module.scss'

export enum SlideToggleTypes {
  Default = 'default',
  NoTransform = 'noTransform',
}

interface SlideToggleProps {
  className?: string
  children?: ReactNode
  classNameWrap?: string
  classNameHide?: string
  type?: SlideToggleTypes
  open?: boolean
  duration?: number
  showDelay?: number
}

export const SlideToggle: FC<SlideToggleProps> = memo(
  ({
    className,
    children,
    classNameWrap,
    classNameHide,
    type = SlideToggleTypes.Default,
    open = true,
    duration = 200,
    showDelay,
  }) => {
    const [openInternal, setOpenInternal] = useSafeState(true)
    const [height, setHeight] = useSafeState<number | 'auto'>('auto')
    const delay = 20
    let ready = false

    const ref = useRef<HTMLDivElement>(null)
    const [isFirst, setIsFirst] = useState(true)

    const isHide = !openInternal || (isFirst && !open)

    useThrottleEffect(
      () => {
        let s1: NodeJS.Timeout
        let s2: NodeJS.Timeout
        if (!isFirst && ref.current && (height === 'auto' || !ready)) {
          if (!open) {
            setHeight(ref.current.offsetHeight)
            window.requestAnimationFrame(() => setOpenInternal(false))
            window.requestAnimationFrame(() => {
              if (duration) {
                s1 = setTimeout(() => {
                  setHeight('auto')
                  ready = true
                }, duration)
              } else {
                setHeight('auto')
                ready = true
              }
            })
          } else if (ref.current.classList.contains(classes.hide)) {
            ref.current.style.height = 'auto'
            ref.current.classList.remove(classes.hide)
            setHeight(ref.current.offsetHeight)
            ref.current.classList.add(classes.hide)
            const show = () => {
              window.requestAnimationFrame(() => setOpenInternal(true))
              window.requestAnimationFrame(() => {
                if (duration) {
                  s2 = setTimeout(() => {
                    setHeight('auto')
                    ready = true
                  }, duration)
                } else {
                  setHeight('auto')
                  ready = true
                }
              })
            }
            if (showDelay) {
              setTimeout(show, showDelay)
            } else {
              show()
            }
          }
        }

        return () => {
          clearTimeout(s1)
          clearTimeout(s2)
        }
      },
      [open, ref.current, isFirst],
      { wait: duration + delay },
    )

    useEffect(() => {
      if (isFirst && !open) {
        setOpenInternal(false)
      }
    }, [isFirst, open])

    useEffect(() => {
      if (ref.current) {
        setIsFirst(false)
      }
    }, [!!ref.current])

    return (
      <div
        className={cx(
          className,
          classes[type],
          { [classes.hide]: isHide },
          classNameHide && { [classNameHide]: isHide },
        )}
        ref={ref}
        style={{ height, transition: `all ${duration}ms ease` }}
      >
        <div className={cx(classes.wrap, classNameWrap)} style={{ transition: `all ${duration}ms ease` }}>
          {children}
        </div>
      </div>
    )
  },
)
