import { FC, memo, useContext, useEffect, useState } from 'react'

import { ExcelTableContext } from './context/ExcelTableContext'
import { ExcelFixedRows } from './ExcelFixedRows'

interface ExcelFixedPagesProps {
  countX: number
  countY: number
  perPage: number
  paginateAxis: 'x' | 'y'
  ready: boolean
}

export const ExcelFixedPages: FC<ExcelFixedPagesProps> = memo(({ countX, countY, perPage, paginateAxis, ready }) => {
  const { tableState } = useContext(ExcelTableContext)
  const [slice, setSlice] = useState([0, 0])
  const pages = Array.from({ length: slice[1] + 1 - slice[0] })

  useEffect(() => tableState.subscribe?.(({ slicePages }) => slicePages, setSlice), [tableState.subscribe])

  return (
    <>
      {pages.map((_, index) => {
        const page = slice[0] + index + 1
        return (
          <ExcelFixedRows
            countX={countX}
            countY={countY}
            initCells={tableState.getState?.().data?.[page]?.rows}
            key={page}
            page={page}
            paginateAxis={paginateAxis}
            perPage={perPage}
            ready={ready}
          />
        )
      })}
    </>
  )
})
