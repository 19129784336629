import { Dispatch, memo, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'

import cx from 'clsx'
import { ExcelAxisItem, SubscribeFixedColumnCell } from 'interfaces/excelTable.interfaces'

import { ExcelTableContext } from './context/ExcelTableContext'
import { ExcelColumnCell } from './ExcelColumnCell'
import classes from './ExcelTable.module.scss'
import { isChartEnabled } from './helpers/isChartEnabled'
import { parsedFixedColumns } from './helpers/parsedFixedColumns'

interface ExcelFixedColumnsProps {
  setWidth: (keys: string[], width: number, isInit?: boolean) => void
  setWidths: (widths: Record<string, number> | null) => void
  getDepth?: () => number
  setDepth: Dispatch<SetStateAction<number>>
  ready: boolean
  initColumns?: ExcelAxisItem[]
}

export const ExcelFixedColumns = memo(
  ({ setWidth, setWidths, getDepth, setDepth, ready, initColumns }: ExcelFixedColumnsProps) => {
    const { tableState } = useContext(ExcelTableContext)
    const showChart = tableState?.getState?.().showChart

    const initializeColumns = (columnsState: ExcelAxisItem[]) =>
      parsedFixedColumns(
        columnsState,
        tableState.subscribe,
        tableState.getState,
        tableState.defaultWidthColumn,
        setWidths,
        tableState,
      )

    const { columnsCells: initColumnsCells } = useMemo(() => {
      setDepth(getDepth?.() || 1)
      return initColumns ? initializeColumns(initColumns) : { columnsCells: [] }
    }, [initColumns])

    const [columns, setColumns] = useState<SubscribeFixedColumnCell[]>(initColumnsCells)

    useEffect(
      () =>
        tableState.subscribe?.(
          ({ data }) => data?.[1]?.rows?.[0],
          (columnsState: ExcelAxisItem[]) => {
            if (columnsState === initColumns) {
              return
            }
            const { columnsCells } = parsedFixedColumns(
              columnsState,
              tableState.subscribe,
              tableState.getState,
              tableState.defaultWidthColumn,
              setWidths,
              tableState,
            )
            setColumns(columnsCells)
            setDepth(getDepth?.() || 1)
          },
        ),
      [],
    )

    return (
      <div className={cx('flex', classes.hearer, classes.fixedColumns)}>
        {!showChart && isChartEnabled(tableState?.getState?.().data) && <div className={classes.chartIcon} />}

        {columns.map((column, indexColumn) => (
          <ExcelColumnCell
            column={column}
            disableLeftResize={indexColumn === 0}
            disableRightResize={indexColumn === columns.length - 1}
            initWidth={
              column.getWidth?.() ||
              column.item?.initWidth ||
              tableState.storageWidths?.getFixedWidthByKey(column.key) ||
              tableState.defaultWidthColumn
            }
            key={column.key}
            ready={ready}
            setWidth={(width, keys) => {
              if (!column.key) {
                return
              }
              setWidth(keys ?? [column.key], width)
              if (!keys) {
                tableState.storageWidths?.setFixedWidthByKey(column.key, width)
              }
            }}
            valueIsName
          />
        ))}
      </div>
    )
  },
)
