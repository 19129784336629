import { FC, memo } from 'react'

import { t } from 'packages/localization'
import { Select, SelectTypes } from 'packages/ui'
import { ScorecardColumn } from 'prgm/scorecard/api'
import { UseControlScenario } from 'prgm/scorecard/stores'

interface Props {
  value?: number
  rowId: number
  rowKey: string
  column: ScorecardColumn
  setValue: UseControlScenario['setValue']
}

export const ControlScenarioSelect: FC<Props> = memo(({ column, rowKey, rowId, value, setValue }) => (
  <Select
    isCleanable
    onChange={(newValue) => {
      const option = column.options?.[(newValue - 1) as number]
      if (option) {
        setValue(rowId, rowKey, option, newValue as number)
      }
    }}
    options={column.options.map((option, index) => ({
      label: option.name,
      value: index + 1,
    }))}
    searchInDropdown
    showSearch
    type={SelectTypes.Cell}
    value={value}
    valueStringWhenNotLoaded={t('searchPromo.selectItems')}
  />
))
