import {
  ExcelChangedCellsState,
  ExcelState,
  ExcelStateSelectedCells,
  TableState,
} from 'interfaces/excelTable.interfaces'

import { isChartEnabled } from './isChartEnabled'

export const isShowChartIcon = <
  State extends ExcelState = ExcelState,
  StateChangedCells extends ExcelChangedCellsState = ExcelChangedCellsState,
  StateSelectedCells extends ExcelStateSelectedCells<State> = ExcelStateSelectedCells<State>,
>(
  tableState: TableState<State, StateChangedCells, StateSelectedCells>,
) => {
  const state = tableState?.getState?.()
  return !state?.showChart && isChartEnabled(tableState?.getState?.().data)
}
