import { ModuleConnector } from 'packages/app'
import { t } from 'packages/localization'

export const deployStockAllocationModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    stockAllocation: {
      label: () => t('deploy.navMenu.deployment.allocation'),
      href: '/deploy/stock-allocation',
    },
  },
})
