import { useRef } from 'react'

import { DraggableParams } from 'packages/ui/Draggable'
import classes from 'packages/ui/Table/Table.module.scss'

export const useDraggableElementModify = (draggableGetDescription?: (meta: any | null) => string) => {
  const refDescriptionElement = useRef<HTMLTableCellElement>()

  const onDragStart = (params: DraggableParams) => {
    if (!draggableGetDescription || !params.refDraggableItem.current) {
      return
    }
    params.refDraggableItem.current.classList.add(classes.draggingWithDescription)
    refDescriptionElement.current = document.createElement('td')
    refDescriptionElement.current.classList.add(classes.draggableDescriptionElement)
    if (params.refInitialIndex.current !== null) {
      refDescriptionElement.current.innerHTML = draggableGetDescription(
        params.refMetaItems.current[params.refInitialIndex.current],
      )
    }
    params.refDraggableItem.current?.appendChild(refDescriptionElement.current)
  }

  const onDragEnd = (params: DraggableParams) => {
    if (!draggableGetDescription || !params.refDraggableItem.current) {
      return
    }
    refDescriptionElement.current?.remove()
    params.refDraggableItem.current.classList.remove(classes.draggingWithDescription)
  }

  return { onDragStart, onDragEnd }
}
