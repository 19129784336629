import { Streams } from 'constants/streams'
import { ProjectTypes } from 'opti/shared/constants/projectTypes'
import { ModuleConnector } from 'packages/app'
import { t } from 'packages/localization'

export const optiScenariosModule: ModuleConnector = (options) => ({
  ...options,
  menuItems: {
    promoOptimizer: {
      label: () => t('menu.allScenarios'),
      href: `/${Streams.Opti}/promo-optimizer`,
      dependence: (appContext) => appContext.modules.project.currentProject?.type.code === ProjectTypes.PromoOptimizer,
    },
    createPromoScenario: {
      label: () => t('menu.createScenario'),
      href: `/${Streams.Opti}/create-promo-scenario`,
      dependence: (appContext) => appContext.modules.project.currentProject?.type.code === ProjectTypes.PromoOptimizer,
    },
    rtmOptimizer: {
      label: () => t('menu.allScenarios'),
      href: `/${Streams.Opti}/rtm-optimizer`,
      dependence: (appContext) => appContext.modules.project.currentProject?.type.code === ProjectTypes.RtmOptimizer,
    },
    createRtmScenario: {
      label: () => t('menu.createScenario'),
      href: `/${Streams.Opti}/create-rtm-scenario`,
      dependence: (appContext) => appContext.modules.project.currentProject?.type.code === ProjectTypes.RtmOptimizer,
    },
    tetrisOptimizer: {
      label: () => t('menu.allScenarios'),
      href: `/${Streams.Opti}/tetris-optimizer`,
      dependence: (appContext) => appContext.modules.project.currentProject?.type.code === ProjectTypes.TetrisOptimizer,
    },
    createTetrisScenario: {
      label: () => t('menu.createScenario'),
      href: `/${Streams.Opti}/create-tetris-scenario`,
      dependence: (appContext) => appContext.modules.project.currentProject?.type.code === ProjectTypes.TetrisOptimizer,
    },
    cfrOptimizer: {
      label: () => t('menu.allScenarios'),
      href: `/${Streams.Opti}/cfr-optimizer`,
      dependence: (appContext) => appContext.modules.project.currentProject?.type.code === ProjectTypes.CfrOptimizer,
    },
    createCfrScenario: {
      label: () => t('menu.createScenario'),
      href: `/${Streams.Opti}/create-cfr-scenario`,
      dependence: (appContext) => appContext.modules.project.currentProject?.type.code === ProjectTypes.CfrOptimizer,
    },
  },
})
