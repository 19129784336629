import { NoParamEndpointConstructor } from 'packages/core/apiConfig'

const baseUrl = '/auth'

interface Endpoints {
  refresh: NoParamEndpointConstructor
}

export const tokensEndpoints: Endpoints = {
  refresh: () => ({
    url: `${baseUrl}/refresh`,
    apiRoute: `${baseUrl}/refresh`,
  }),
}
