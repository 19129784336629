import {
  ExcelChangedCellsState,
  ExcelState,
  ExcelStateSelectedCells,
  ExcelStateSelectedColumns,
  TableState,
} from 'interfaces/excelTable.interfaces'

export const getValueFormatted = (
  value: string | number | null,
  attribute: string | undefined,
  tableState: TableState<
    ExcelState,
    ExcelChangedCellsState,
    ExcelStateSelectedCells<ExcelState>,
    ExcelStateSelectedColumns
  >,
  oversize: boolean,
) => {
  const valueGloballyFormatted =
    value && tableState.formatCell ? tableState.formatCell({ attribute, value, oversize }) : value

  if (valueGloballyFormatted !== value) {
    return valueGloballyFormatted
  }
  if (value && tableState.formatDates) {
    return tableState.formatDates(value)
  }
  return value
}
