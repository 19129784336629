import { FC, ReactNode } from 'react'

import CloseIcon from 'assets/images/close-16.svg?react'
import RemoveIcon from 'assets/images/delete.svg?react'
import DangerIcon from 'assets/images/info-circle.svg?react'
import InfoIcon from 'assets/images/info.svg?react'
import MarkCircleIcon from 'assets/images/mark-circle.svg?react'
import MarkIcon from 'assets/images/mark.svg?react'
import cx from 'clsx'
import { Loader, LoaderTypes } from 'packages/ui/Loader'

import classes from './MarkItem.module.scss'

export enum MarkItemTypes {
  Default = 'default',
  Danger = 'danger',
  Info = 'info',
  InfoSuccess = 'infoSuccess',
  DangerInfo = 'dangerInfo',
  GraySuccess = 'graySuccess',
  Remove = 'remove',
  Close = 'close',
}

export enum MarkItemSizes {
  Default = 'default-size',
  Large = 'large',
  Small = 'small',
}

const MarkIcons = {
  [MarkItemTypes.Default]: <MarkCircleIcon />,
  [MarkItemTypes.Danger]: <DangerIcon />,
  [MarkItemTypes.InfoSuccess]: <DangerIcon />,
  [MarkItemTypes.Info]: <InfoIcon />,
  [MarkItemTypes.DangerInfo]: <InfoIcon />,
  [MarkItemTypes.GraySuccess]: <MarkIcon />,
  [MarkItemTypes.Remove]: <RemoveIcon />,
  [MarkItemTypes.Close]: <CloseIcon />,
}

interface MarkItemProps {
  className?: string
  type?: MarkItemTypes
  size?: MarkItemSizes
  children?: ReactNode
  disabled?: boolean
  isBold?: boolean
  onClick?: () => void
  isClickable?: boolean
  isLoading?: boolean
}

export const MarkItem: FC<MarkItemProps> = ({
  className,
  type = MarkItemTypes.Default,
  size = MarkItemSizes.Default,
  children,
  isBold,
  onClick,
  isClickable,
  disabled = false,
  isLoading,
}) => (
  <div
    className={cx(classes.wrap, className, classes[type], classes[size], {
      [classes.bold]: isBold,
      [classes.isClickable]: isClickable,
      [classes.disabled]: disabled,
    })}
    onClick={onClick}
  >
    <div className={cx('icon', classes.icon)}>
      {isLoading ? (
        <Loader type={LoaderTypes.Spinner} />
      ) : type === MarkItemTypes.Danger && size === MarkItemSizes.Large ? (
        <InfoIcon />
      ) : (
        MarkIcons[type]
      )}
    </div>
    <span className={classes.text}>{children}</span>
  </div>
)
