import { FC, useContext } from 'react'

import { Skeleton } from 'packages/ui/Skeleton'

import { ExcelTableContext } from './context/ExcelTableContext'
import classes from './ExcelTable.module.scss'

export const ExcelLoading: FC = () => {
  const { tableState } = useContext(ExcelTableContext)

  return (
    <>
      {Array.from({ length: 50 })
        .map(() => Array.from({ length: 30 }))
        .map((row, index1) => (
          <div className={classes.placeholderRow} key={index1}>
            {row.map((_, index2) => (
              <div className={classes.cell} key={index2} style={{ width: tableState.defaultWidthColumn }}>
                <Skeleton className={classes.skeleton} disableAnimation />
              </div>
            ))}
          </div>
        ))}
    </>
  )
}
