import { createEndpoint, NoParamEndpointConstructor } from 'packages/core'

const baseUrl = '/prgm/scorecard'

interface Endpoints {
  scenarios: NoParamEndpointConstructor
  calculate: NoParamEndpointConstructor
  controlYear: NoParamEndpointConstructor
  targetYear: NoParamEndpointConstructor
}

export const scorecardEndpoints: Endpoints = {
  scenarios: () => createEndpoint(`${baseUrl}/scenarios`),
  calculate: () => createEndpoint(`${baseUrl}/calculate`),
  controlYear: () => createEndpoint(`${baseUrl}/filters/control-year`),
  targetYear: () => createEndpoint(`${baseUrl}/filters/target-year`),
}
