import { SelectedColumns, SubscribeColumnCell, SubscribeFixedColumnCell } from 'interfaces/excelTable.interfaces'

export const getIsSelected = ({
  selectedColumns,
  column,
}: {
  selectedColumns: SelectedColumns | undefined
  column: SubscribeColumnCell | SubscribeFixedColumnCell
}) => {
  let isSelected = false
  let isSelectedStart = false
  let isSelectedEnd = false

  if (selectedColumns) {
    const start = Math.min(selectedColumns[column.type].start, selectedColumns[column.type].end)
    const end = Math.max(selectedColumns[column.type].start, selectedColumns[column.type].end)
    isSelected = column.index >= start && column.index <= end

    isSelectedStart =
      column.index === 0 && column.type === 'cells' && selectedColumns.fixed.end > -1 ? false : column.index === start
    isSelectedEnd =
      column.isLastIndex && column.type === 'fixed' && selectedColumns.cells.start > -1 ? false : column.index === end
  }

  return {
    isSelected,
    isSelectedStart,
    isSelectedEnd,
  }
}
