export const average = (numbers: number[]) => {
  const sum = numbers.reduce((acc, number) => acc + number, 0)
  const length = numbers.length
  return sum / length
}

export const getNumbersBetween = (num1: number, num2: number): number[] => {
  const list = []
  for (let i = num1; i <= num2; i++) {
    list.push(i)
  }
  return list
}
