import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import ChartIcon from 'assets/images/chart.svg?react'
import cx from 'clsx'
import { ExcelAxisItem, ExcelFilters, SubscribeFixedRowCell } from 'interfaces/excelTable.interfaces'

import { ExcelTableContext } from './context/ExcelTableContext'
import { ExcelCell } from './ExcelCell'
import classes from './ExcelTable.module.scss'
import { useCellsSliceY } from './handlers/useCellsSliceY'
import { isShowChartIcon } from './helpers/isShowChartIcon'
import { parseFixedRows } from './helpers/parseFixedRows'

interface ExcelFixedRowsProps {
  page: number
  perPage: number
  countX: number
  countY: number
  paginateAxis: 'x' | 'y'
  ready: boolean
  initCells?: ExcelAxisItem[][]
}

export const ExcelFixedRows = memo(
  ({ page, perPage, countX, countY, paginateAxis, ready, initCells }: ExcelFixedRowsProps) => {
    const { tableState } = useContext(ExcelTableContext)
    const { sliceY } = useCellsSliceY(paginateAxis, page, perPage)
    const setShowChart = tableState?.getState?.().setShowChart
    const setFilter = tableState.filterState?.getState().setFilter
    const excludeFilters = tableState.chartOptions?.excludeFilters

    const getSelectedCells = useCallback(
      () => tableState.getStateSelectedCells?.().selectedCells,
      [tableState.getState],
    )

    const initializeCells = (rows: ExcelAxisItem[][] | undefined) =>
      parseFixedRows(rows, tableState.subscribe, tableState.getState, page, perPage, countX, countY, paginateAxis)

    const { cellsWithSubscriber: initCellsWithSubscriber } = useMemo(() => initializeCells(initCells), [initCells])
    const [cells, setCells] = useState<SubscribeFixedRowCell[][]>(initCellsWithSubscriber)
    const slicedCells = cells.slice(sliceY[0], sliceY[1] + 1)

    useEffect(
      () =>
        tableState.subscribe?.(
          ({ data }) => data?.[page]?.rows,
          (rows: ExcelAxisItem[][] | undefined) => {
            if (rows === initCells) {
              return
            }
            const { cellsWithSubscriber } = initializeCells(rows)
            setCells(cellsWithSubscriber)
          },
        ),
      [tableState.subscribe],
    )

    const showChartInternal = (row: SubscribeFixedRowCell[]) => {
      const filter: ExcelFilters = row.reduce((acc, cell) => {
        const code = cell.getInitCell()?.code
        const value = cell.getInitCell()?.value || null

        return code && !excludeFilters?.includes(code) && value ? { ...acc, [code]: { include: [value] } } : acc
      }, {})

      setFilter?.(filter)
      setShowChart?.(true)
    }

    return (
      <>
        {slicedCells.map((row, rowIndex) => {
          const indexRow = (page - 1) * perPage + sliceY[0] + rowIndex
          const color = row[0]?.getInitCell()?.color || ''

          return (
            <div
              className={classes.row}
              key={indexRow}
              style={{
                height: tableState.heightRow,
                transform: `translateY(${indexRow * tableState.heightRow}px)`,
              }}
            >
              {isShowChartIcon(tableState) && (
                <div className={cx(classes.chartIcon, classes[color])}>
                  <ChartIcon onClick={() => showChartInternal(row)} />
                </div>
              )}
              {row.map((cell, cellIndex) => (
                <ExcelCell
                  getInitCell={cell.getInitCell}
                  getSelectedCells={getSelectedCells}
                  indexColumn={cellIndex}
                  indexRow={indexRow}
                  initWidth={cell.getWidth?.()}
                  isFixed
                  key={cell.getInitCell()?.code || cellIndex}
                  ready={ready}
                  subscribeCell={cell.subscribeCell}
                  subscribeWidth={cell.subscribeWidth}
                />
              ))}
            </div>
          )
        })}
      </>
    )
  },
)
