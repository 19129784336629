import { AxiosError, AxiosResponse } from 'axios'
import { loginEndpoints, tokensEndpoints } from 'packages/core/auth'

import { AxiosExtendedRequestConfig } from './types'

export class RetryRequestController {
  private response: AxiosResponse<unknown> | undefined
  private originalRequest: AxiosExtendedRequestConfig

  constructor(axiosError: AxiosError) {
    this.response = axiosError.response
    this.originalRequest = axiosError.config as AxiosExtendedRequestConfig
    if (!this.originalRequest.headers) {
      this.originalRequest.headers = {}
    }
  }

  init() {
    this.originalRequest.isRetry = true
  }

  isNoRetry() {
    return (
      this.response?.status !== 401 ||
      !this.originalRequest ||
      this.originalRequest.isRetry ||
      this.originalRequest.url === tokensEndpoints.refresh().url ||
      this.originalRequest.url === loginEndpoints.login().url
    )
  }

  parseJsonBody() {
    if (
      this.originalRequest.headers?.['Content-Type'] === 'application/json' &&
      typeof this.originalRequest.data === 'string'
    ) {
      this.originalRequest.data = JSON.parse(this.originalRequest.data)
    }
  }

  setAccessToken(accessToken: string) {
    this.originalRequest.headers.Authorization = `Bearer ${accessToken}`
  }
}
