import { convertToInteger, formatSecondsToDateString } from 'packages/helper'
import { t } from 'packages/localization'

export const parseSessionTime = (ms?: number | null) => {
  if (!ms) {
    return t('date.second', { count: 0 })
  }
  if (ms <= 1000) {
    return t('date.second', { count: 1 })
  }
  return formatSecondsToDateString(convertToInteger(ms / 1000), t)
}
