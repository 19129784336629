const getCellInputText = (cell: HTMLTableCellElement) => {
  const input = cell.querySelector('input[type="text"]') as HTMLInputElement
  if (input) {
    return input.value
  }
}

const getCellTruncatedText = (cell: HTMLTableCellElement) => {
  const truncatedCell = cell.querySelector('div.truncate')
  return truncatedCell?.textContent
}

const getCellTextFromDataAttribute = (cell: HTMLTableCellElement) => {
  const el = cell.querySelector('[data-copy-text]')
  return el?.getAttribute('data-copy-text')
}

export const getCellText = (cell: HTMLTableCellElement) =>
  getCellTextFromDataAttribute(cell) || getCellInputText(cell) || getCellTruncatedText(cell) || cell.textContent
