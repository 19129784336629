import { axios, GetOneResponse } from 'packages/core'

import { systemUpdatingEndpoints } from './endpoints'
import { SystemUpdating } from './interfaces'

const getSystemUpdatingStatus = async (): GetOneResponse<SystemUpdating> => {
  const { data } = await axios.request<SystemUpdating>({
    method: 'GET',
    ...systemUpdatingEndpoints.getUpdatingStatus(),
    tracing: {
      stream: null,
      module: 'app.systemUpdating',
      methodName: 'getSystemUpdatingStatus',
    },
  })
  return data
}

export const systemUpdatingApi = {
  getSystemUpdatingStatus,
}
