import { AxiosError } from 'axios'
import { errorInterceptor } from 'packages/core/apiConfig/api'
import { CustomAxiosInstance } from 'packages/core/apiConfig/types'
import { tracingRequestInterceptor, tracingResponseInterceptor } from 'packages/core/tracing'

export const connectErrorAndTracingPipeline = (
  axios: CustomAxiosInstance,
  options: { tracing?: { enable?: boolean } },
) => {
  if (options.tracing?.enable) {
    axios.interceptors.request.use(tracingRequestInterceptor)
    axios.interceptors.response.use(
      (response) => response,
      (response: AxiosError) =>
        errorInterceptor(response).catch((apiError) => tracingResponseInterceptor(response, apiError)),
    )
  } else {
    axios.interceptors.response.use((response) => response, errorInterceptor)
  }
}
