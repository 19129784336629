export const secondsInMinute = 60
export const secondsInHour = secondsInMinute * 60
export const secondsInDay = secondsInHour * 24
export const secondsInWeek = secondsInDay * 7

export const secondsToTime = (seconds: number) => {
  if (seconds === Infinity) {
    return { seconds: 0, minutes: 0, hours: 0, days: 0, weeks: 0, infinity: true }
  }
  const weeks = Math.trunc(seconds / secondsInWeek)
  seconds = seconds % secondsInWeek
  const days = Math.trunc(seconds / secondsInDay)
  seconds = seconds % secondsInDay
  const hours = Math.trunc(seconds / secondsInHour)
  seconds = seconds % secondsInHour
  const minutes = Math.trunc(seconds / secondsInMinute)
  seconds = seconds % secondsInMinute
  return {
    seconds,
    minutes,
    hours,
    days,
    weeks,
    infinity: false,
  }
}

export const formatSecondsToDateString = (totalSeconds: number, t: (key: any, options: any) => string) => {
  if (totalSeconds === Infinity) {
    return '∞'
  }
  const { seconds, minutes, hours, days, weeks } = secondsToTime(totalSeconds)
  const weeksStr = weeks ? `${weeks} ${t('date.week', { count: weeks || 0 })},` : ''
  const daysStr = days ? ` ${days} ${t('date.day', { count: days || 0 })},` : ''
  const hoursStr = hours ? ` ${hours} ${t('date.hour', { count: hours || 0 })},` : ''
  const minutesStr = minutes ? ` ${minutes} ${t('date.minute', { count: minutes || 0 })},` : ''
  const secondsStr = seconds ? ` ${seconds} ${t('date.second', { count: seconds || 0 })},` : ''
  return `${weeksStr} ${daysStr} ${hoursStr} ${minutesStr} ${secondsStr}`.trim().slice(0, -1) || '0'
}
