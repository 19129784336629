import { FC, MouseEventHandler, ReactNode } from 'react'

import cx from 'clsx'
import { hexToRgb } from 'packages/helper'
import { Close, CloseSizes } from 'packages/ui/Close'
import { LoaderColors, LoaderTypes } from 'packages/ui/Loader'
import { Loader } from 'packages/ui/Loader/Loader'
import { isNotStopPropagation } from 'utils/isNotStopPropagation'

import classes from './Tag.module.scss'

export enum TagSizes {
  Medium = 'medium',
  Small = 'small',
  Large = 'large',
}

export enum TagColors {
  Default = 'default',
  Danger = 'danger',
  White = 'white',
  Dark = 'dark',
  Black = 'black',
  Primary = 'primary',
  Red = 'red',
  Transparent = 'transparent',
}

const LoaderDependence = {
  [TagColors.Default]: LoaderColors.Gray,
  [TagColors.Danger]: LoaderColors.White,
  [TagColors.White]: LoaderColors.Gray,
  [TagColors.Dark]: LoaderColors.Gray,
  [TagColors.Black]: LoaderColors.Gray,
  [TagColors.Primary]: LoaderColors.White,
  [TagColors.Red]: LoaderColors.White,
  [TagColors.Transparent]: LoaderColors.Gray,
}

interface TagProps {
  className?: string
  classNameDisabled?: string
  classNameLabel?: string
  children?: ReactNode
  startIcon?: ReactNode
  endIcon?: ReactNode
  isClosable?: boolean
  isClickable?: boolean
  color?: TagColors
  size?: TagSizes
  colorHex?: string
  isBorder?: boolean
  onClick?: () => void
  onClose?: () => void
  disabled?: boolean
  flex?: boolean
  loading?: boolean
  disableClose?: boolean
  gradient?: boolean
}

export const Tag: FC<TagProps> = ({
  className,
  classNameDisabled,
  classNameLabel,
  children,
  startIcon,
  endIcon,
  isClosable,
  isClickable,
  color = TagColors.Default,
  size = TagSizes.Small,
  colorHex,
  isBorder,
  onClick,
  onClose,
  disabled,
  flex,
  loading,
  disableClose,
  gradient,
}) => {
  const onClickInternal: MouseEventHandler = (event) => {
    if (isNotStopPropagation(event.target) && onClick) {
      onClick()
    }
  }

  const onCloseInternal = () => {
    if (onClose) {
      onClose()
    }
  }

  const rgbArray = colorHex && hexToRgb(colorHex)
  const rgba = rgbArray && `rgba(${rgbArray[0]}, ${rgbArray[1]}, ${rgbArray[2]}, 0.85)`

  return (
    <div
      className={cx(classes.wrap, className, color && classes[color], classes[size], disabled && classNameDisabled, {
        [classes.pointer]: isClickable,
        [classes.disabled]: disabled,
        [classes.isBorder]: isBorder,
      })}
      onClick={onClickInternal}
      style={{
        ...(isBorder
          ? { boxShadow: `0 0 0 1px ${colorHex} inset`, color: colorHex }
          : {
              backgroundColor: gradient ? 'transparent' : colorHex,
              color: colorHex ? '#fff' : undefined,
              backgroundImage: gradient
                ? `repeating-linear-gradient(295deg, ${rgba} 0, ${rgba} 25px, ${colorHex} 25px, ${colorHex} 50px)`
                : undefined,
            }),
      }}
    >
      <span
        className={cx(classes.cont, {
          [classes.contShort]: isClosable || loading,
        })}
      >
        {startIcon && <div className={classes.icon}>{startIcon}</div>}
        {(children || children === 0) && (
          <span className={cx(classes.label, classNameLabel, { [classes.flex]: flex })}>{children}</span>
        )}
        {endIcon && <div className={classes.icon}>{endIcon}</div>}
      </span>

      {isClosable && !loading && (
        <Close
          className={cx(classes.icoClose, { [classes.disableClose]: disableClose })}
          dataStopPropagation
          onClick={onCloseInternal}
          size={CloseSizes.Small}
        />
      )}
      {loading && (
        <Loader className={classes.icoClose} color={LoaderDependence[color]} type={LoaderTypes.SpinnerMini} />
      )}
    </div>
  )
}
