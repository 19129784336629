import { memo, MutableRefObject, useCallback, useContext } from 'react'

import { SubscribeCell } from 'interfaces/excelTable.interfaces'

import { ExcelTableContext } from './context/ExcelTableContext'
import { ExcelCell } from './ExcelCell'
import classes from './ExcelTable.module.scss'

interface ExcelCellsRowProps {
  paginateAxis: 'x' | 'y'
  cells: SubscribeCell[]
  pageX: number
  perPage: number
  indexRow: number
  startSliceX: number
  ready: boolean
  refCellsOptions: MutableRefObject<{
    onKeyDown: ((event: KeyboardEvent) => Promise<(number | null)[][] | undefined>)[][]
  }>
}

export const ExcelCellsRow = memo(
  ({ paginateAxis, cells, pageX, perPage, indexRow, startSliceX, ready, refCellsOptions }: ExcelCellsRowProps) => {
    const { tableState } = useContext(ExcelTableContext)
    const getSelectedCells = useCallback(
      () => tableState.getStateSelectedCells?.().selectedCells,
      [tableState.getStateSelectedCells],
    )

    if (!cells.length) {
      return null
    }

    const firstKey = cells[0].getInitCell()?.key

    return (
      <div
        className={classes.row}
        style={{
          height: tableState.heightRow,
          transform: `translate(${(firstKey && tableState.columns[firstKey].position.left) || 0}px, ${
            indexRow * tableState.heightRow
          }px)`,
        }}
      >
        {cells.map((cell, cellIndex) => {
          const indexColumn =
            paginateAxis === 'x' ? (pageX - 1) * perPage + cellIndex + startSliceX : cellIndex + startSliceX
          const attribute = tableState.getAttribute && tableState.getAttribute(indexRow, indexColumn)
          return (
            <ExcelCell
              attribute={attribute}
              getFields={cell.getFields}
              getInitCell={cell.getInitCell}
              getLocation={cell.getLocation}
              getOffsetCell={cell.getOffsetCell}
              getSelectedCells={getSelectedCells}
              indexColumn={indexColumn}
              indexRow={indexRow}
              initWidth={
                cell.getWidth?.() || tableState.storageWidths?.getWidth(indexColumn) || tableState.defaultWidthColumn
              }
              isFixed={false}
              key={cell.getInitCell()?.key || cellIndex}
              ready={ready}
              refCellsOptions={refCellsOptions}
              subscribeCell={cell.subscribeCell}
              subscribeWidth={cell.subscribeWidth}
            />
          )
        })}
      </div>
    )
  },
)
