import {
  Dispatch,
  forwardRef,
  KeyboardEvent,
  ReactElement,
  Ref,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useMutationObserver } from 'ahooks'
import ArrowRightIcon from 'assets/images/arrow-right.svg?react'
import cx from 'clsx'
import { localization } from 'config/i18n'
import { inputMasks } from 'constants/app'
import { endOfMonth, endOfYear, startOfMonth, startOfYear } from 'date-fns'
import { useCombinedRef } from 'hooks/useCombinedRef'
import { calendarLocals } from 'locale/calendar'
import { isEqual } from 'lodash'
import moment from 'moment'
import { Moment } from 'moment/moment'
import { onlyNumbers } from 'packages/helper/src/onlyNumbers'
import { t } from 'packages/localization'
import { Button, ButtonColors, ButtonSizes } from 'packages/ui/Button'
import { TextField } from 'packages/ui/TextField'
import { PickerPanel } from 'rc-picker'
import momentGenerateConfig from 'rc-picker/lib/generate/moment'
import locale from 'rc-picker/lib/locale/ru_RU'
import {
  dateByMode,
  disabledDate,
  formatDate,
  isDisabledAfterDate,
  isDisabledBeforeDate,
  isDisabledBeforeToday,
  isValidFormattedDate,
  parseDate,
  toMidnight,
} from 'utils/date'

import classes from './Calendar.module.scss'

export interface CalendarProps<IsRange extends boolean = false> {
  className?: string
  disabledBeforeToday?: boolean
  disabledBeforeDate?: Date | string | number | null
  disabledAfterDate?: Date | string | number | null
  onChange?: (date: IsRange extends true ? [Date | null, Date | null] : string | Date | null) => void
  value?: (IsRange extends true ? [Date | null, Date | null] : Date | null) | null
  showResetButton?: boolean
  showApplyButton?: boolean
  isRange?: IsRange
  hideInputs?: boolean
  mode?: 'date' | 'month'
}

const CalendarInternal = <IsRange extends boolean = false>(
  {
    className,
    disabledBeforeToday,
    disabledBeforeDate,
    disabledAfterDate,
    onChange,
    value,
    showResetButton,
    showApplyButton,
    isRange,
    hideInputs,
    mode = 'date',
  }: CalendarProps<IsRange>,
  ref?: Ref<HTMLDivElement>,
) => {
  type Value = (IsRange extends true ? [Date | null, Date | null] : Date | null) | null | undefined

  const [valueInternal, setValueInternal] = useState<Value>(value)
  const [isFocus, setIsFocus] = useState(false)
  const [isError, setIsError] = useState(false)
  const refIsFirst = useRef(true)

  const refInternal = useRef<HTMLDivElement>(null)
  const { cbRef } = useCombinedRef<HTMLDivElement>(ref, refInternal)
  const refFirstInput = useRef<HTMLInputElement>(null)
  const refSecondInput = useRef<HTMLInputElement>(null)

  const lang = localization.i18n.getLang()
  moment.updateLocale(lang, calendarLocals[lang])
  const selectionStart = mode === 'date' ? 9 : 7
  const selectionEnd = mode === 'date' ? 10 : 8

  const onChangeInternal = (date: Moment | null): void => {
    if (!date) {
      return
    }
    const newDate = toMidnight(date.toDate())
    if (mode === 'month') {
      newDate.setDate(1)
    }
    const isDisabled = !!disabledDate(disabledBeforeToday, disabledBeforeDate, disabledAfterDate)?.(date)
    if (!isRange) {
      const onChangeOne = onChange as (date: string | Date | null) => void
      const setValueInternalOne = setValueInternal as Dispatch<SetStateAction<Date | null | undefined>>
      if (isDisabled) {
        if (isDisabledBeforeToday(disabledBeforeToday, date.valueOf())) {
          return onChangeOne?.(new Date())
        }
        if (disabledBeforeDate && isDisabledBeforeDate(disabledBeforeDate, date.valueOf())) {
          return onChangeOne?.(new Date(disabledBeforeDate))
        }
        if (disabledAfterDate && isDisabledAfterDate(disabledAfterDate, date.valueOf())) {
          return onChangeOne?.(new Date(disabledAfterDate))
        }
        return
      }
      if (!showApplyButton) {
        onChangeOne?.(newDate)
      }
      setValueInternalOne(newDate)
    } else {
      const onChangeRange = onChange as undefined | ((date: [Date | null, Date | null] | null) => void)
      const setValueInternalRange = setValueInternal as Dispatch<SetStateAction<[Date | null, Date | null] | undefined>>
      if (refIsFirst.current) {
        setValueInternalRange((prev) => {
          let newValue: [Date | null, Date | null] = [newDate, prev?.[1] || null]
          if (newValue[0] && newValue[1] && new Date(newValue[0]).getTime() > new Date(newValue[1]).getTime()) {
            newValue = [newDate, null]
          }
          if (!showApplyButton) {
            onChangeRange?.(newValue)
          }
          return newValue
        })
        refIsFirst.current = false
      } else {
        setValueInternalRange((prev) => {
          let newValue: [Date | null, Date | null] = [prev?.[0] || null, newDate]
          if (newValue[0] && newValue[1] && new Date(newValue[0]).getTime() > new Date(newValue[1]).getTime()) {
            newValue = [newValue[1], newValue[0]]
          } else {
            refIsFirst.current = true
          }
          if (!showApplyButton) {
            onChangeRange?.(newValue)
          }
          return newValue
        })
      }
    }
  }

  const onChangeInput = (date: string, position: 'start' | 'end') => {
    const onChangeRange = onChange as undefined | ((date: [Date | null, Date | null]) => void)
    const setValueInternalRange = setValueInternal as Dispatch<SetStateAction<[Date | null, Date | null] | undefined>>
    setIsError(false)
    if (isValidFormattedDate(date)) {
      setValueInternalRange((prev) => {
        const newValue: [Date | null, Date | null] =
          position === 'start' ? [parseDate(date), prev?.[1] || null] : [prev?.[0] || null, parseDate(date)]
        if (newValue[0] && newValue[1] && new Date(newValue[0]).getTime() > new Date(newValue[1]).getTime()) {
          setIsError(true)
        }
        if (!showApplyButton) {
          onChangeRange?.(newValue)
        }
        return newValue
      })
    } else if (onlyNumbers(date) === '') {
      setValueInternalRange((prev) => {
        const newValue: [Date | null, Date | null] =
          position === 'start' ? [null, prev?.[1] || null] : [prev?.[0] || null, null]
        if (!showApplyButton) {
          onChangeRange?.(newValue)
        }
        return newValue
      })
      if (position === 'end') {
        refIsFirst.current = false
      }
    }
  }

  const onClear = () => {
    onChange?.((isRange ? [null, null] : null) as any)
    setValueInternal(undefined)
    setIsError(false)
    setIsFocus(false)
    refIsFirst.current = true
  }

  const apply = () => {
    if (valueInternal && !isError) {
      onChange?.(valueInternal)
    }
  }

  const onKeyDownFirst = (event: KeyboardEvent) => {
    const target = event.target as HTMLInputElement
    if (
      (target.selectionEnd &&
        target.selectionEnd >= selectionStart &&
        target.selectionStart === target.selectionEnd &&
        !['Backspace', 'ArrowLeft', 'ArrowRight'].includes(event.key)) ||
      (target.selectionStart === selectionEnd && target.selectionEnd === selectionEnd && event.key === 'ArrowRight')
    ) {
      requestAnimationFrame(() => {
        if (refSecondInput.current) {
          refSecondInput.current.focus()
          refSecondInput.current.selectionStart = 0
          refSecondInput.current.selectionEnd = 0
        }
      })
    }
  }

  const onKeyDownSecond = (event: KeyboardEvent) => {
    const target = event.target as HTMLInputElement
    if (
      refFirstInput.current &&
      target.selectionEnd === 0 &&
      target.selectionStart === target.selectionEnd &&
      (event.key === 'Backspace' || event.key === 'ArrowLeft')
    ) {
      refFirstInput.current.focus()
      requestAnimationFrame(() => {
        if (refFirstInput.current) {
          refFirstInput.current.selectionStart = 10
          refFirstInput.current.selectionEnd = 10
        }
      })
    }
    if (event.key === 'Enter') {
      apply()
    }
  }

  useMutationObserver(
    () => {
      const yearBlock = refInternal.current?.querySelector('.rc-calendar-year-panel-tbody')
      if (yearBlock) {
        Array.from(yearBlock.querySelectorAll('.rc-calendar-year-panel-cell')).forEach((element) => {
          const title = element.getAttribute('title')
          if (title) {
            const date = endOfYear(new Date(title))
            let isDisabled = false

            if (isDisabledBeforeToday(disabledBeforeToday, date.getTime())) {
              isDisabled = true
            }
            if (isDisabledBeforeDate(disabledBeforeDate, date.getTime())) {
              isDisabled = true
            }
            if (isDisabledAfterDate(disabledAfterDate, startOfYear(date).getTime())) {
              isDisabled = true
            }

            if (isDisabled) {
              element.classList.add('rc-calendar-cell-disabled')
            }
          }
        })
      }
      const monthBlock = refInternal.current?.querySelector('.rc-calendar-month-panel-tbody')
      if (monthBlock) {
        Array.from(monthBlock.querySelectorAll('.rc-calendar-month-panel-month')).forEach((element) => {
          const dataDate = element.getAttribute('data-date')
          if (dataDate) {
            const date = endOfMonth(new Date(Number(dataDate)))
            let isDisabled = false

            if (isDisabledBeforeToday(disabledBeforeToday, date.getTime())) {
              isDisabled = true
            }
            if (isDisabledBeforeDate(disabledBeforeDate, date.getTime())) {
              isDisabled = true
            }
            if (isDisabledAfterDate(disabledAfterDate, startOfMonth(date).getTime())) {
              isDisabled = true
            }

            if (isDisabled) {
              element.parentElement?.classList.add('rc-calendar-cell-disabled')
            }
          }
        })
      }
    },
    refInternal,
    { childList: true, subtree: true },
  )

  useEffect(() => {
    setValueInternal(
      (Array.isArray(value)
        ? [value[0] ? toMidnight(value[0]) : null, value[1] ? toMidnight(value[1]) : null]
        : value
          ? toMidnight(value)
          : null) as Value,
    )
  }, [value])

  const valueToCalendar = useMemo(
    () => (Array.isArray(valueInternal) ? moment(valueInternal[0]) : moment(valueInternal)),
    [valueInternal],
  )

  return (
    <div className={cx(classes.wrap, className)} data-calendar="true" ref={cbRef}>
      {isRange && !hideInputs && (
        <div className={cx(classes.top, { [classes.isFocus]: isFocus, [classes.isError]: isError })} key="top">
          <div className={classes.topCont}>
            <TextField
              className={classes.inputLeft}
              classNameContainer={classes.inputWrap}
              mask={inputMasks[mode].mask}
              onBlur={() => setIsFocus(false)}
              onChange={(date) => {
                const parsedDate = dateByMode(date, mode)
                onChangeInput(parsedDate, 'start')
              }}
              onFocus={() => setIsFocus(true)}
              onKeyDown={onKeyDownFirst}
              placeholder={inputMasks[mode].placeholder}
              ref={refFirstInput}
              value={formatDate(Array.isArray(valueInternal) ? valueInternal[0] : valueInternal, mode)}
            />
            <TextField
              className={classes.inputRight}
              classNameContainer={classes.inputWrap}
              mask={inputMasks[mode].mask}
              onBlur={() => setIsFocus(false)}
              onChange={(date) => {
                const parsedDate = dateByMode(date, mode)
                onChangeInput(parsedDate, 'end')
              }}
              onFocus={() => setIsFocus(true)}
              onKeyDown={onKeyDownSecond}
              placeholder={inputMasks[mode].placeholder}
              ref={refSecondInput}
              value={formatDate(Array.isArray(valueInternal) ? valueInternal[1] : valueInternal, mode)}
            />
          </div>
        </div>
      )}
      <PickerPanel
        cellRender={(currentCell, info) => {
          const current = moment(currentCell)
          const currentDate = toMidnight(current.toDate()).getTime()
          let isActive
          let range = false
          if (Array.isArray(valueInternal)) {
            const startDate = valueInternal[0] ? new Date(valueInternal[0]).getTime() : null
            const endDate = !isError && valueInternal[1] ? new Date(valueInternal[1]).getTime() : null
            isActive = (startDate && currentDate === startDate) || (endDate && currentDate === endDate)
            range = !!startDate && !!endDate && currentDate > startDate && currentDate < endDate
          } else {
            isActive = currentDate === valueInternal?.getTime()
          }
          return (
            <div
              className={cx('rc-calendar-cell', {
                ['rc-calendar-date-active']: isActive,
                ['rc-calendar-date-range']: range,
                ['rc-calendar-date']: info.type === 'date',
              })}
              key={current.toISOString()}
            >
              {info.originNode}
            </div>
          )
        }}
        disabledDate={disabledDate(disabledBeforeToday, disabledBeforeDate, disabledAfterDate)}
        generateConfig={momentGenerateConfig}
        locale={locale}
        nextIcon={<ArrowRightIcon />}
        onSelect={onChangeInternal}
        picker={mode}
        prevIcon={<ArrowRightIcon />}
        superNextIcon={<ArrowRightIcon />}
        superPrevIcon={<ArrowRightIcon />}
        value={valueToCalendar.isValid() ? valueToCalendar : undefined}
      />
      {(showResetButton || showApplyButton) && (
        <div className={classes.footer}>
          {showResetButton && (
            <Button
              color={ButtonColors.Border}
              disabled={valueInternal === null || isEqual(valueInternal, [null, null])}
              fullWidth
              onClick={onClear}
              size={ButtonSizes.Medium}
            >
              {t('reset')}
            </Button>
          )}
          {showApplyButton && (
            <Button
              disabled={
                isError ||
                valueInternal === null ||
                isEqual(valueInternal, [null, null]) ||
                (isRange && Array.isArray(valueInternal) && (!valueInternal[0] || !valueInternal[1]))
              }
              fullWidth
              onClick={apply}
              size={ButtonSizes.Medium}
            >
              {t('apply')}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export const Calendar = forwardRef(CalendarInternal) as <IsRange extends boolean = false>(
  props: CalendarProps<IsRange>,
) => ReactElement
