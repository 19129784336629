import { MutableRefObject, useCallback, useRef } from 'react'
import { ConnectDropTarget } from 'react-dnd'

import { Header, HeaderGroup, Table } from '@tanstack/react-table'
import cx from 'clsx'
import { TRow } from 'interfaces/table.interfaces'
import { kebabCase } from 'lodash'

import { DragWrap } from './DragWrap'
import { getBorderRadius } from './helpers/getBorderRadius'
import { getColumnProps } from './helpers/getColumnProps'
import { getHeaderSelection } from './helpers/getHeaderSelection'
import { SelectionData } from './interfaces'
import { Resizer } from './Resizer'
import classes from './ThCell.module.scss'
import { ThCellHeader } from './ThCellHeader'

interface ThCellProps<TData extends TRow> {
  header: Header<TData, unknown>
  refFirstColumn?: MutableRefObject<HTMLTableCellElement | null>
  refColumns?: MutableRefObject<(HTMLTableCellElement | null)[]>
  columnResizable?: boolean
  columnDraggable?: boolean
  trackViewport?: boolean
  index: number
  onViewPort?: (columnId: string, inViewport: boolean) => void
  headerGroup: HeaderGroup<TData>
  table: Table<TData>
  truncateHeaders?: boolean
  onResize?: (rowId: number | string, size: number, isAutoWidthLastColumn: boolean) => void
  colExtra?: number
  copyPaste?: boolean
  selection?: SelectionData
  showBorderCell?: boolean
  isLast?: boolean
  widthFullAlways?: boolean
}

export const ThCell = <TData extends TRow>({ selection, ...restProps }: ThCellProps<TData>) => {
  const {
    header,
    refFirstColumn,
    refColumns,
    columnResizable,
    columnDraggable,
    index,
    headerGroup,
    table,
    onResize,
    colExtra,
    copyPaste,
    showBorderCell,
    isLast,
    widthFullAlways,
  } = restProps
  const { columnProps, tdProps } = getColumnProps(header.column)
  const { borderTopLeftRadius, borderTopRightRadius } = getBorderRadius(columnProps.style?.borderRadius)
  const dropRef = useRef<ConnectDropTarget>()

  const onMouseUp = useCallback(() => {
    if (header.column.id) {
      onResize?.(header.column.id, header.getSize(), false)
    }
  }, [header])

  const onMouseUpPreviousResizer = useCallback(() => {
    const prevTh = headerGroup.headers[index - 1]
    if (prevTh?.column.id) {
      onResize?.(prevTh.column.id, prevTh.getSize(), false)
    }
  }, [headerGroup.headers[index - 1]])

  const { isSelected, isStart, isEnd, inProgress, haveEdgeBorder } =
    getHeaderSelection({ index, selection, colExtra, copyPaste }) || {}

  return (
    <th
      className={cx(tdProps.className, {
        [classes.selectedBack]: isSelected,
        [classes.selectStart]: isStart,
        [classes.selectEnd]: isEnd,
        [classes.borderTop]: !inProgress,
        [classes.borderBottom]: haveEdgeBorder && !inProgress,
        [classes.couldCopyPaste]: copyPaste,
        [classes.showBorderCell]: showBorderCell,
      })}
      colSpan={header.colSpan}
      ref={(el) => {
        dropRef.current?.(el)
        if (refFirstColumn && index === 0) {
          refFirstColumn.current = el
        }
        if (refColumns) {
          refColumns.current[index] = el
        }
      }}
      style={{
        ...tdProps.style,
        borderTopLeftRadius,
        borderTopRightRadius,
        textAlign: tdProps.isNumberCellAlignRight ? 'right' : tdProps.style?.textAlign,
        width: columnResizable ? header.getSize() : tdProps.style?.width,
      }}
      {...Object.entries(tdProps.columnDataset || {}).reduce((acc: Record<string, string>, [key, value]) => {
        acc[`data-${kebabCase(key)}`] = value === null || value === undefined ? '' : String(value)
        return acc
      }, {})}
    >
      {columnDraggable ? (
        <DragWrap {...restProps} containerRef={dropRef}>
          <ThCellHeader {...restProps} />
        </DragWrap>
      ) : (
        <ThCellHeader {...restProps} />
      )}

      <Resizer
        columnResizable={columnResizable}
        isLast={isLast}
        isResizing={header.column.getIsResizing()}
        onMouseDown={header.getResizeHandler()}
        onMouseUp={onMouseUp}
        onTouchStart={header.getResizeHandler()}
        previousResizer={
          index === 0
            ? undefined
            : {
                isResizing: headerGroup.headers[index - 1].column.getIsResizing(),
                onMouseDown: headerGroup.headers[index - 1].getResizeHandler(),
                onTouchStart: headerGroup.headers[index - 1].getResizeHandler(),
                onMouseUp: onMouseUpPreviousResizer,
              }
        }
        table={table}
        widthFullAlways={widthFullAlways}
      />
    </th>
  )
}
