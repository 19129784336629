import { FC, useContext, useState } from 'react'

import { clsx } from 'clsx'
import { useOpenMenu } from 'packages/app/layout'
import { AppContext } from 'packages/app/providers'
import SidebarIcon from 'packages/icons/sidebar.svg?react'

import { NavMenu } from './NavMenu'
import classes from './Sidebar.module.scss'

export const Sidebar: FC = () => {
  const [hover, setHover] = useState(false)
  const { open, toggleOpen } = useOpenMenu()
  const { global } = useContext(AppContext)

  return (
    <>
      <aside
        className={clsx(classes.sidebar, { [classes.open]: open, [classes.hover]: hover })}
        onMouseEnter={() => setHover(!open)}
        onMouseLeave={() => setHover(false)}
      >
        <div className={classes.logoCont}>
          <img alt="Logo" className={classes.logo} src={global.LOGO} />
          <div
            className={classes.openIcon}
            onClick={() => {
              toggleOpen()
              setHover(false)
            }}
          >
            <SidebarIcon />
          </div>
        </div>
        <NavMenu />
      </aside>
      <div className={classes.openSidebar} onMouseEnter={() => setHover(!open)} onMouseLeave={() => setHover(false)} />
    </>
  )
}
