import { MouseEventHandler, useCallback, useContext, useEffect, useRef } from 'react'

import { useThrottleFn } from 'ahooks'
import { useInterval } from 'hooks/useInterval'
import { removeHTMLSelection } from 'packages/helper'
import { defaultSelectedColumns } from 'packages/ui/ExcelTable/constants/selectedColumns'
import { ExcelTableContext } from 'packages/ui/ExcelTable/context/ExcelTableContext'
import { getIndexColumnByWidth, getIndexFixColumnByWidth } from 'packages/ui/ExcelTable/helpers/getIndexColumnByWidth'

export const useSelectColumns = () => {
  const { virtualScroll, tableState, scrollingData } = useContext(ExcelTableContext)

  const refStartPosition = useRef<number | null>(null)
  const refStartWidth = useRef<number | null>(null)
  const refOnMouseMoveThrottled = useRef<((event: MouseEvent) => void) | null>(null)
  const refPositionWrap = useRef({ left: 0 })
  const refSizeWrap = useRef({ width: 0 })
  const refStartScroll = useRef({ startX: 0 })
  const refMoveForScroll = useRef({ toLeft: false, toRight: false })

  const { enableInterval, disableInterval, isEnableInterval } = useInterval(() => {
    scrollingData?.scrollToPx?.(
      virtualScroll.position.startY || 0,
      (virtualScroll.position.startX || 0) +
        (refMoveForScroll.current.toLeft ? 10 : refMoveForScroll.current.toRight ? -10 : 0),
    )
  }, 16)

  const onMouseMove = useCallback((event: MouseEvent) => {
    if (event.buttons !== 1) {
      return
    }
    const stateSelectedColumns = tableState.stateSelectedColumns?.getState()
    if (refStartPosition.current === null) {
      return
    }

    const diffX = (event as MouseEvent).pageX - refStartPosition.current
    const diffScroll = (virtualScroll.position.startX || 0) - refStartScroll.current.startX
    const width = (refStartWidth.current || 0) + diffX
    const startIsFixed = refStartWidth.current && refStartWidth.current < refStartScroll.current.startX

    let fixedStart = -1
    let fixedEnd = -1
    let cellsStart = -1
    let cellsEnd = -1

    if (width < refStartScroll.current.startX) {
      const fixedFullWidth = scrollingData?.refFixedRows.current?.offsetWidth || 0
      const fixedWidth = fixedFullWidth + (width - refStartScroll.current.startX)
      fixedStart = stateSelectedColumns?.selectedColumns.fixed.start ?? -1
      fixedEnd = getIndexFixColumnByWidth(tableState.storageWidths, tableState.defaultWidthColumn, fixedWidth)
      if (!startIsFixed) {
        cellsStart = stateSelectedColumns?.selectedColumns.cells.start ?? -1
        if ((stateSelectedColumns?.selectedColumns.cells.start ?? -1) > -1) {
          cellsEnd = 0
          fixedStart = (tableState.storageWidths?.getFixedCount() || 1) - 1
        }
      }
    } else {
      const countX = tableState.getState?.().countX || 0
      cellsEnd = getIndexColumnByWidth(tableState.storageWidths, tableState.defaultWidthColumn, width + diffScroll)
      if (cellsEnd >= countX) {
        cellsEnd = countX - 1
      }
      cellsStart = stateSelectedColumns?.selectedColumns.cells.start ?? -1
      if (startIsFixed) {
        fixedStart = stateSelectedColumns?.selectedColumns.fixed.start ?? -1
        if ((stateSelectedColumns?.selectedColumns.fixed.start ?? -1) > -1) {
          cellsStart = 0
          fixedEnd = (tableState.storageWidths?.getFixedCount() || 1) - 1
        }
      }
    }
    stateSelectedColumns?.setSelectedColumns({
      cells: {
        start: cellsStart,
        end: cellsEnd,
      },
      fixed: {
        start: fixedStart,
        end: fixedEnd,
      },
    })

    const left = event.pageX - refPositionWrap.current.left
    refMoveForScroll.current.toLeft = left > refSizeWrap.current.width - 40
    refMoveForScroll.current.toRight = left < 40

    if (refMoveForScroll.current.toLeft || refMoveForScroll.current.toRight) {
      enableInterval()
    } else if (isEnableInterval()) {
      disableInterval()
    }
  }, [])

  const { run: onMouseMoveThrottled } = useThrottleFn(onMouseMove, { wait: 20 })

  const onMouseUp = useCallback(() => {
    window.removeEventListener('mouseup', onMouseUp)
    window.removeEventListener('mousemove', onMouseMoveThrottled)
    refStartPosition.current = null
    refStartWidth.current = null
    disableInterval()
  }, [])

  const onMouseDown: MouseEventHandler = useCallback((event) => {
    if ((event.target as HTMLDivElement)?.getAttribute('data-resizer') === 'true') {
      return
    }
    tableState.getStateSelectedCells?.().clearSelectedCells()
    window.addEventListener('mouseup', onMouseUp)
    window.addEventListener('mousemove', onMouseMoveThrottled)
    removeHTMLSelection()

    if (scrollingData?.refWrap.current && scrollingData?.refColumns.current) {
      const rectWrap = scrollingData?.refWrap.current?.getBoundingClientRect()
      refPositionWrap.current = { left: rectWrap.left }
      refSizeWrap.current = { width: rectWrap.width }
      refStartScroll.current = { startX: virtualScroll.position.startX || 0 }
    }

    refStartPosition.current = event.pageX
    refStartWidth.current = event.pageX - (scrollingData?.refColumns.current?.getBoundingClientRect().left || 0)

    const stateSelectedColumns = tableState.stateSelectedColumns?.getState()

    if (refStartWidth.current < (virtualScroll.position.startX || 0)) {
      const fixedFullWidth = scrollingData?.refFixedRows.current?.offsetWidth || 0
      const fixedWidth = fixedFullWidth + (refStartWidth.current - (virtualScroll.position.startX || 0))
      const start = getIndexFixColumnByWidth(tableState.storageWidths, tableState.defaultWidthColumn, fixedWidth)
      stateSelectedColumns?.setSelectedColumns({
        ...defaultSelectedColumns,
        fixed: {
          start,
          end: start,
        },
      })
    } else {
      const start = getIndexColumnByWidth(
        tableState.storageWidths,
        tableState.defaultWidthColumn,
        refStartWidth.current,
      )
      stateSelectedColumns?.setSelectedColumns({
        ...defaultSelectedColumns,
        cells: {
          start,
          end: start,
        },
      })
    }
  }, [])

  useEffect(() => {
    refOnMouseMoveThrottled.current = onMouseMoveThrottled
  }, [onMouseMoveThrottled])

  return {
    onMouseDown,
  }
}
