import { devtools } from 'packages/core'
import { create } from 'zustand'

import { UseForecastCalculating } from './types'

export const useForecastCalculating = create<UseForecastCalculating>()(
  devtools(
    (set) => ({
      forecastCalculating: false,
      setForecastCalculating: (forecastCalculating) => set({ forecastCalculating }),
    }),
    {
      store: 'forecastCalculating',
    },
  ),
)
