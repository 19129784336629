import { FC } from 'react'

import { useUnsavedChangesBlocker } from 'packages/core/unsavedChanges/useUnsavedChangesBlocker'
import { t } from 'packages/localization'
import { ConfirmModal } from 'packages/ui'

export const UnsavedChangesModal: FC = () => {
  const { onCancel, onOk, isOpenedModal, message } = useUnsavedChangesBlocker()

  return (
    <ConfirmModal
      closeAfterSuccess
      isOpened={isOpenedModal}
      okText={t('yes')}
      onClose={onCancel}
      onOk={onOk}
      title={message}
    />
  )
}
