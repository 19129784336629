import { NoParamEndpointConstructor } from 'packages/core'
import { createEndpoint } from 'packages/core/apiConfig'

interface Endpoints {
  login: NoParamEndpointConstructor
  forgotPassword: NoParamEndpointConstructor
  resetPassword: NoParamEndpointConstructor
  resendTwoFactorCode: NoParamEndpointConstructor
  twoFactorLogin: NoParamEndpointConstructor
  checkTwoFactorCode: NoParamEndpointConstructor
  getAuthSsoTokens: NoParamEndpointConstructor
}

export const loginEndpoints: Endpoints = {
  login: () => createEndpoint('/auth/login'),
  forgotPassword: () => createEndpoint('/account/reset-password'),
  resetPassword: () => createEndpoint('/account/change-password'),
  resendTwoFactorCode: () => createEndpoint('/auth/resending-two-factor-authentication-code'),
  twoFactorLogin: () => createEndpoint('/auth/two-factor-authentication'),
  checkTwoFactorCode: () => createEndpoint('/auth/check-two-factor-authentication-code'),
  getAuthSsoTokens: () => createEndpoint('/auth/sso-callback'),
}
