import { createContext } from 'react'

import {
  ExcelChangedCellsState,
  ExcelState,
  ExcelStateSelectedCells,
  ScrollingData,
  TableState,
  VirtualScrollState,
} from 'interfaces/excelTable.interfaces'

interface ExcelTableContextProps<
  State extends ExcelState = ExcelState,
  StateChangedCells extends ExcelChangedCellsState = ExcelChangedCellsState,
  StateSelectedCells extends ExcelStateSelectedCells<State> = ExcelStateSelectedCells<State>,
> {
  tableState: TableState<State, StateChangedCells, StateSelectedCells>
  virtualScroll: VirtualScrollState
  scrollingData: ScrollingData | null
}

export const ExcelTableContext = createContext<ExcelTableContextProps>({
  tableState: {
    slices: {},
    currentSlices: {},
    currentSliceX: {},
    onChangeSliceX: {},
    columns: {},
    columnsKeys: [],
    isSelecting: false,
    defaultWidthColumn: 0,
    heightRow: 0,
    minWidthColumn: 0,
  },
  virtualScroll: {
    position: {
      startX: null,
      endX: null,
      startY: null,
      endY: null,
    },
    onChangeFns: [],
    ready: true,
    onChangeReady: [],
  },
  scrollingData: null,
})
