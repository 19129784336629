import { createRootRoute } from '@tanstack/react-router'
import { RootLayout } from 'packages/app/layout'
import { NotFoundBlock } from 'packages/app/layout/RootLayout/components'
import { ApplicationConfig } from 'packages/core/config'

export const rootRoute = (applicationConfig: ApplicationConfig) =>
  createRootRoute({
    component: () => <RootLayout applicationConfig={applicationConfig} />,
    notFoundComponent: () => (
      <NotFoundBlock
        title={applicationConfig.notFound?.title}
        titleTranslateKey={applicationConfig.notFound?.titleTranslateKey}
      >
        {applicationConfig.notFound?.component}
      </NotFoundBlock>
    ),
  })
