import { CellContext } from '@tanstack/table-core'
import DeleteIcon from 'assets/images/delete.svg?react'
import { DifferenceRow } from 'prgm/scorecard/interfaces'
import { useDifference } from 'prgm/scorecard/stores'

import classes from './DifferenceDelete.module.scss'

export const DifferenceDelete = ({ getRowData }: CellContext<DifferenceRow, null>) => {
  const removeRow = useDifference((state) => state.removeRow)

  return (
    <span className={classes.root} onClick={() => removeRow(getRowData().id)}>
      <DeleteIcon />
    </span>
  )
}
