import { MouseEvent as ReactMouseEvent } from 'react'

import classes from 'packages/ui/Draggable/Draggable.module.scss'
import { DraggableParams } from 'packages/ui/Draggable/types'

export const initPositions = (
  params: DraggableParams,
  items: HTMLElement[],
  elementPosition: { elementX: number; elementY: number; startPosition: number } | null | undefined,
  event: MouseEvent | ReactMouseEvent,
  isRecalculate?: boolean,
) => {
  if (!params.refDraggableItem.current || !params.refList.current || !params.refDraggableContainer.current) {
    return
  }

  const listTop = params.refList.current?.getBoundingClientRect().top
  params.refPositions.current = items.map((item) => item.getBoundingClientRect().top - listTop)

  if (
    params.draggableIsGlobal &&
    params.draggableLists &&
    params.refDraggableId.current &&
    params.refDraggableItem.current &&
    !isRecalculate
  ) {
    const rectItem = params.refDraggableItem.current.getBoundingClientRect()
    const computedStyle = window.getComputedStyle(params.refDraggableItem.current)
    const rectContainer = params.refDraggableContainer.current.getBoundingClientRect()
    const width = rectItem.width
    const height = rectItem.height
    params.refRectItem.current = { width, height }

    const windowX = event.clientX
    const windowY = event.clientY
    const elementX = event.clientX - rectItem.left
    const elementY = event.clientY - rectItem.top

    let translateX = windowX - elementX - rectContainer.left
    let translateY = windowY - elementY - rectContainer.top

    if (computedStyle.borderTop) {
      const borderTop = parseInt(computedStyle.borderTop)
      translateY += borderTop
      params.refRectItem.current.height -= borderTop
    }
    if (computedStyle.borderLeft) {
      const borderLeft = parseInt(computedStyle.borderLeft)
      translateX += borderLeft
      params.refRectItem.current.width -= borderLeft
    }
    const currentList = params.draggableLists[params.refDraggableId.current]
    if (currentList) {
      currentList.originalDraggableItem = params.refDraggableItem.current
      params.refDraggableItem.current = params.refDraggableItem.current.cloneNode(true) as HTMLDivElement
      params.refCommonDraggableItem.current = params.refDraggableItem.current
      params.refDraggableItem.current.style.pointerEvents = 'none'
      currentList.originalDraggableItem.removeAttribute('data-list-element')
      document.body.appendChild(params.refDraggableItem.current)
      currentList.originalDraggableItem.style.display = 'none'
    }

    params.refDraggableItem.current.style.top = `${translateY}px`
    params.refDraggableItem.current.style.left = `${translateX}px`
    params.refDraggableItem.current.style.width = `${width}px`
    params.refDraggableItem.current.style.height = `${params.refRectItem.current.height}px`
    if (computedStyle.borderTop) {
      params.refDraggableItem.current.style.borderTop = '1px solid transparent'
    }
    requestAnimationFrame(() => {
      params.refDraggableItem.current?.classList.add(classes.dragging)
    })
    if (params.classNameDragging) {
      params.refDraggableItem.current.classList.add(params.classNameDragging)
    }
    params.refDraggableItem.current?.removeAttribute('data-list-element')
    params.refElementPosition.current = elementPosition ?? { elementX, elementY, startPosition: rectItem.top - listTop }
  }

  if (
    params.draggableIsGlobal &&
    params.draggableLists &&
    params.refDraggableId.current &&
    params.refDraggableItem.current &&
    isRecalculate
  ) {
    params.refDraggableItem.current = params.refCommonDraggableItem.current
    const currentList = params.draggableLists[params.refDraggableId.current]
    if (currentList && currentList.originalDraggableItem) {
      const newOriginal = params.refList.current.querySelector(
        `[data-index='${currentList.originalDraggableItem.dataset.index}']`,
      )
      if (newOriginal) {
        currentList.originalDraggableItem = newOriginal
        currentList.originalDraggableItem.style.display = 'none'
        currentList.originalDraggableItem.removeAttribute('data-list-element')
      }
    }
  }
}
