import { useCallback, useMemo } from 'react'

import { Row } from '@tanstack/table-core'
import { TableCommonProps, TRow } from 'interfaces/table.interfaces'
import { deleteUndefined } from 'packages/helper'
import { getCellProps } from 'packages/ui/Table/helpers/getCellProps'
import { getColumnProps } from 'packages/ui/Table/helpers/getColumnProps'
import { mergeObjects } from 'utils/mergeObjects'

export const useMatrix = <TData extends TRow>(rows: Row<TData>[], enabled: boolean, deps: any[] = []) => {
  const matrix = useMemo(
    () =>
      rows.map((row) =>
        row.getVisibleCells().map((cell) => {
          const { tdProps } = getColumnProps(cell.column)
          const props = mergeObjects<TableCommonProps>(
            deleteUndefined({ ...(row.original.props ?? {}), style: undefined }),
            tdProps,
            getCellProps(row.original, cell.column.id),
          )
          return !!props.isEditable && !props.disabled
        }),
      ),
    [rows, enabled, ...deps],
  )

  const getValue = useCallback(
    (x: number, y: number) => (matrix[y] && matrix[y][x] !== undefined ? matrix[y][x] : false),
    [matrix],
  )
  const getLeft = useCallback((x: number, y: number) => (x > 0 ? getValue(x - 1, y) : false), [getValue])
  const getRight = useCallback(
    (x: number, y: number) =>
      y > 0 && y <= matrix.length - 1 && x < matrix[y].length - 1 ? getValue(x + 1, y) : false,
    [matrix, getValue],
  )
  const getTop = useCallback((x: number, y: number) => (y > 0 ? getValue(x, y - 1) : false), [getValue])
  const getBottom = useCallback(
    (x: number, y: number) => (y < (matrix[x] ? matrix[x].length - 1 : 0) ? getValue(x, y + 1) : false),
    [matrix, getValue],
  )

  const matrixFns = useMemo(
    () => ({
      getLeft,
      getRight,
      getTop,
      getBottom,
    }),
    [getLeft, getRight, getTop, getBottom],
  )

  return matrixFns
}
