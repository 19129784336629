import { AuthPipeFn } from 'packages/core/auth/controlAuth/authBuilder'

import { defineLastPage } from './defineLastPage'
import { defineStartPage } from './defineStartPage'

export const defineRedirectPage: AuthPipeFn = async (builder) => {
  if (!builder.redirectPage) {
    await builder.apply(defineLastPage)
  }
  if (
    builder.redirectPage &&
    !builder.appState.modules?.isValidStreamForCurrentUser(builder.appState, builder.redirectPage.split('/')[1])
  ) {
    builder.redirectPage = null
  }
  if (!builder.redirectPage) {
    await builder.apply(defineStartPage)
  }
}
