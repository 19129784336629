import { AppState } from 'packages/app/appState'
import { useSession } from 'packages/app/modules/session/store'
import { StreamOptions } from 'packages/core'

export const useEnableSession = (appState: AppState) => {
  const enableSession = (stream: StreamOptions | null) => {
    if (!stream?.session?.enabled) {
      return
    }
    const session = useSession.getState()
    const currentUserId = appState.modules?.profile?.id || null
    if (!currentUserId) {
      return
    }
    const currentActiveProjectId = appState.modules?.profile?.activeProjectId || null
    session.setUserId(currentUserId)
    session.setActiveProjectId(currentActiveProjectId)
    session.setPage(stream.startPage)
  }

  return { enableSession }
}
