import { useModal } from 'hooks/useModal'
import { useLogout } from 'packages/app/modules/logout'
import { useSessionOver } from 'packages/app/modules/session'
import { parseSessionTime } from 'packages/app/modules/session/helpers/parseSessionTime'
import { useCurrentStream } from 'packages/app/modules/streams'
import { t } from 'packages/localization'
import { Button, ButtonColors, TextModal } from 'packages/ui'

export const SessionOver = () => {
  const { currentStream } = useCurrentStream()
  const [openSessionOver, onOpenSessionOver, onCloseSessionOver] = useModal()
  const { logout } = useLogout()

  useSessionOver(onOpenSessionOver)

  const closeSessionOverModal = () => {
    onCloseSessionOver()
    logout()
  }

  return (
    <TextModal
      isOpened={openSessionOver}
      onClose={closeSessionOverModal}
      title={t('sessionOver', { time: parseSessionTime(currentStream?.session?.timeOfInactivity) })}
    >
      <Button color={ButtonColors.Primary} onClick={closeSessionOverModal}>
        Ok
      </Button>
    </TextModal>
  )
}
