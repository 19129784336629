import { AppState } from 'packages/app/appState'
import { useInitialRequests } from 'packages/app/initialRequests'
import { useMenuCreator } from 'packages/app/menu'
import { AppModulesState, isValidStreamForCurrentUser, useStreamsInit } from 'packages/app/modules'
import { useProjectInit } from 'packages/app/modules/projects'
import { useSessionInit } from 'packages/app/modules/session'
import { defineStartPage } from 'packages/app/startPageDefinition'
import { ApplicationConfig } from 'packages/core'

export const useModulesInit = (applicationConfig: ApplicationConfig, appState: AppState): AppModulesState => ({
  defineStartPage,
  isValidStreamForCurrentUser,
  ...useStreamsInit(applicationConfig, appState),
  ...useProjectInit(appState),
  ...useMenuCreator(applicationConfig),
  ...useInitialRequests(applicationConfig, appState),
  ...useSessionInit(applicationConfig),
})
