import { AxiosResponse } from 'axios'
import { camelCase } from 'lodash'
import { t } from 'packages/localization'

import { ApiError, ValidationErrorsApi } from './apiTypingErrors'
import { getError } from './functions'

export const getValidationErrors = (
  response: AxiosResponse | ApiError,
  validationReplaceToCamelCase = false,
): ValidationErrorsApi => {
  const errorsApi = ((response as AxiosResponse)?.data?.errors ??
    (response as AxiosResponse)?.data ??
    (response as ApiError).validation) as Record<string, string[] | string> | string | undefined | null

  if (typeof errorsApi === 'string' || errorsApi === undefined || errorsApi === null) {
    if (response.status === 404 || String(response.status).startsWith('5')) {
      return { root: t('apiErrors.requestFailed') }
    }
    return { root: errorsApi || t('apiErrors.requestFailed') }
  }

  const errors: ValidationErrorsApi = {}
  if (Array.isArray(errorsApi)) {
    errorsApi.forEach((item) => {
      for (const param in item) {
        if (Array.isArray(item[param]) && item[param]?.[0]) {
          errors[validationReplaceToCamelCase ? camelCase(param) : param] = item[param][0]
        } else if (!Array.isArray(item[param]) && item[param]) {
          errors[validationReplaceToCamelCase ? camelCase(param) : param] = item[param]
        }
      }
    })
  } else {
    for (const param in errorsApi) {
      let key = validationReplaceToCamelCase ? camelCase(param) : param
      if (key === 'error') {
        key = 'root'
      }
      if (Array.isArray(errorsApi[param]) && errorsApi[param]?.[0]) {
        errors[key] = errorsApi[param][0]
      } else if (!Array.isArray(errorsApi[param]) && errorsApi[param]) {
        errors[key] = (typeof errorsApi[param] === 'object' ? getError(errorsApi[param]) : errorsApi[param]) as string
      }
    }
  }
  if (!Object.keys(errors).length) {
    return null
  }
  return errors
}
